import React, { FunctionComponent } from "react";

import { StyledOverlay } from "./overlay.styles";

interface OverlayProps {
  top: number;
  left: number;
  width?: number;
  height?: number;
}

// overlay is always fixed positioned in certain coordinates
// always position overlay inside a component with onMouseLeave to control visibility
const Overlay: FunctionComponent<OverlayProps> = ({ children, top, left, width, height }) => {
  return (
    <StyledOverlay top={top} left={left} width={width} height={height}>
      {children}
    </StyledOverlay>
  );
};

export default Overlay;
