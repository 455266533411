import { OrganizationModel } from "redux/models/organization";

export enum OrganizationActionEnum {
  ORGANIZATION_UPDATE = "ORGANIZATION_UPDATE",
}

// Initial state
const initialState: OrganizationModel | null = null;

// Reducer
const OrganizationStateReducer = (state = initialState, action: any = {}) => {
  const { organization } = action;

  switch (action.type) {
    case OrganizationActionEnum.ORGANIZATION_UPDATE:
      return { ...organization } as OrganizationModel;

    default:
      return state;
  }
};

export default OrganizationStateReducer;
