import React, { FunctionComponent } from "react";
import moment from "moment";

// components
import DropdownComponent, { ComponentDropdownOptionProps } from "./component-dropdown";
import { StyledYearSelector } from "./year-selector.styles";

export const MIN_YEAR = 1900;
export const MAX_YEAR = moment().year() + 25;

interface YearSelectorProps {
  className?: string;
  year: number;
  onChange: (year: number) => void;
}

const YearSelector: FunctionComponent<YearSelectorProps> = ({ className, year, onChange }) => {
  // compose a list of years
  const options: ComponentDropdownOptionProps[] = [];
  for (let i = MIN_YEAR; i <= MAX_YEAR; i += 1) {
    options.push({ value: i, text: `${i}` });
  }

  const fnOnChange = (selectedYear: number) => {
    onChange(selectedYear);
  };

  return (
    <StyledYearSelector className={className}>
      <DropdownComponent width={90} options={options} value={year} onChange={fnOnChange} />
    </StyledYearSelector>
  );
};

export default YearSelector;
