import styled from "styled-components/macro";

export const StyledGuide = styled.p`
  ${props => props.theme.mixins.fontFira()};
  text-transform: initial;
  margin-bottom: 30px;
  font-weight: 400;
  color: ${props => props.theme.colors.fontColor};
  margin-top: 0px;
`;
