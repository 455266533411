import { ListTypeModel } from "redux/models/list-type";

export enum ListTypesActionEnum {
  LIST_TYPES_UPDATE = "LIST_TYPES_UPDATE",
  LIST_TYPES_CLEAR = "LIST_TYPES_CLEAR",
}

// Initial state
const initialState: ListTypeModel[] | null = null;

// Reducer
const ListsTypesStateReducer = (state: any = initialState, action: any = {}) => {
  const { listTypes } = action;

  switch (action.type) {
    case ListTypesActionEnum.LIST_TYPES_UPDATE:
      return [...listTypes] as ListTypeModel[];

    case ListTypesActionEnum.LIST_TYPES_CLEAR:
      return null;

    default:
      return state;
  }
};

export default ListsTypesStateReducer;
