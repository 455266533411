import styled from "styled-components/macro";

import Title from "./title";
import Image from "./image";

export const StyledBulletin = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 80px;
  text-align: center;
`;

export const StyledTitle = styled(Title)``;

export const StyledImage = styled(Image)`
  width: 50px;
  margin-bottom: 20px;
`;

export const StyledText = styled.p`
  font-size: 16px;
`;

export const StyledButtonWrapper = styled.div`
  margin-top: 60px;
`;

export default {};
