import React, { FunctionComponent } from "react";
import { translate } from "utils/i18n";
import Splash from "components/splash";

interface UnexpectedExceptionInterface {}

const UnexpectedException: FunctionComponent<UnexpectedExceptionInterface> = () => {
  return (
    <Splash
      icon="frown"
      title={translate("unexpectedException.title")}
      text={translate("unexpectedException.text")}
    ></Splash>
  );
};

export default UnexpectedException;
