import React, { FunctionComponent, Fragment } from "react";

import FormikError, { parseError } from "../error";
import FormikField, { setValue } from "../field";
import { StyledTextarea } from "./textarea.styles";
import { FieldTypeEnum } from "redux/models/field-type";
import { FieldModel } from "../utils";

export interface TextareaOptions {
  height?: number;
}

const TextareaField: FunctionComponent<FieldModel> = ({
  name,
  label,
  guide,
  required,
  textareaOptions = {},
  placeholder,
  onChange = () => {},
}) => {
  return (
    <FormikField name={name} type={FieldTypeEnum.TEXTAREA} label={label} guide={guide} required={required}>
      {({ field, form }) => {
        const { height } = textareaOptions;
        const fnOnChange = (event: any) => {
          const value = event.target.value;
          setValue(form, field.name, value);
          onChange(value);
        };

        const error = parseError(form, field);

        return (
          <Fragment>
            <StyledTextarea
              id={field.name}
              error={error}
              placeholder={placeholder}
              height={height}
              value={field.value}
              name={field.name}
              onChange={fnOnChange}
            />
            <FormikError error={error} />
          </Fragment>
        );
      }}
    </FormikField>
  );
};

export default TextareaField;
