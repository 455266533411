import { RouteModel } from "redux/models/route";

// routes
import Logout from "./logout";
import Home from "./home";
import LoginView from "../public/login/view";
import SessionExpiredView from "../public/session-expired/view";
import SsoLogin from "../sso/login";
import ForgottenPasswordsView from "../public/forgotten-passwords/view";
import LinkView from "../public/link/view";
import asyncComponent from "./async";

// new routes
const SystemAdminView = asyncComponent(() => import("../systemadmin/view"));
const OrganizationView = asyncComponent(() => import("../organization/view"));
const DefaultsView = asyncComponent(() => import("../defaults/view"));
const ListTypeView = asyncComponent(() => import("../list-type/view"));
const ListView = asyncComponent(() => import("../list/view"));
const AddMembersView = asyncComponent(() => import("../list/members/add/view"));
const ListsView = asyncComponent(() => import("../lists/view"));
const MemberView = asyncComponent(() => import("../member/view"));
const MessageView = asyncComponent(() => import("../list/message/view"));
const ThanksView = asyncComponent(() => import("../public/thanks/view"));
const ProfileView = asyncComponent(() => import("../profile/view"));
const MembershipsView = asyncComponent(() => import("../organization/memberships/tab"));
const ImportView = asyncComponent(() => import("../list/members/import/view"));

export const buildRoute = (path: string, component: any) => {
  return {
    path,
    component,
  } as RouteModel;
};

const membershipRoutes = [buildRoute("/memberships", MembershipsView)];
const listRoutes = [buildRoute("/lists/:listTypeUuid?", ListsView), buildRoute("/list/:listUuid", ListView)];
const memberRoutes = [
  buildRoute("/list/:listUuid/members", AddMembersView),
  buildRoute("/list/:listUuid/import", ImportView),
  buildRoute("/list/:listUuid/members/:memberUuid", MemberView),
];
const messageRoutes = [buildRoute("/list/:listUuid/message/:messageUuid?", MessageView)];

const commonRoutes = [
  buildRoute("/", Home),
  buildRoute("/profile", ProfileView),
  buildRoute("/systemadmin", SystemAdminView),
  buildRoute("/organization", OrganizationView),
  buildRoute("/defaults", DefaultsView),
  buildRoute("/list-type/:listTypeUuid?", ListTypeView),
];

export const privateRoutes = [...commonRoutes, ...membershipRoutes, ...listRoutes, ...memberRoutes, ...messageRoutes];

export const publicRoutes = [
  buildRoute("/login", LoginView),
  buildRoute("/thanks", ThanksView),
  buildRoute("/session-expired", SessionExpiredView),
  buildRoute("/forgotten-passwords/:uuid?", ForgottenPasswordsView),
  buildRoute("/link/:uuid?", LinkView),
  buildRoute("/error", () => {
    throw new Error("This is intentional error.");
  }),
];

export const redirectRoutes = [buildRoute("/sso-login", SsoLogin), buildRoute("/logout", Logout)];

export default privateRoutes;
