import styled from "styled-components/macro";

import { ZEnum } from "theme/layers";

export const StyledNavigationDesktop = styled.div`
  ${props => props.theme.mixins.noSelect()};
  height: 100%;

  display: flex;
  white-space: nowrap;

  z-index: ${ZEnum.COMPONENT};
  position: relative;

  ${props => props.theme.media.desktop`display: flex;`};
  ${props => props.theme.media.tablet`display: flex;`};
  ${props => props.theme.media.phone`display: none;`};
`;
