import { SettingsModel } from "redux/models/settings";

export enum SettingsActionEnum {
  UPDATE_SETTINGS = "UPDATE_SETTINGS",
}

const initialState: any = {};

const SettingsStateReducer = (state: SettingsModel = initialState, action: any) => {
  const { settings } = action;

  switch (action.type) {
    case SettingsActionEnum.UPDATE_SETTINGS:
      return { ...settings } as SettingsModel;

    default:
      return state;
  }
};

export default SettingsStateReducer;
