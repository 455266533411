import styled from "styled-components/macro";

export const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

export const StyledLink = styled.div`
  ${props => props.theme.mixins.fontExo()};
  font-size: 11px;
  font-weight: 400;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export default {};
