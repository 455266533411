import React from "react";

interface AsyncComponentStateInterface {
  Component: any;
}

// this only works with React -components, so wrap in such :)
const asyncComponent = (importComponent: any): any => {
  class AsyncComponent extends React.Component<{}, AsyncComponentStateInterface> {
    constructor(props: any) {
      super(props);

      this.state = {
        Component: null,
      };
    }

    componentDidMount = async () => {
      const { default: Component } = await importComponent();

      this.setState({
        Component,
      });
    };

    render() {
      const C = this.state.Component;

      return C ? <C {...this.props} /> : null;
    }
  }

  return AsyncComponent;
};

export default asyncComponent;
