export enum FieldSetDataActionEnum {
  FIELDSET_DATA_UPDATE = "FIELDSET_DATA_UPDATE",
  FIELDSET_DATA_CLEAR = "FIELDSET_DATA_CLEAR",
}

// Initial state
const initialState: any = null;

// Reducer
const FieldSetDataStateReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case FieldSetDataActionEnum.FIELDSET_DATA_UPDATE:
      return [...action.fieldSetData];

    case FieldSetDataActionEnum.FIELDSET_DATA_CLEAR:
      return null;

    default:
      return state;
  }
};

export default FieldSetDataStateReducer;
