import { LanguageEnum, RoleEnum } from "consts";
import { OrganizationModel } from "./organization";

export enum UserTypeEnum {
  SYSTEM_ADMIN = "system_admin",
  USER = "user",
}

export enum AuthTypeEnum {
  TOKEN = "token",
  CREDENTIALS = "credentials",
  SAML = "saml",
  CREDENTIALS_TWO_FACTOR_PENDING = "credentials_2fa_pending",
}

export enum TwoFactorDeliveryTypeEnum {
  SMS = "sms",
  EMAIL = "email",
  CODE_APP = "code_app",
}

export interface UserModel {
  uuid?: string;
  authenticated?: boolean;
  firstName?: string;
  lastName?: string;
  userType?: UserTypeEnum;
  authType?: AuthTypeEnum;
  username?: string;
  language?: LanguageEnum;
  phoneNumber?: string;
  redirectPath?: string; // redirect for "token" users
  twoFactorDeliveryType?: TwoFactorDeliveryTypeEnum;
  isAdmin?: boolean; // used in some queries
  roles?: RoleEnum[];
  organizations?: OrganizationModel[];
}
