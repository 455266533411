import ReactMarkdown from "react-markdown/with-html";
import styled from "styled-components/macro";

interface MyProps {
  transformLinkUri: string;
}

export const StyledReactMarkdown = styled(ReactMarkdown)<MyProps>`
  ${props => props.theme.mixins.fontFira()};
  letter-spacing: -0.1px;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;

  h1 {
    ${props => props.theme.mixins.fontFira()};
    -webkit-font-smoothing: subpixel-antialiased;
    font-size: 28px;
    font-weight: 300;
    margin-bottom: 15px;
    color: #313333;
    letter-spacing: -0.2px;
    line-height: 1.2;
    margin-top: 0;
  }

  h2 {
    ${props => props.theme.mixins.fontFira()};
    -webkit-font-smoothing: subpixel-antialiased;
    font-size: 24px;
    font-weight: 300;
    color: #313333;
    margin-top: 25px;
    margin-bottom: 15px;
    letter-spacing: -0.2px;
    line-height: 1.2;
  }

  h3 {
    ${props => props.theme.mixins.fontFira()};
    -webkit-font-smoothing: subpixel-antialiased;
    font-size: 20px;
    font-weight: 300;
    color: #313333;
    margin-top: 25px;
    margin-bottom: 15px;
    letter-spacing: -0.2px;
    line-height: 1.2;
  }

  p:first-child {
    margin-top: 0px;
  }
`;

export default {};
