import { OrganizationModel } from "redux/models/organization";
import { OrganizationsActionEnum } from "redux/reducers/organizations";
import { store } from "../store";

export const update = (organizations: OrganizationModel[]) => {
  try {
    store.dispatch({
      type: OrganizationsActionEnum.ORGANIZATIONS_UPDATE,
      organizations,
    });
  } catch (ex) {
    store.dispatch({
      type: OrganizationsActionEnum.ORGANIZATIONS_CLEAR,
    });
  }
};
