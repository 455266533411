import { ListModel } from "redux/models/list";

export enum ListActionEnum {
  LIST_UPDATE = "LIST_UPDATE",
  LIST_CLEAR = "LIST_CLEAR",
}

// Initial state
const initialState: ListModel | null = null;

// Reducer
const ListStateReducer = (state: ListModel | null = initialState, action: any = {}): ListModel | null => {
  switch (action.type) {
    case ListActionEnum.LIST_UPDATE:
      return { ...action.list };

    case ListActionEnum.LIST_CLEAR:
      return null;

    default:
      return state;
  }
};

export default ListStateReducer;
