import styled, { css } from "styled-components/macro";

import { ZEnum } from "theme/layers";
import Icon from "components/icon";

interface StyledRouteInterface {
  selected: boolean;
  hasIcon: boolean;
}

export const StyledRoute = styled.div<StyledRouteInterface>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

  background-color: ${props =>
    props.selected &&
    !props.hasIcon &&
    css`
      ${props.theme.colors.greenAlternative};
    `};

  ${props =>
    props.hasIcon &&
    css`
      padding-right: 0px;
    `};
`;

export const StyledText = styled.div`
  display: inline-block;

  ${props => props.theme.mixins.fontFira()};
  color: ${props => props.theme.colors.white};
  font-size: 12px;
  font-weight: 400;
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledIconContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: ${props => props.theme.colors.greenAlternative};
  border-radius: 50%;
  margin-top: -1px;
`;

export const StyledIcon = styled(Icon)`
  min-width: auto;
`;

export const StyledCaret = styled(Icon)`
  display: inline-block;
  vertical-align: top;
  min-width: auto;
  margin-left: 7px;
`;

export enum AlignEnum {
  LEFT = "left",
  RIGHT = "right",
}

interface StyledSubrouteContainerInterface {
  align: AlignEnum;
}

export const StyledSubrouteContainer = styled.div<StyledSubrouteContainerInterface>`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.grayLight};
  ${props => props.theme.mixins.boxShadow()};
  position: absolute;
  border: 1px solid ${props => props.theme.colors.gray};
  z-index: ${ZEnum.COMPONENT};

  ${props =>
    props.align === AlignEnum.LEFT &&
    css`
      left: 0px;
    `};

  ${props =>
    props.align === AlignEnum.RIGHT &&
    css`
      right: 0px;
    `};
`;
