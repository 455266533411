import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components/macro";

interface MyProps {
  transparent?: boolean;
  nopadding?: boolean;
}

export const StyledContainer = styled.div<MyProps>`
  position: relative;
  padding: 30px;
  max-width: 1160px;
  margin: 0 auto;
  background-color: ${props => props.theme.colors.white};

  ${props =>
    props.transparent &&
    css`
      background-color: transparent;
    `};

  ${props =>
    props.nopadding &&
    css`
      padding: 0px;
    `};

  ${props => !props.nopadding && props.theme.media.phone`padding: 15px;`};
`;

interface ContainerProps {
  className?: string;
  transparent?: boolean;
  nopadding?: boolean;
}

const Container: FunctionComponent<ContainerProps> = ({
  className,
  children,
  transparent = false,
  nopadding = false,
}) => (
  <StyledContainer className={className} transparent={transparent} nopadding={nopadding}>
    {children}
  </StyledContainer>
);

export default Container;
