import generic from "./generic.json";
import profile from "./profile.json";
import systemadmin from "./systemadmin.json";
import organization from "./organization.json";
import yup from "./yup-translations.json";
import list from "./list.json";
import lists from "./lists.json";
import member from "./member.json";
import defaults from "./defaults.json";
import publicTranslations from "./public.json";
import navigation from "./navigation.json";

export default {
  ...generic,
  profile,
  systemadmin,
  organization,
  yup,
  list,
  lists,
  member,
  defaults,
  public: publicTranslations,
  navigation,
};
