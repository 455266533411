import styled from "styled-components/macro";

export const SelectorHeight = 20;

interface StyledSelectWrapperProps {
  width: number;
}

export const StyledSelectWrapper = styled.div<StyledSelectWrapperProps>`
  width: ${props => props.width}px;
  height: ${SelectorHeight}px;
  position: relative;
`;

export const StyledSelect = styled.select`
  appearance: button;
  border-radius: 0px;
  border: 0px;
  opacity: 0;
  position: absolute;
  cursor: pointer;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
`;

export const StyledLabel = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding-top: 0px;
  cursor: pointer;
  pointer-events: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.theme.mixins.fontFira()};
  ${props => props.theme.mixins.noSelect()};
  color: ${props => props.theme.colors.green};

  &:after {
    content: "";
    border: solid ${props => props.theme.colors.green};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    margin-left: 8px;
    margin-top: -4px;
  }
`;

export const StyledOption = styled.option``;
