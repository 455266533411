import React, { FunctionComponent, Fragment, useState, useRef } from "react";
import Draft, { Editor, EditorState, RichUtils, CompositeDecorator, ContentBlock, ContentState } from "draft-js";
import { stateToMarkdown } from "draft-js-export-markdown";
import { stateFromMarkdown } from "draft-js-import-markdown";

import FormikError, { parseError } from "../error";
import FormikField, { setValue } from "../field";
import { useSelector } from "react-redux";
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { blockStyleFn, StyledMarkDownStyleWrapper } from "./markdown.styles";
import Link from "./markdown/link";
import LinkModal from "./markdown/link-modal";
import Toolbox from "./markdown/toolbox";
import { Map } from "immutable";
import { FieldTypeEnum } from "redux/models/field-type";
import { FieldModel } from "../utils";

const findLinkEntities = (contentBlock: ContentBlock, callback: any, contentState: ContentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === "LINK";
  }, callback);
};

const extendedBlockRenderMap = Draft.DefaultDraftBlockRenderMap.merge(
  Map({
    unstyled: {
      element: "p",
    },
  })
);

const toMarkdown = (editorState: EditorState) => {
  const content = editorState.getCurrentContent();
  const plain = content.getPlainText();
  let markdown = stateToMarkdown(content, { gfm: true });
  if (plain === "") {
    markdown = "";
  }
  return markdown;
};

const MarkdownField: FunctionComponent<FieldModel> = ({
  name,
  label,
  guide,
  required,
  placeholder,
  onChange = () => {},
}) => {
  useSelector(languageSelector, languageEquality);
  const [editorState, setEditorState] = useState<EditorState | undefined>(undefined); // we will later set the actual value
  const [link, setLink] = useState(false);
  const [url, setUrl] = useState("");
  const ref = useRef<any>(undefined);

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

  return (
    <FormikField name={name} type={FieldTypeEnum.MARKDOWN} label={label} guide={guide} required={required}>
      {({ field, form }) => {
        // if we don't have the initial value, parse it
        if (!editorState) {
          // create a new editor state from the field value
          const content = stateFromMarkdown(field.value);
          const editorState = EditorState.createWithContent(content, decorator);
          setEditorState(editorState);
          return null;
        }

        const fnOnChange = (editorState: EditorState) => {
          // convert to markdown
          const newMarkdown = toMarkdown(editorState);
          setValue(form, field.name, newMarkdown);

          // set to state and delegate
          setEditorState(editorState);
          onChange(newMarkdown);
        };

        const error = parseError(form, field);

        // this must use passed editorState
        const handleKeyCommand = (command: any, editorState: EditorState) => {
          const newState = RichUtils.handleKeyCommand(editorState, command);
          if (newState) {
            fnOnChange(newState);
            return "handled";
          }
          return "not-handled";
        };

        const toggleLink = () => {
          const selection = editorState.getSelection();
          if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = editorState.getSelection().getStartKey();
            const startOffset = editorState.getSelection().getStartOffset();
            const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

            let url = "http://";
            if (linkKey) {
              const linkInstance = contentState.getEntity(linkKey);
              url = linkInstance.getData().url;
            }

            // change state
            setUrl(url);
            setLink(true);
          }
        };

        const confirmLink = (url: string) => {
          let finalUrl = url.includes("://") ? url : `http://${url}`;
          const contentState = editorState.getCurrentContent();
          const contentStateWithEntity = contentState.createEntity("LINK", "MUTABLE", { url: finalUrl });
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
          const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
          const changedEditorState = RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey);

          setUrl("");
          setLink(false);
          fnOnChange(changedEditorState);
          refocus();
        };

        const toggleBlockType = (blockType: any) => {
          const newEditorState = RichUtils.toggleBlockType(editorState, blockType);
          fnOnChange(newEditorState);
          refocus();
        };

        const toggleInlineStyle = (inlineStyle: string) => {
          const newEditorState = RichUtils.toggleInlineStyle(editorState, inlineStyle);
          fnOnChange(newEditorState);
          refocus();
        };

        const refocus = () => {
          setTimeout(() => {
            try {
              ref.current.focus();
            } catch (err) {}
          }, 100);
        };

        const onClickWrapper = (e: any) => {
          const { className } = e.target;
          if (className === "DraftEditor-root") {
            // only when clicking on empty editor area
            refocus();
          }
        };

        return (
          <Fragment>
            <StyledMarkDownStyleWrapper onClick={onClickWrapper}>
              <Toolbox
                editorState={editorState}
                onToggleBlock={toggleBlockType}
                onToggleInline={toggleInlineStyle}
                onToggleLink={toggleLink}
              />
              {link && <LinkModal url={url} onClose={async () => setLink(false)} onSubmit={confirmLink} />}
              <Editor
                editorState={editorState}
                placeholder={placeholder}
                onChange={fnOnChange}
                handleKeyCommand={handleKeyCommand}
                blockStyleFn={blockStyleFn}
                stripPastedStyles
                blockRenderMap={extendedBlockRenderMap}
                ref={ref}
              />
            </StyledMarkDownStyleWrapper>
            <FormikError error={error} />
          </Fragment>
        );
      }}
    </FormikField>
  );
};

export default MarkdownField;
