import styled, { css } from "styled-components/macro";

export const DIMENSION = 32;

interface StyledDayProps {
  selectable: boolean;
  bold: boolean;
  selected: boolean;
  disabled: boolean;
}

export const StyledDay = styled.div<StyledDayProps>`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  width: ${DIMENSION}px;
  height: ${DIMENSION}px;
  border-radius: 50%;
  border: 1px solid transparent;
  ${props => props.theme.mixins.fontExo()};
  ${props => props.theme.mixins.noSelect()};
  opacity: 0.25;
  font-weight: 600;
  font-size: 12px;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  ${props =>
    props.selectable &&
    css`
      opacity: 1;
      cursor: pointer;
      &:hover {
        background-color: ${props.theme.colors.gray};
      }
    `};

  ${props =>
    props.bold &&
    css`
      opacity: 1;
      color: ${props.theme.colors.green};
      text-transform: uppercase;
    `};

  ${props =>
    props.selected &&
    css`
      color: ${props.theme.colors.green};
      border: 1px solid ${props.theme.colors.green};
    `};
`;

export const StyledContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
