import React, { FunctionComponent } from "react";
import MediaQuery from "react-responsive";
import { css, CSSObject } from "styled-components/macro";

export enum BreakpointEnum {
  DESKTOP = 1224,
  TABLET = 768,
  PHONE = 576,
}

// media object, core
const media: any = {};

// Iterate through the sizes and create a media template
const breakpointKeys = Object.keys(BreakpointEnum)
  .map(key => BreakpointEnum[key as any])
  .filter(value => typeof value === "string") as string[];
for (const breakpointKey of breakpointKeys) {
  const breakpointValue: any = BreakpointEnum[breakpointKey as any];
  media[breakpointKey.toLowerCase()] = (customCss: any) =>
    css`
      @media (max-width: ${breakpointValue}px) {
        ${css(customCss)};
      }
    `;
}

// custom breakpoint fn
media.max = (breakpoint: BreakpointEnum, customCss: CSSObject) => css`
  @media (max-width: ${breakpoint as number}px) {
    ${css(customCss)};
  }
`;

// custom breakpoint fn
media.min = (breakpoint: BreakpointEnum, customCss: CSSObject) => css`
  @media (min-width: ${breakpoint as number}px) {
    ${css(customCss)};
  }
`;

interface MediaComponentInterface {
  minWidth?: number;
  maxWidth?: number;
  children: any;
}

export const MediaComponent: FunctionComponent<MediaComponentInterface> = ({ minWidth, maxWidth, children }) => {
  const props: any = {};
  if (minWidth) {
    props.minWidth = minWidth - 1; // to cover the dead pixes
  }
  if (maxWidth) {
    props.maxWidth = maxWidth;
  }
  return <MediaQuery {...props}>{children}</MediaQuery>;
};

export default media;

// usage:
// ${props => props.theme.media.desktop`display: none;`}
// ${props => props.theme.media.max(600, `display: none;`)}
// ${props => props.theme.media.min(752, `display: none;`)}
// <MediaComponent maxWidth={BreakpointEnum.PHONE}>...</MediaComponent>

// note:
// always add them in order desktop -> tablet -> phone (bigger to smaller)
