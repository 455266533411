import React, { FunctionComponent } from "react";

// redux
import { hasRole } from "redux/actions/user";
import { selector as listTypesSelector, equalityFn as listTypesEquality } from "redux/hooks/list-types";

// utils
import { goto } from "./navigation";
import { RoleEnum } from "consts";
import { useSelector } from "react-redux";
import { selector as userSelector, equalityFn as userEquality } from "redux/hooks/user";
import { AuthTypeEnum } from "redux/models/user";
import NotFoundRoute from "modules/error/not-found";

interface HomeInterface {}

const Home: FunctionComponent<HomeInterface> = () => {
  const listTypesState = useSelector(listTypesSelector, listTypesEquality);
  const userState = useSelector(userSelector, userEquality);

  if (!listTypesState) {
    return <div />;
  }

  // where to redirect?
  const { authType } = userState;
  if (authType === AuthTypeEnum.TOKEN) {
    return <NotFoundRoute />;
  } else {
    // redirect others
    if (hasRole(RoleEnum.INSIDER_LIST_ADMIN)) {
      goto("/lists");
    } else if (hasRole(RoleEnum.ORGANIZATION_ADMINISTRATOR)) {
      goto("/organization");
    }
  }
  return <div />;
};

export default Home;
