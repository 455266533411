import { EmailTemplateModel } from "redux/models/email-templates";

export enum EmailTemplatesActionEnum {
  EMAIL_TEMPLATES_UPDATE = "EMAIL_TEMPLATES_UPDATE",
  EMAIL_TEMPLATES_CLEAR = "EMAIL_TEMPLATES_CLEAR",
}

// Reducer
const EmailTemplatesStateReducer = (state: any = null, action: any) => {
  const { emailTemplates } = action;

  switch (action.type) {
    case EmailTemplatesActionEnum.EMAIL_TEMPLATES_UPDATE:
      return [...emailTemplates] as EmailTemplateModel[];

    case EmailTemplatesActionEnum.EMAIL_TEMPLATES_CLEAR:
      return null;

    default:
      return state;
  }
};

export default EmailTemplatesStateReducer;
