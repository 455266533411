import React, { FunctionComponent } from "react";
import { translate } from "utils/i18n";

// child components
import PublicForm from "../elements/form";
import DocTitle from "components/doctitle";
import { StageTypeEnum } from "./view";
import PublicPlate from "../elements/plate";
import { PlateTypeEnum } from "../elements/plate.styles";

// redux
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { useSelector } from "react-redux";

interface LoginErrorInterface {
  onStageChange: (stage: StageTypeEnum) => void;
}

const LoginError: FunctionComponent<LoginErrorInterface> = ({ onStageChange }) => {
  useSelector(languageSelector, languageEquality);

  const fn = () => {
    onStageChange(StageTypeEnum.LOGIN);
  };

  return (
    <PublicForm>
      <DocTitle title={translate("public.login.title")} />
      <PublicPlate
        type={PlateTypeEnum.ERROR}
        title={translate("public.login.error.title")}
        text={translate("public.login.error.desc")}
        button={{
          fn,
          text: translate("public.login.buttons.retry"),
        }}
      />
    </PublicForm>
  );
};

export default LoginError;
