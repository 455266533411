import React, { FunctionComponent, CSSProperties } from "react";
import styled from "styled-components/macro";

interface RowProps {
  className?: string;
  style?: any; // this is how it should be done I think :P
  children: any;
}

// components
export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${props => props.theme.media.phone`display: block;`};
`;

const Row: FunctionComponent<RowProps> = ({ className, children, style }) => (
  <StyledRow style={style as CSSProperties} className={className}>
    {children}
  </StyledRow>
);

export default Row;
