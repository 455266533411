import { MemberModel } from "redux/models/member";

export enum MemberActionEnum {
  MEMBER_UPDATE = "MEMBER_UPDATE",
  MEMBER_CLEAR = "MEMBER_CLEAR",
}

// Initial state
const initialState: MemberModel | null = null;

// Reducer
const MemberStateReducer = (state: MemberModel | null = initialState, action: any) => {
  switch (action.type) {
    case MemberActionEnum.MEMBER_UPDATE:
      return { ...action.member } as MemberModel;

    case MemberActionEnum.MEMBER_CLEAR:
      return null;

    default:
      return state;
  }
};

export default MemberStateReducer;
