import React, { FunctionComponent } from "react";

// components
import Modal from "components/layout/modal";
import Markdown from "components/markdown";

// utils+misc
import { translateFrom } from "utils/i18n";

// redux
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";

import { StyledContent } from "./privacy-policy-modal.styles";
import { useSelector } from "react-redux";

interface PrivacyPolicyModalProps {
  onClose: () => Promise<void>;
  policy: any;
}

export const PrivacyPolicyModal: FunctionComponent<PrivacyPolicyModalProps> = ({ onClose, policy }) => {
  const languageState = useSelector(languageSelector, languageEquality);

  const { contents = [] } = policy;

  // reduce contents to single object for i18n utils
  const contentObject = contents
    .map((content: any) => ({ [content.language]: content.content }))
    .reduce((contentsReduce: any, content: any) => ({ ...contentsReduce, ...content }), {});

  const policyText = translateFrom(contentObject, languageState);

  return (
    <Modal onClose={onClose}>
      <StyledContent>
        <Markdown text={policyText} />
      </StyledContent>
    </Modal>
  );
};

export default PrivacyPolicyModal;
