import React, { FunctionComponent } from "react";
import { translate } from "utils/i18n";

// components
import PublicForm from "../elements/form";
import Form, { FormButtonProps } from "components/formik/form";
import { ButtonColorEnum } from "components/button";
import { StageTypeEnum } from "./view";

// services
import { verifyCode as verifyLink, requestCode as getCode } from "services/link";
import { LinkModel } from "redux/models/link";
import { TwoFactorDeliveryTypeEnum } from "redux/models/user";
import { loginWithLink } from "utils/auth";

// redux
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { useSelector } from "react-redux";
import { FieldTypeEnum } from "redux/models/field-type";
import FormikLayoutColumns from "components/formik/layout/columns";
import { FieldModel } from "components/formik/utils";
import PublicGuide from "../elements/guide";

interface EmailFormInterface {
  uuid: string;
  link: LinkModel;
  onStageChange: (stage: StageTypeEnum, method?: TwoFactorDeliveryTypeEnum) => void;
}

interface FormValues {
  email: string;
}

const EmailForm: FunctionComponent<EmailFormInterface> = ({ uuid, link, onStageChange }) => {
  useSelector(languageSelector, languageEquality);

  const getSchemaForEmail = () => {
    const schema: FieldModel[] = [
      {
        name: "email",
        type: FieldTypeEnum.USERNAME,
        label: translate("public.2fa.labels.email"),
        placeholder: translate("public.2fa.placeholders.email"),
        usernameOptions: {
          submitOnEnter: false,
        },
      },
    ];
    return schema;
  };

  const getInitialValuesForEmail = () => {
    const initialValues: FormValues = { email: "" };
    return initialValues;
  };

  const getButtonsForEmail = () => {
    const buttons: FormButtonProps[] = [
      {
        children: translate("public.2fa.buttons.sendCode"),
        color: ButtonColorEnum.PUBLIC,
      },
    ];
    return buttons;
  };

  const onSubmitEmail = async (values: FormValues) => {
    const { email } = values;

    if (!link || !uuid) {
      return;
    }

    // send email
    try {
      // send a request for a code
      const result = await getCode(uuid, email, "email", link.hash);
      if (link.hasTwoFactor === true) {
        // move to code verification
        onStageChange(StageTypeEnum.CODE, result.verificationCode.method);
      }

      if (link.hasTwoFactor === false) {
        await verifyLink(uuid, "none", link.hash);
        await loginWithLink(link, uuid);
      }
    } catch (error) {
      onStageChange(StageTypeEnum.EMAIL_ERROR);
    }
  };

  const onRender = () => <FormikLayoutColumns formSchema={getSchemaForEmail()} />;

  return (
    <PublicForm>
      <PublicGuide>{translate("public.2fa.guides.emailVerification")}</PublicGuide>

      <Form
        onRender={onRender}
        initialValues={getInitialValuesForEmail()}
        buttons={getButtonsForEmail()}
        onSubmit={onSubmitEmail}
      />
    </PublicForm>
  );
};

export default EmailForm;
