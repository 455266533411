import { TableModel } from "redux/models/table";

export enum TableActionEnum {
  SET_TABLE_INITIAL = "SET_TABLE_INITIAL",

  SET_TABLE_SEARCH = "SET_TABLE_SEARCH",
  SET_TABLE_SORT = "SET_TABLE_SORT",
  SET_TABLE_FILTER = "SET_TABLE_FILTER",
  SET_TABLE_PAGE = "SET_TABLE_PAGE",
  SET_TABLE_LIMIT = "SET_TABLE_LIMIT",

  SET_TABLE_SELECTION = "SET_TABLE_SELECTION",

  CLEAR_TABLE_STATE = "CLEAR_TABLE_STATE",
}

// Initial state
export const initialTable: TableModel = {
  initial: true,
  search: "",
  sort: { value: "", desc: false },
  filter: undefined, // selected filter key
  selection: [], // uuid's of the selected items
  limit: 25,
  page: 1,
};

const initialState: any = {};

// do not use initialState in constructor as initiaState is per table!!!!!
export const TableReducer = (state = initialState, action: any) => {
  let tableState = undefined;
  let newState = undefined;
  switch (action.type) {
    case TableActionEnum.SET_TABLE_INITIAL:
      tableState = { ...state }[action.name] || { ...initialTable };
      tableState.initial = false;
      tableState.search = action.search;
      tableState.sort = action.sort;
      tableState.filter = action.filter;
      tableState.page = action.page;
      tableState.limit = action.limit;
      return { ...state, [action.name]: tableState };

    case TableActionEnum.SET_TABLE_SEARCH:
      tableState = { ...state }[action.name] || { ...initialTable };
      tableState.search = action.search;
      return { ...state, [action.name]: tableState };

    case TableActionEnum.SET_TABLE_SORT:
      tableState = { ...state }[action.name] || { ...initialTable };
      tableState.sort = action.sort;
      return { ...state, [action.name]: tableState };

    case TableActionEnum.SET_TABLE_FILTER:
      tableState = { ...state }[action.name] || { ...initialTable };
      tableState.filter = action.filter;
      return { ...state, [action.name]: tableState };

    case TableActionEnum.SET_TABLE_LIMIT:
      tableState = { ...state }[action.name] || { ...initialTable };
      tableState.limit = action.limit;
      return { ...state, [action.name]: tableState };

    case TableActionEnum.SET_TABLE_PAGE:
      tableState = { ...state }[action.name] || { ...initialTable };
      tableState.page = action.page;
      return { ...state, [action.name]: tableState };

    case TableActionEnum.SET_TABLE_SELECTION:
      tableState = { ...state }[action.name] || { ...initialTable };
      tableState.selection = action.selection;
      return { ...state, [action.name]: tableState };

    case TableActionEnum.CLEAR_TABLE_STATE:
      newState = { ...state };
      delete newState[action.name];
      return newState;

    default:
      return state;
  }
};

export default TableReducer;
