import React, { memo, FunctionComponent } from "react";

import { StyledClear } from "./clear.styles";
import { ColorEnum } from "theme/colors";

interface FormikClearProps {
  className?: string;
  value: any;
  disabled?: boolean;
  onClear: () => void;
}

const FormikClear: FunctionComponent<FormikClearProps> = ({ className, disabled = false, value = null, onClear }) => {
  // if disabled, no clear
  if (disabled) {
    return null;
  }

  // if no value, no clear
  if (!value) {
    return null;
  }

  return (
    <StyledClear
      className={className}
      icon="times"
      onClick={onClear}
      color={ColorEnum.grayDisabled}
      hoverColor={ColorEnum.redNegative}
    />
  );
};

export default memo(FormikClear);
