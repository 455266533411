export enum ColorEnum {
  body = "#e3e5e5",
  white = "#ffffff",
  black = "#000000",
  gray = "#D2D4D4",
  green = "#018F94",
  red = "#00C4C7",
  greenLight = "#edf9fa", // tint of green
  redLight = "#faedf8", // tint of red
  greenPositive = "#339966",
  redNegative = "#cc0000",
  greenAlternative = "#00C4C7",
  greenDark = "#00565C",
  fontColor = "#202121",
  grayLight = "#f9fafa",
  grayBorder = "#D2D4D4",
  grayDisabled = "#b9bdbd",
  grayHover = "#edf9fa",
  grayBlue = "rgba(56,94,102, 0.4)",
  grayPlaceholder = "rgba(50, 51, 51, 0.4)",
}
