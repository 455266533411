import React, { FunctionComponent } from "react";
import { translate } from "utils/i18n";

// child components
import PublicForm from "../elements/form";
import Form, { FormButtonProps } from "components/formik/form";
import { ButtonColorEnum } from "components/button";
import { StageTypeEnum } from "./view";

// redux
import { TwoFactorDeliveryTypeEnum } from "redux/models/user";
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { useSelector } from "react-redux";

// services
import { verifyCode } from "services/link";
import { loginWithLink } from "utils/auth";
import PublicGuide from "../elements/guide";
import { FieldTypeEnum } from "redux/models/field-type";
import FormikLayoutColumns from "components/formik/layout/columns";
import { FieldModel } from "components/formik/utils";
import { LinkModel } from "redux/models/link";

interface CodeFormInterface {
  uuid: string;
  hash: string;
  link: LinkModel;
  method?: TwoFactorDeliveryTypeEnum;
  onStageChange: (stage: StageTypeEnum) => void;
}

interface FormValues {
  code: string;
}

const CodeForm: FunctionComponent<CodeFormInterface> = ({ uuid, hash, link, method, onStageChange }) => {
  useSelector(languageSelector, languageEquality);

  const getSchemaForCode = () => {
    const schema: FieldModel[] = [
      {
        name: "code",
        type: FieldTypeEnum.CODE,
        codeOptions: { submitOnEnter: false, maxLength: 4 },
      },
    ];
    return schema;
  };

  const getInitialValuesForCode = () => {
    const initialValues: FormValues = { code: "" };
    return initialValues;
  };

  const getButtonsForCode = () => {
    const buttons: FormButtonProps[] = [
      {
        children: translate("public.2fa.buttons.login"),
        color: ButtonColorEnum.PUBLIC,
      },
    ];
    return buttons;
  };

  const renderCodeStageForm = () => <FormikLayoutColumns formSchema={getSchemaForCode()} />;

  const onSubmitCode = async (values: FormValues) => {
    const { code } = values;

    try {
      if (!code) {
        throw new Error("must have code");
      }

      // first verify link
      await verifyCode(uuid, code, hash);

      // actual login
      await loginWithLink(link, uuid);
    } catch (error) {
      // show error and exit without login
      return onStageChange(StageTypeEnum.CODE_ERROR);
    }
  };

  // check guide
  let guide = "";
  switch (method) {
    case TwoFactorDeliveryTypeEnum.EMAIL:
      guide = translate("public.2fa.guides.email");
      break;
    case TwoFactorDeliveryTypeEnum.SMS:
      guide = translate("public.2fa.guides.sms");
      break;
  }

  return (
    <PublicForm>
      <PublicGuide>{guide}</PublicGuide>

      <Form
        initialValues={getInitialValuesForCode()}
        buttons={getButtonsForCode()}
        onSubmit={onSubmitCode}
        onRender={renderCodeStageForm}
      />
    </PublicForm>
  );
};

export default CodeForm;
