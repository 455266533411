import React, { memo, FunctionComponent } from "react";

import { StyledFormikLabel } from "./label.styles";

interface FormikLabelProps {
  className?: string;
  label?: string;
  required?: boolean;
  id?: string;
}
const FormikLabel: FunctionComponent<FormikLabelProps> = ({ className, label, required = false, id }) => {
  if (!label) {
    return null;
  }

  return (
    <StyledFormikLabel className={className} htmlFor={id}>
      {required ? `${label} *` : label}
    </StyledFormikLabel>
  );
};

export default memo(FormikLabel);
