import { LanguageEnum } from "consts";

export enum LanguageActionEnum {
  LANGUAGE_CHANGE = "LANGUAGE_CHANGE",
}

// Initial state
const initialState = LanguageEnum.EN;

// Reducer
const LanguageStateReducer = (state = initialState, action: any = {}) => {
  const { language } = action;

  switch (action.type) {
    case LanguageActionEnum.LANGUAGE_CHANGE:
      return language as LanguageEnum;

    default:
      return state as LanguageEnum;
  }
};

export default LanguageStateReducer;
