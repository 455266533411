import styled from "styled-components/macro";

// components
import Container from "components/layout/container";
import { StyledButton } from "components/button";
import { StyledNav } from "./nav.styles";

// images
import background from "images/Ticker_Splash_Background_V2_1920x1229.png";

// this should be slipt and pieces put into correct files
export const StyledPublicSplash = styled.div`
  background-image: url(${background});
  color: ${props => props.theme.colors.white};

  background-size: cover;

  min-height: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${StyledButton} {
    margin-bottom: 0px;
  }
`;

export const StyledPipe = styled.span`
  margin: 0 11px;
`;

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  text-transform: uppercase;

  padding-top: 60px;
  padding-bottom: 60px;
`;

export const StyledContent = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 60px;
  max-width: 460px;
  width: 100%;
  ${props => props.theme.media.phone`max-width: 100%; padding: 30px;`};

  ${StyledNav} {
    ${props => props.theme.mixins.fontFira()};
    color: ${props => props.theme.colors.fontColor} !important;
    text-transform: initial;
    font-weight: 400;
    border-bottom: 1px solid ${props => props.theme.colors.fontColor};

    &:hover {
      border-color: ${props => props.theme.colors.fontColor};
    }
  }
`;

export default { StyledPublicSplash };
