import React, { FunctionComponent } from "react";

import { StyledLock } from "./lock.styles";
import { ColorEnum } from "theme/colors";

interface FormikLockProps {
  className?: string;
  disabled?: boolean;
}

const FormikLock: FunctionComponent<FormikLockProps> = ({ className, disabled = false }) => {
  // if not disabled, no lock
  if (!disabled) {
    return null;
  }

  return <StyledLock className={className} icon="lock" color={ColorEnum.grayDisabled} />;
};

export default FormikLock;
