import React, { FunctionComponent } from "react";

import { StyledFormikGuide } from "./guide.styles";

interface FormikGuideProps {
  className?: string;
  guide?: string;
}
const FormikGuide: FunctionComponent<FormikGuideProps> = ({ className, guide }) => {
  if (!guide) {
    return null;
  }

  const __html = guide.replace(/\n/gi, "<br/>");
  return <StyledFormikGuide className={className} dangerouslySetInnerHTML={{ __html }}></StyledFormikGuide>;
};

export default FormikGuide;
