import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

// Build the middleware for intercepting and dispatching navigation actions
export const history = createBrowserHistory();
const router = routerMiddleware(history);

// add to array
export const middlewares: any[] = [];
middlewares.push(router);
middlewares.push(thunk);
