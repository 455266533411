import React, { FunctionComponent, useState } from "react";

// components
import LoginForm from "./login-form";
import LoginError from "./login-error";
import Login2FAForm from "./login-2fa-form";
import Login2FAError from "./login-2fa-error";

// utils
import { TwoFactorDeliveryTypeEnum } from "redux/models/user";

export enum StageTypeEnum {
  LOGIN = "login",
  LOGIN_2FA = "login_2fa",
  LOGIN_ERROR = "login_error",
  LOGIN_2FA_ERROR = "login_2fa_error",
}

interface LoginViewInterface {}

const LoginView: FunctionComponent<LoginViewInterface> = () => {
  const [stage, setStage] = useState<StageTypeEnum>(StageTypeEnum.LOGIN);
  const [username, setUsername] = useState<string>("");
  const [method, setMethod] = useState<TwoFactorDeliveryTypeEnum>(TwoFactorDeliveryTypeEnum.EMAIL);

  const onStageChangeFromError = (stage: StageTypeEnum) => {
    setStage(stage);
  };

  // allows subforms to change state
  const onStageChangeFromLogin = (stage: StageTypeEnum, username: string, method?: TwoFactorDeliveryTypeEnum) => {
    setStage(stage);
    if (username) {
      setUsername(username);
    }
    if (method) {
      setMethod(method);
    }
  };

  const onStageChangeFrom2FA = (stage: StageTypeEnum) => {
    setStage(stage);
    if (username) {
      setUsername(username);
    }
  };

  // render the lgin view
  switch (stage) {
    case StageTypeEnum.LOGIN:
      return <LoginForm username={username} onStageChange={onStageChangeFromLogin} />;

    case StageTypeEnum.LOGIN_2FA:
      return <Login2FAForm method={method} onStageChange={onStageChangeFrom2FA} />;

    case StageTypeEnum.LOGIN_ERROR:
      return <LoginError onStageChange={onStageChangeFromError} />;

    case StageTypeEnum.LOGIN_2FA_ERROR:
      return <Login2FAError onStageChange={onStageChangeFrom2FA} />;

    default:
      return null;
  }
};

export default LoginView;
