import React, { FunctionComponent, CSSProperties } from "react";
import { Field as FormikField, FieldProps as FormikFieldProps, FormikProps } from "formik";

// child components
import FormikLabel from "./label";
import FormikGuide from "./guide";
import { StyledFormikFieldContainer, StyledWrapper } from "./field.styles";
import { FieldTypeEnum } from "redux/models/field-type";

export const setValue = (form: FormikProps<any>, fieldName: string, fieldValue: any) => {
  form.setFieldValue(fieldName, fieldValue, true);
  form.setFieldTouched(fieldName, true, false);
};

// this interface contains properties for field wrapper
export interface FieldProps {
  className?: string;
  type: FieldTypeEnum;
  label?: string;
  guide?: string;
  style?: CSSStyleDeclaration;
  name: string;
  required?: boolean;
  children?: (props: FormikFieldProps<any>) => React.ReactNode;
  validate?: (value: any) => void;
}

const Field: FunctionComponent<FieldProps> = ({
  className = "",
  type,
  style,
  name,
  label,
  required = false,
  guide,
  children,
  validate,
}) => {
  return (
    <StyledFormikFieldContainer className={`${className} ${type}`} style={style as CSSProperties}>
      {label && <FormikLabel required={required} label={label} id={name} />}
      <StyledWrapper>
        <FormikField name={name} validate={validate}>
          {children}
        </FormikField>
      </StyledWrapper>
      {guide && <FormikGuide guide={guide} />}
    </StyledFormikFieldContainer>
  );
};

export default Field;
