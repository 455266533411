import React, { FunctionComponent } from "react";

import { StyledInputWrapper, StyledInput } from "./component-input.styles";

interface ComponentInputProps {
  className?: string;
  value: string;
  onChange: (value: string, validate: boolean, key?: string) => void;
  onIncrease: () => void;
  onDecrease: () => void;
  onBlur?: () => void;
  maxLength?: number;
  width?: number;
}

const ComponentInput: FunctionComponent<ComponentInputProps> = ({
  className,
  value,
  onChange,
  onIncrease,
  onDecrease,
  onBlur,
  maxLength = 2,
}) => {
  const getValueFromEvent = (event: any) => {
    return event.target.value;
  };

  const fnOnChange = (event: any) => {
    onChange(getValueFromEvent(event), false);
  };

  const fnOnKeyDown = (event: any) => {
    if (event.key === "ArrowUp") {
      onIncrease();
    } else if (event.key === "ArrowDown") {
      onDecrease();
    }
  };

  const fnOnBlur = (event: any) => {
    onChange(getValueFromEvent(event), true);
  };

  return (
    <StyledInputWrapper className={className}>
      <StyledInput
        value={value}
        maxLength={maxLength}
        onChange={fnOnChange}
        onKeyDown={fnOnKeyDown}
        onBlur={onBlur || fnOnBlur}
      />
    </StyledInputWrapper>
  );
};

export default ComponentInput;
