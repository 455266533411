import styled, { css } from "styled-components/macro";

export const StyledToolbox = styled.div<any>`
  border-bottom: 1px solid ${props => props.theme.colors.grayBorder};
  padding: 12px;
`;

interface StyledButtonProps {
  active: boolean;
  last: boolean;
}

export const StyledButton = styled.div<StyledButtonProps>`
  ${props => props.theme.mixins.fontExo()};

  display: inline-block;
  background-color: ${props => props.theme.colors.grayLight};
  padding: 6px 12px 6px 12px;
  text-transform: uppercase;
  font-weight: 600;

  margin-right: -1px;
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors.grayBorder};

  ${props =>
    props.active &&
    css`
      background-color: ${props => props.theme.colors.grayBorder};
    `}

  &:hover {
    background-color: ${props => props.theme.colors.grayBorder};
  }

  ${props =>
    props.last &&
    css`
      margin-right: 7px;
    `}
`;
