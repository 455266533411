import React, { FunctionComponent } from "react";
import styled from "styled-components/macro";

const StyledTitle = styled.h2`
  ${props => props.theme.mixins.fontFira()};
  color: ${props => props.theme.colors.green};
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 18px;
`;

interface TitleProps {
  className?: string;
  children: string;
}

const Title: FunctionComponent<TitleProps> = ({ className = "", children = "" }) => (
  <StyledTitle className={`${className} title`}>{children}</StyledTitle>
);

export default Title;
