import React, { FunctionComponent } from "react";

// components
import { StyledSelectWrapper, StyledSelect, StyledOption, StyledLabel } from "./component-dropdown.styles";

interface ComponentDropdownProps {
  className?: string;
  width?: number;
  options?: ComponentDropdownOptionProps[];
  value: number;
  onChange: (value: number) => void;
}

export interface ComponentDropdownOptionProps {
  value: number;
  text: string;
  description?: string;
}

const ComponentDropdown: FunctionComponent<ComponentDropdownProps> = ({
  width = 35,
  className,
  options = [],
  value,
  onChange,
}) => {
  const fnOnChange = (event: any) => {
    const valueAsNumber = parseInt(event.target.value, 10);
    onChange(valueAsNumber);
  };

  const matchingOption = options.find(o => o.value === value);
  const valueAsStr = matchingOption ? matchingOption.text : "";

  return (
    <StyledSelectWrapper className={className} width={width}>
      <StyledSelect value={value} onChange={fnOnChange}>
        {options.map(option => (
          <StyledOption key={`component-dropdown-${option.value}-${option.text}`} value={option.value}>
            {option.description || option.text}
          </StyledOption>
        ))}
      </StyledSelect>
      <StyledLabel>{valueAsStr}</StyledLabel>
    </StyledSelectWrapper>
  );
};

export default ComponentDropdown;
