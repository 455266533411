import React, { FunctionComponent, useState } from "react";

// components
import InputComponent from "./component-input";
import { StyledMinuteSelector } from "./minute-selector.styles";

const MIN_MINUTE = 0;
const MAX_MINUTE = 59;

interface MinuteSelectorProps {
  onChange: (minute: number) => void;
  minute: number;
  className?: string;
}

const validateMinute = (minute: number) => {
  return minute >= MIN_MINUTE && minute <= MAX_MINUTE;
};

const MinuteSelector: FunctionComponent<MinuteSelectorProps> = ({ onChange, minute, className }) => {
  const [rawInputMinute, setRawInputMinute] = useState<string | null>(null);

  const onBlur = () => {
    const minuteAsNumber = parseInt(rawInputMinute || "", 10);
    setRawInputMinute(null);
    if (validateMinute(minuteAsNumber)) {
      onChange(minuteAsNumber);
    }
  };

  // the value in the input has changed
  const fnOnChange = (value: string, validate: boolean) => {
    if (value === "" || !validate) {
      setRawInputMinute(value);
    } else {
      // if a valid number, validate in range
      const minuteAsNumber = parseInt(value, 10);
      if (!isNaN(minuteAsNumber) && validateMinute(minuteAsNumber)) {
        setRawInputMinute(null);

        // delegate
        onChange(minuteAsNumber);
      }
    }
  };

  const fnOnIncrease = () => {
    // get the current value
    let rawInputMinuteNumeric = parseInt(rawInputMinute || "", 10) || minute;
    if (!isNaN(rawInputMinuteNumeric)) {
      // increase
      rawInputMinuteNumeric = rawInputMinuteNumeric < MAX_MINUTE ? rawInputMinuteNumeric + 1 : MIN_MINUTE;

      // delegate
      fnOnChange(String(rawInputMinuteNumeric), true);
    }
  };

  const fnOnDecrease = () => {
    // get the current value
    let rawInputMinuteNumeric = parseInt(rawInputMinute || "", 10) || minute;
    if (!isNaN(rawInputMinuteNumeric)) {
      // increase
      rawInputMinuteNumeric = rawInputMinuteNumeric > MIN_MINUTE ? rawInputMinuteNumeric - 1 : MAX_MINUTE;

      // delegate
      fnOnChange(String(rawInputMinuteNumeric), true);
    }
  };

  // parse value
  let value = String(minute);
  if (rawInputMinute !== null) {
    value = rawInputMinute;
  }

  // add zero padding, if needed
  if (rawInputMinute === null && minute < 10) {
    value = [0, value].join("");
  }

  return (
    <StyledMinuteSelector className={className}>
      <InputComponent
        width={35}
        value={value}
        onBlur={onBlur}
        onChange={fnOnChange}
        onIncrease={fnOnIncrease}
        onDecrease={fnOnDecrease}
      />
    </StyledMinuteSelector>
  );
};

export default MinuteSelector;
