import React, { FunctionComponent } from "react";
import moment from "moment";

import DropdownComponent from "./component-dropdown";
import { StyledMonthSelector } from "./month-selector.styles";

// in some locales months are like "january" but in some like "(stycznia|styczeń)"
const getOptions = () => {
  const months = moment.months();
  const options = months.map((text, index) => {
    if (text[0] === "(") {
      text = text.substring(1, text.length - 1);
      text = text.split("|")[1];
    }
    text = text[0].toUpperCase() + text.substring(1);
    return { value: index, text };
  });
  return options;
};

interface MonthSelectorProps {
  className?: string;
  month: number;
  onChange: (month: number) => void;
}

const MonthSelector: FunctionComponent<MonthSelectorProps> = ({ className, month, onChange }) => {
  // compose a list of months
  const options = getOptions();

  // when the month has been changed from dropdown
  const fnOnChange = (newMonth: number) => {
    onChange(newMonth);
  };

  return (
    <StyledMonthSelector className={className}>
      <DropdownComponent width={120} options={options} value={month} onChange={fnOnChange} />
    </StyledMonthSelector>
  );
};

export default MonthSelector;
