import React, { FunctionComponent, useRef, useEffect } from "react";
import { StyledSplash, StyledBox, StyledIcon, StyledTitle, StyledText } from "./splash.styles";
import { ColorEnum } from "theme/colors";

interface SplashProps {
  icon: string;
  title: string;
  text: string;
}

const Splash: FunctionComponent<SplashProps> = ({ icon, title, text }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.style.opacity = "1";
    }
  }, [ref]);

  return (
    <StyledSplash ref={ref}>
      <StyledBox>
        <StyledIcon icon={icon} size={72} color={ColorEnum.green} />
        <StyledTitle>{title}</StyledTitle>
        <StyledText>{text}</StyledText>
      </StyledBox>
    </StyledSplash>
  );
};

export default Splash;
