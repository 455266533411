import React from "react";

// components
import DocTitle from "components/doctitle";
import Bulletin from "components/bulletin";
import Container from "components/layout/container";

// utils
import { translate } from "utils/i18n";

const NotFoundRoute = () => (
  <Container>
    <DocTitle title={translate("error.404.title")} />
    <Bulletin title={translate("error.404.title") as string} text={translate("error.404.text") as string} />
  </Container>
);

export default NotFoundRoute;
