import React from "react";
import { Route, Switch } from "react-router-dom";

import { redirectRoutes } from "./routes";

// non rendered routes
const RedirectRoute = () => (
  <Switch>
    {redirectRoutes.map((route, index) => (
      <Route exact path={route.path} component={route.component} key={`redirect-route-${index}`} />
    ))}
  </Switch>
);

export default RedirectRoute;
