import React, { FunctionComponent } from "react";
import { translate } from "utils/i18n";

// components
import FormikError, { parseError } from "../error";
import FormikField, { setValue } from "../field";
import { StyledWrapper, StyledAttachmentContainer, StyledFileInput, StyledButton } from "./file.styles";

// utils
import { addAttachment, removeAttachment, MaxFileSize } from "utils/attachment";
import { uploadFileAsBase64, downloadAttachment, bytesToMegaBytes, validateFile, FileError } from "utils/file";
import { failure } from "utils/notification";
import List, { ListItemProps } from "components/list";
import { AttachmentModel } from "redux/models/attachment";
import { FieldTypeEnum } from "redux/models/field-type";
import { FieldModel } from "../utils";

const FormikFile: FunctionComponent<FieldModel> = ({ name, label, guide, required, onChange = () => {} }) => {
  return (
    <FormikField name={name} type={FieldTypeEnum.FILE} label={label} guide={guide} required={required}>
      {({ field, form }) => {
        const { value = [] } = field;

        // get files
        const attachments: AttachmentModel[] = value;

        const fnOnChange = (attachments: AttachmentModel[]) => {
          setValue(form, field.name, attachments);
          onChange(attachments);
        };

        const fnAddAttachment = (attachment: AttachmentModel) => {
          const attachments: AttachmentModel[] = value;
          addAttachment(attachments, attachment);
          fnOnChange(attachments);
        };

        const fnDownloadAttachment = (item: ListItemProps, index: number) => {
          downloadAttachment(attachments[index]);
        };

        const fnRemoveAttachment = (item: ListItemProps, index: number) => {
          const attachments: AttachmentModel[] = value;
          removeAttachment(attachments, index);
          fnOnChange(attachments);
        };

        const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
          // must persist the event https://reactjs.org/docs/events.html#event-pooling
          event.persist();

          // get the chose file
          const { files } = event.target;

          if (!files) {
            return;
          }

          const file = files[0];
          try {
            // first validate
            validateFile(attachments, file);
          } catch (err) {
            if (err.message === FileError.TOOLARGE) {
              failure(translate("files.exceedSizeLimit", { maxSize: bytesToMegaBytes(MaxFileSize) }));
              return true;
            } else if (err.message === FileError.FILETYPE) {
              failure(translate("files.supportedTypes"));
              return true;
            }
          }

          // actual upload
          await uploadFileAsBase64(file, fnAddAttachment);

          // clear the input to allow for example selecting the same file again
          event.target.value = "";
          return true;
        };

        // parse error
        const error = parseError(form, field);

        const items: ListItemProps[] = attachments.map(attachment => {
          return { value: attachment.uuid || "", label: attachment.name };
        });

        return (
          <StyledWrapper>
            <StyledAttachmentContainer>
              <List items={items} onDelete={fnRemoveAttachment} onClick={fnDownloadAttachment} />
            </StyledAttachmentContainer>
            <StyledFileInput type="file" name={field.name} id={`id-${field.name}`} onChange={handleOnChange} />
            {/* renders as label to implement label for input thingie */}
            <StyledButton as="label" htmlFor={`id-${field.name}`}>
              {translate("files.add")}
            </StyledButton>

            <FormikError error={error} />
          </StyledWrapper>
        );
      }}
    </FormikField>
  );
};

export default FormikFile;
