import React, { FunctionComponent } from "react";

import { StyledTickInput, StyledTickContainer, StyledTickBox, StyledTickLabel, StyledTickIcon } from "./tick.styles";
import FormikError, { parseError } from "components/formik/error";
import FormikField, { setValue } from "../field";
import { ColorEnum } from "theme/colors";
import { FieldTypeEnum } from "redux/models/field-type";
import { FieldModel } from "../utils";

const FormikTick: FunctionComponent<FieldModel> = ({ name, label, guide, required, onChange = () => {} }) => {
  return (
    <FormikField name={name} type={FieldTypeEnum.TICK} label={undefined} guide={guide} required={required}>
      {({ field, form }) => {
        const fnOnPreventPropagation = (e: any) => {
          e.stopPropagation();
        };

        const fnOnChange = async (value: any) => {
          setValue(form, field.name, value);
          onChange(value);
        };

        const checkboxId = `checkbox-${field.name}`;

        const error = parseError(form, field);

        return (
          <StyledTickContainer onClick={fnOnPreventPropagation}>
            {/* Normal input element, with some extra juicy props */}
            <StyledTickInput
              checked={field.value}
              id={checkboxId}
              error={error}
              type="checkbox"
              onClick={() => fnOnChange(!field.value)}
              {...field}
            />
            <StyledTickBox htmlFor={checkboxId} checked={field.value}>
              <StyledTickIcon icon="check" color={ColorEnum.green} />
            </StyledTickBox>
            <StyledTickLabel id={`tick-${field.name}`} htmlFor={checkboxId}>
              {label}
            </StyledTickLabel>
            <FormikError error={error} />
          </StyledTickContainer>
        );
      }}
    </FormikField>
  );
};

export default FormikTick;
