import React, { FunctionComponent, useState, useEffect, useCallback } from "react";

// components
import PublicForm from "../elements/form";
import EmailForm from "./email-form";
import EmailDone from "./email-done";
import EmailError from "./email-error";
import LinkError from "./link-error";
import RestoreForm from "./restore-form";
import RestoreDone from "./restore-done";
import RestoreError from "./restore-error";

// services
import { get } from "services/forgotten-passwords";

export enum StageTypeEnum {
  EMAIL = "email", // just entered the view to enter email address
  EMAIL_DONE = "email_done", // email given, show ok
  EMAIL_ERROR = "email_error", // something went wrong
  LINK_ERROR = "link_error", // the link is not valied
  RESTORE = "restore", // enter new pw
  RESTORE_DONE = "restore_done", // restored successfully
  RESTORE_ERROR = "restore_error", // restore failed
}

interface ForgottenPasswordsViewInterface {
  match: any;
}

export interface ForgottenPasswordsViewStateInterface {
  loaded: boolean;
  uuid: string;
  stage: StageTypeEnum;
  username: string;
}

const ForgottenPasswordsView: FunctionComponent<ForgottenPasswordsViewInterface> = ({ match }) => {
  const [loaded, setLoaded] = useState(false);
  const [uuid, setUuid] = useState<string | undefined>(undefined);
  const [stage, setStage] = useState(StageTypeEnum.EMAIL);
  const [username, setUsername] = useState("");

  if (!uuid) {
    // extract the link uuid
    const { params } = match;
    if (params.uuid) {
      setUuid(params.uuid);
    }
  }

  const start = useCallback(async () => {
    if (uuid) {
      // uuid exists, we need to verify the link
      try {
        await get(uuid); // just fetching it should be enough
        setStage(StageTypeEnum.RESTORE);
      } catch (err) {
        setStage(StageTypeEnum.LINK_ERROR);
      }
    }

    // mark as loaded
    setLoaded(true);
  }, [uuid]);

  useEffect(() => {
    start();
  }, [uuid, start]);

  const onStageChange = (stage: StageTypeEnum) => setStage(stage);
  const onSetUsername = (username: string) => setUsername(username);

  // if no link, render nothing
  if (!loaded) {
    return <PublicForm />;
  }

  switch (stage) {
    case StageTypeEnum.EMAIL:
      return <EmailForm setUsername={onSetUsername} onStageChange={onStageChange} />;

    case StageTypeEnum.EMAIL_DONE:
      return <EmailDone username={username} />;

    case StageTypeEnum.EMAIL_ERROR:
      return <EmailError onStageChange={onStageChange} />;

    case StageTypeEnum.LINK_ERROR:
      return <LinkError />;

    case StageTypeEnum.RESTORE:
      return <RestoreForm uuid={uuid} onStageChange={onStageChange} />;

    case StageTypeEnum.RESTORE_DONE:
      return <RestoreDone />;

    case StageTypeEnum.RESTORE_ERROR:
      return <RestoreError onStageChange={onStageChange} />;

    default:
      return null;
  }
};

export default ForgottenPasswordsView;
