import React, { FunctionComponent, Fragment } from "react";

import { StyledInput, StyledLabel, StyledContainer, StyledWrapper } from "./checkbox.styles";
import FormikError, { parseError } from "../error";
import FormikField, { setValue } from "../field";
import { FieldTypeEnum } from "redux/models/field-type";
import { OptionsOpts, FieldModel } from "../utils";

export interface CheckboxOptions extends OptionsOpts {}

// this component is rendered inside of the Formiks Field Component. See `./field.js`
// components gets fields & form props from the formik renderer.
const CheckboxField: FunctionComponent<FieldModel> = ({
  name,
  label,
  guide,
  required,
  checkboxOptions = {},
  onChange = () => {},
}) => {
  const { options = [] } = checkboxOptions;
  return (
    <FormikField name={name} type={FieldTypeEnum.CHECKBOX} label={label} guide={guide} required={required}>
      {({ field, form }) => {
        // throw a warning, if no options
        if (options.length === 0) {
          throw new Error(`Checkbox ${field.name} has no options.`);
        }

        // stops propagation when needed
        const fnOnPreventPropagation = (e: any) => {
          e.stopPropagation();
        };

        const getValueAsArray = () => {
          // sanity checks
          if (typeof field.value !== "string") {
            throw new Error(`Checkbox "${field.name}" is having non-string value!`);
          }

          // removes empty values with the .filter
          return (field.value as string).split(";").filter((value: string) => value);
        };

        // handle the value changes, checkbox can have multiple values!!!
        const fnOnChange = (event: any) => {
          const { value } = event.target;

          // logic
          const values = getValueAsArray();
          if (values.includes(value)) {
            values.splice(values.indexOf(value), 1);
          } else {
            values.push(value);
          }

          // convert to string
          const valuesAsString = values.join(";");

          // set back to values
          setValue(form, field.name, valuesAsString);
          onChange(valuesAsString);
        };

        // parse options and error
        const error = parseError(form, field);

        // utility to check if the certain option is checked
        const isChecked = (value: string) => {
          const values = getValueAsArray();
          return values.includes(value);
        };

        return (
          <Fragment>
            <StyledWrapper>
              {options.map(option => {
                // get label and value from this option
                const { label, value } = option;

                const fieldId = `${field.name}-${value}`;
                const checked = isChecked(value);

                return (
                  <StyledContainer key={fieldId} onClick={fnOnPreventPropagation}>
                    <StyledInput type="checkbox" value={value} id={fieldId} checked={checked} onChange={fnOnChange} />
                    <StyledLabel htmlFor={fieldId} checked={checked}>
                      {label}
                    </StyledLabel>
                  </StyledContainer>
                );
              })}
            </StyledWrapper>

            <FormikError error={error} />
          </Fragment>
        );
      }}
    </FormikField>
  );
};

export default CheckboxField;
