import React, { FunctionComponent, ReactChild } from "react";

import { StyledDay, StyledContent } from "./day.styles";

interface DayProps {
  className?: string;
  day?: number;
  bold?: boolean;
  disabled?: boolean;
  selectable?: boolean;
  selected?: boolean;
  onSelect?: (nr: number) => void;
  children?: ReactChild;
}

const Day: FunctionComponent<DayProps> = ({
  className,
  day,
  bold = false,
  disabled = false,
  selectable = false,
  selected = false,
  onSelect = () => {},
  children = false,
}) => {
  const fnOnClick = () => {
    // click only if selectable and actual day defined
    if (selectable && day) {
      onSelect(day);
    }
  };

  return (
    <StyledDay
      className={className}
      bold={bold}
      selectable={selectable}
      disabled={disabled}
      selected={selected}
      onClick={fnOnClick}
    >
      <StyledContent id={`day-${day}`}>{children}</StyledContent>
    </StyledDay>
  );
};

export default Day;
