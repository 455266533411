import { translate } from "utils/i18n";

// utils
import { goto } from "../modules/app/navigation";
import { changeLanguage } from "utils/i18n";
import { set as setToLocalStorage } from "utils/local-storage";
import { remove, set as setToSessionStorage, get as getFromSessionStorage } from "utils/session-storage";
import { doGet, doPost } from "./ajax";
import { LanguageEnum } from "consts";
import { success } from "utils/notification";

// redux stuff
import { store } from "redux/store";
import { CLEAR_REDUX } from "redux/reducer";
import { redirect } from "redux/actions/app";
import { update as updateUser, login as loginUser, getAuthType } from "redux/actions/user";
import { select as selectOrganization } from "redux/actions/organization";
import { update as updateOrganizations } from "redux/actions/organizations";
import { update as updateLanguages } from "redux/actions/languages";
import { update as updateListTypes } from "redux/actions/list-types";
import { update as updateSettings } from "redux/actions/settings";
import { update as updateEmailTemplates } from "redux/actions/email-templates";
import { AuthTypeEnum } from "redux/models/user";
import { update as updateLists } from "redux/actions/lists";

// services
import { authenticate } from "services/link";
import { OrganizationModel } from "redux/models/organization";
import { LinkModel } from "redux/models/link";

export const clearRedux = async () => {
  // clear redux (this will clear the session-storage too)
  await store.dispatch({ type: CLEAR_REDUX });
};

export enum LogoutTypeEnum {
  NORMAL = "normal", // redirects to login page
  SESSION_TIMEOUT = "session-timeout", // redirect to session expired page
  TOKEN = "token", // redirect to "Thank you" page
}

const { REACT_APP_API_BASE_URL = "http://localhost:3000/api/v1" } = process.env;

// logout the user
export const logout = async (logoutType = LogoutTypeEnum.NORMAL) => {
  const authType = getAuthType();
  try {
    // if SAML and normal logout, then kill SAML session
    if (authType === AuthTypeEnum.SAML && logoutType === LogoutTypeEnum.NORMAL) {
      // clear redux (this will clear the session-storage too)
      await clearRedux();
      remove(process.env.REACT_APP_CSRF_TOKEN_COOKIE || "");

      // redirect to saml logout endpoint
      return window.open(`${REACT_APP_API_BASE_URL}/auth/saml/logout`, "_self");
    }

    // first terminate session on the server
    await doPost("/logout");

    // redirect user to correct view
    switch (logoutType) {
      case LogoutTypeEnum.NORMAL:
        goto("/login");
        break;
      case LogoutTypeEnum.TOKEN:
        goto("/thanks");
        break;
      case LogoutTypeEnum.SESSION_TIMEOUT:
        goto("/session-expired");
        break;
      default:
        throw new Error(`Unknown LogoutType ${logoutType}`);
    }

    // clear session on the frontend (must be last, otherwise hook subcsriptions are launched everywhere)
    await clearRedux();
    remove(process.env.REACT_APP_CSRF_TOKEN_COOKIE || "");
  } catch (error) {
    // well, it can fail, not a big deal
  }
};

// gets user info right after login/refresh
export const update = async (organizationUuid?: string, language?: string) => {
  const json = await doGet("/my/user");

  // fetch data from the
  const { user } = json;
  const organizations: OrganizationModel[] = user.organizations;
  delete user.organizations;

  // update user and organizations
  updateUser(json);
  updateOrganizations(organizations);

  // get current organization
  const currentOrganization: OrganizationModel = store.getState().organization;

  // find out which organization to choose first
  // order: predefined --> current --> first one
  const organizationUuidToChoose =
    organizationUuid || (currentOrganization ? currentOrganization.uuid : null) || organizations[0].uuid;

  // if we don't have uuid at this point, something is wrong :/ --> prevent login
  if (!organizationUuidToChoose) {
    throw new Error("No organization uuid to choose :(");
  }

  // choose the org by uuid
  const organization: any = organizations.find(organization => organization.uuid === organizationUuidToChoose);
  if (organization) {
    selectOrganization(organization);
  } else {
    throw new Error(`No organization to choose :(`);
  }

  // update languages and some other settings
  await updateLanguages();
  await updateSettings();
  await updateEmailTemplates();

  // update list types, requires settings to be present
  if (user.authType !== AuthTypeEnum.TOKEN) {
    await updateLists();
    await updateListTypes();
  }

  // change language in order: predefined --> user --> en
  const lang = language || user.language || LanguageEnum.EN;
  changeLanguage(lang);
};

export const switchOrganization = async (organization: OrganizationModel) => {
  // fetch all the data
  await update(organization.uuid);

  // redirect
  success(translate("switchedTo", organization));
  goto("/");
};

export const loginWithLink = async (link: LinkModel, uuid: string) => {
  try {
    // authenticate and create session, fails if an existing user
    const result = await authenticate(uuid, link.hash);

    // clear redux (this will clear the session-storage too)
    await clearRedux();

    // signal to other tabs
    setToLocalStorage("instanceUuid", getFromSessionStorage("instanceUuid"));

    // Store csrfToken
    const { csrfToken } = result;
    setToSessionStorage(process.env.REACT_APP_CSRF_TOKEN_COOKIE || "", csrfToken);

    // mark as authenticated
    loginUser(link.redirectPath);

    // update user information
    const { organizationUuid, language } = link;
    await update(organizationUuid, language);

    // redirect to desired path
    if (link.redirectPath) {
      await goto(link.redirectPath);
    }
  } catch (error) {
    throw error;
  }
};

// login as credential user
export const login = async (username: string, password: string) => {
  try {
    // do actual login
    const result = await doPost("/login", { username, password });
    const { csrfToken, authType } = result;

    // clear redux (this will clear the session-storage too)
    await clearRedux();

    // signal to other tabs
    setToLocalStorage("instanceUuid", getFromSessionStorage("instanceUuid"));

    // Store csrfToken
    setToSessionStorage(process.env.REACT_APP_CSRF_TOKEN_COOKIE || "", csrfToken);

    // check need for 2fa
    if (authType === AuthTypeEnum.CREDENTIALS_TWO_FACTOR_PENDING) {
      // return for the login view to process 2fa further
      return result;
    }

    // mark as authenticated
    loginUser();

    // update user information
    await update();

    // parse path to redirecrt
    const path = store.getState().app.redirect || "/";
    redirect();

    // redirect to root
    goto(path);
    return;
  } catch (err) {
    // pass higher --> login view
    throw err;
  }
};

export const login2FA = async () => {
  // mark as authenticated
  loginUser();

  // update user information
  await update();

  // parse path to redirecrt
  const path = store.getState().app.redirect || "/";
  redirect();

  // redirect to root
  goto(path);
};

export default { login, loginWithLink, update, logout };
