import React, { FunctionComponent } from "react";

import {
  TickerLoaderContainer,
  StyledTickerLoader,
  StyledTickerLoaderCircle,
  StyledTickerLoaderLetters,
} from "./ticker.styles";

interface LoaderComponentProps {
  className?: string;
}

const TickerLoader: FunctionComponent<LoaderComponentProps> = ({ className = "" }) => {
  return (
    <TickerLoaderContainer className={className}>
      <StyledTickerLoader>
        <StyledTickerLoaderCircle>
          <svg width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <circle
              cx="50"
              cy="50"
              r="45"
              strokeWidth="5"
              stroke="#35aeb5"
              strokeDasharray="72 72"
              fill="none"
              strokeLinecap="round"
              transform="rotate(102.982 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="2s"
                keyTimes="0;1"
                values="0 50 50;360 50 50"
              ></animateTransform>
            </circle>
          </svg>
        </StyledTickerLoaderCircle>

        <StyledTickerLoaderLetters>
          <svg viewBox="-125 -130 500 500" width="75px" height="75px">
            <polygon points="0 97.78 104.69 115.53 70.18 156.66 0 97.78" />
            <rect
              x="281.71"
              y="335.22"
              width="53.69"
              height="53.83"
              transform="translate(-357.86 165.73) rotate(-50)"
            />
            <polygon points="123.14 113.84 218.66 0 199.96 106.02 164.37 148.44 123.14 113.84" />
          </svg>
        </StyledTickerLoaderLetters>
      </StyledTickerLoader>
    </TickerLoaderContainer>
  );
};

export default TickerLoader;
