import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import AppStateReducer from "./reducers/app";
import UserStateReducer from "./reducers/user";
import OrganizationStateReducer from "./reducers/organization";
import OrganizationsStateReducer from "./reducers/organizations";
import ConfirmStateRecuder from "./reducers/confirm";
import TableReducer from "./reducers/table";
import LanguageReducer from "./reducers/language";
import LanguagesReducer from "./reducers/languages";
import TabsReducer from "./reducers/tabs";
import SettingsReducer from "./reducers/settings";
import ListReducer from "./reducers/list";
import ListsReducer from "./reducers/lists";
import MemberRecuder from "./reducers/member";
import FieldSetDataRecuder from "./reducers/field-set-data";
import MembersRecuder from "./reducers/members";
import ListTypesReducer from "./reducers/list-types";
import ListSettingsReducer from "./reducers/list-settings";
import EmailTemplatesReducer from "./reducers/email-templates";

export const CLEAR_REDUX = "CLEAR_REDUX";

// main export, a function that creates the rootReducer
export default (history: any) => {
  const historyReducer: any = connectRouter(history);

  // first combine all app level reducers to a single reducer
  // KEEP THIS LIST SORTED ALPHABETICALLY
  const appReducers = combineReducers({
    app: AppStateReducer,
    confirm: ConfirmStateRecuder,
    emailTemplates: EmailTemplatesReducer,
    language: LanguageReducer,
    languages: LanguagesReducer,
    list: ListReducer,
    lists: ListsReducer,
    listTypes: ListTypesReducer,
    listSettings: ListSettingsReducer,
    member: MemberRecuder,
    fieldSetData: FieldSetDataRecuder,
    members: MembersRecuder,
    organization: OrganizationStateReducer,
    organizations: OrganizationsStateReducer,
    router: historyReducer,
    settings: SettingsReducer,
    tabs: TabsReducer,
    table: TableReducer,
    user: UserStateReducer,
  });

  // introduce root reducer that has power to reset redux state
  const rootReducer = (state: any, action: any) => {
    if (action.type === CLEAR_REDUX) {
      // change state to undefined --> other reducers will then default to initial state
      // note: maintain routing state
      const { router } = state;
      state = { router };
    }
    return appReducers(state, action);
  };

  return rootReducer;
};
