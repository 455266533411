import React, { FunctionComponent } from "react";
import { translate } from "utils/i18n";

// components
import PublicForm from "../elements/form";
import PublicNav from "../elements/nav";
import Form, { FormButtonProps } from "components/formik/form";
import { ButtonColorEnum } from "components/button";
import { goto } from "modules/app/navigation";
import { StageTypeEnum } from "./view";

// services
import { restore } from "services/forgotten-passwords";

// redux
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { useSelector } from "react-redux";
import { FieldTypeEnum } from "redux/models/field-type";
import FormikLayoutColumns from "components/formik/layout/columns";
import { FieldModel } from "components/formik/utils";

interface RestoreFormInterface {
  uuid?: string;
  onStageChange: (stage: StageTypeEnum) => void;
}

interface FormValues {
  newPassword: string;
  newPasswordConfirmed: string;
}

const RestoreForm: FunctionComponent<RestoreFormInterface> = ({ uuid, onStageChange }) => {
  useSelector(languageSelector, languageEquality);

  if (!uuid) {
    return null;
  }

  const getSchemaForRestore = () => {
    const schema: FieldModel[] = [
      {
        name: "newPassword",
        type: FieldTypeEnum.PASSWORD,
        placeholder: translate("public.forgottenPassword.placeholders.newPassword"),
        label: translate("public.forgottenPassword.labels.newPassword"),
        passwordOptions: { strength: true },
      },
      {
        name: "newPasswordConfirmed",
        type: FieldTypeEnum.PASSWORD,
        placeholder: translate("public.forgottenPassword.placeholders.newPasswordConfirmed"),
        label: translate("public.forgottenPassword.labels.newPasswordConfirmed"),
        passwordOptions: {
          submitOnEnter: true,
        },
      },
    ];
    return schema;
  };

  const getInitialValuesForRestore = () => {
    const initialValues: FormValues = { newPassword: "", newPasswordConfirmed: "" };
    return initialValues;
  };

  const getButtonsForRestore = () => {
    const buttons: FormButtonProps[] = [
      {
        children: translate("public.forgottenPassword.buttons.changePassword"),
        color: ButtonColorEnum.PUBLIC,
      },
    ];
    return buttons;
  };

  const onSubmitRestore = async (values: FormValues) => {
    const { newPassword, newPasswordConfirmed } = values;
    try {
      await restore(uuid, newPassword, newPasswordConfirmed);
    } catch (error) {
      return onStageChange(StageTypeEnum.RESTORE_ERROR);
    }

    // go to confirmation page
    onStageChange(StageTypeEnum.RESTORE_DONE);
  };

  const onRenderRestore = () => <FormikLayoutColumns formSchema={getSchemaForRestore()} />;

  return (
    <PublicForm>
      <Form
        initialValues={getInitialValuesForRestore()}
        buttons={getButtonsForRestore()}
        onSubmit={onSubmitRestore}
        onRender={onRenderRestore}
      />
      <PublicNav onClick={() => goto("/login")}>{translate("public.forgottenPassword.buttons.return")}</PublicNav>
    </PublicForm>
  );
};

export default RestoreForm;
