import React, { FunctionComponent } from "react";

// components
import { StyledForm } from "./form.styles";

interface PublicFormInterface {}

const PublicForm: FunctionComponent<PublicFormInterface> = ({ children }) => <StyledForm>{children}</StyledForm>;

export default PublicForm;
