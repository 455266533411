import React, { FunctionComponent } from "react";

// components
import { RouteInterface } from "./route";
import { StyledNavigationRoute, StyledOrganization, StyledOrganizationText } from "./organization.styles";
import { AlignEnum } from "./route.styles";

// utils
import { switchOrganization } from "utils/auth";
import { itemSort } from "utils/table";

// redux
import { AuthTypeEnum } from "redux/models/user";
import { selector as userSelector, equalityFn as userEquality } from "redux/hooks/user";
import { useSelector } from "react-redux";
import { selector as organizationsSelector, equalityFn as organizationsEquality } from "redux/hooks/organizations";
import { selector as organizationSelector, equalityFn as organizationEquality } from "redux/hooks/organization";
import { isSystemAdmin } from "redux/actions/user";

interface NavigationOrganizationInterface {}

const NavigationOrganization: FunctionComponent<NavigationOrganizationInterface> = () => {
  const organizationState = useSelector(organizationSelector, organizationEquality);
  const organizationsState = useSelector(organizationsSelector, organizationsEquality);
  const userState = useSelector(userSelector, userEquality);

  if (!organizationsState) {
    return null;
  }

  const fnOnClick = async (route: RouteInterface) => {
    const uuid = route.path;

    // get org uuid and org object
    const org = organizationsState.find(organization => organization.uuid === uuid);

    // change if org exists (and force user to home route)
    if (org) {
      await switchOrganization(org);
    }
  };

  // if we dont have current organization, do not render
  if (!organizationState) {
    return null;
  }

  // construct main route using current organization
  const route: RouteInterface = {
    name: organizationState.uuid || "",
    path: organizationState.uuid || "",
    text: organizationState.name || "",
  };

  // filter out organizations
  const filteredOrganizations = organizationsState.filter(
    org => isSystemAdmin() || (org.settings && org.settings.enabled === true)
  );

  // build array of orgs
  route.subroutes = filteredOrganizations
    .map(org => {
      const route: RouteInterface = {
        name: org.uuid || "",
        path: org.uuid || "",
        text: org.name || "",
      };
      return route;
    })
    .sort((a: any, b: any) => itemSort(a, b, ["text"], false));

  if (userState.authType === AuthTypeEnum.TOKEN) {
    // if token user, overwrite the selection
    route.subroutes = [];
  } else if (route.subroutes.length === 1) {
    // if only 1 route, do not bother showing dropdown
    route.subroutes = [];
  }

  // show different component, if nothing to navigate
  if (route.subroutes.length === 0) {
    return (
      <StyledOrganization>
        <StyledOrganizationText>{organizationState.name}</StyledOrganizationText>
      </StyledOrganization>
    );
  }

  // render the dropdown
  return <StyledNavigationRoute onClick={fnOnClick} route={{ ...route, align: AlignEnum.LEFT }} />;
};

export default NavigationOrganization;
