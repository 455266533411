import { ListTypesActionEnum } from "redux/reducers/list-types";
import { ListTypeModel } from "redux/models/list-type";
import { store } from "redux/store";
import { list as getListTypesFromServer } from "services/list-types";

export const update = async () => {
  const listTypes = await getListTypesFromServer();
  await store.dispatch({ type: ListTypesActionEnum.LIST_TYPES_UPDATE, listTypes });
};

export const list = () => {
  const state = store.getState();
  if (state.listTypes) {
    return [...state.listTypes] as ListTypeModel[];
  }
  return null;
};

export const get = (listTypeUuid: string) => {
  const listTypes = list() || [];

  // return a clone to prevent changes
  const listType = listTypes.find(listType => listType.uuid === listTypeUuid);
  if (listType) {
    return { ...listType } as ListTypeModel;
  }
  return undefined;
};
