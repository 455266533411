import { MemberModel } from "redux/models/member";

export enum MembersActionEnum {
  MEMBERS_UPDATE = "MEMBERS_UPDATE",
  MEMBERS_CLEAR = "MEMBERS_CLEAR",
}

// Initial state
const initialState: MemberModel[] | null = null;

// Reducer
const MembersStateReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case MembersActionEnum.MEMBERS_UPDATE:
      return [...action.members] as MemberModel[];

    case MembersActionEnum.MEMBERS_CLEAR:
      return null;

    default:
      return state;
  }
};

export default MembersStateReducer;
