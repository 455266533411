import styled from "styled-components/macro";

export const StyledErrorContainer = styled.div`
  ${props => props.theme.mixins.fontExo()};

  display: block;
  color: ${props => props.theme.colors.redNegative};
  margin-top: 8px;

  text-transform: initial;
`;
