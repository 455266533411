import { ListSettingsModel } from "redux/models/list-settings";

export enum ListSettingsActionEnum {
  LIST_SETTINGS_UPDATE = "LIST_SETTINGS_UPDATE",
  LIST_SETTINGS_CLEAR = "LIST_SETTINGS_CLEAR",
}

// Initial state
const initialState: ListSettingsModel | null = null;

// Reducer
const ListStateReducer = (state: ListSettingsModel | null = initialState, action: any): ListSettingsModel | null => {
  switch (action.type) {
    case ListSettingsActionEnum.LIST_SETTINGS_UPDATE:
      return { ...action.settings };

    case ListSettingsActionEnum.LIST_SETTINGS_CLEAR:
      return null;

    default:
      return state;
  }
};

export default ListStateReducer;
