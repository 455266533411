import { doGet, doPatch, doPost } from "utils/ajax";
import { ListTypeModel } from "redux/models/list-type";

export const list = async () => {
  const listTypes: ListTypeModel[] = await doGet("/lists/types");
  return listTypes;
};

export const get = async (listTypeUuid: string) => {
  const listType: ListTypeModel = await doGet(`/lists/types/${listTypeUuid}`);
  return listType;
};

export const update = async (listTypeUuid: string, json: Partial<ListTypeModel>) => {
  const listType: ListTypeModel = await doPatch(`/lists/types/${listTypeUuid}`, json);
  return listType;
};

export const create = async (json: ListTypeModel) => {
  const listType: ListTypeModel = await doPost(`/lists/types`, json);
  return listType;
};
