import React, { FunctionComponent } from "react";

// components
import { StyledList, StyledListItem, StyledText, StyledDelete } from "./list.styles";
import { ColorEnum } from "theme/colors";

export interface ListItemProps {
  value: string; // uuid or some other identifier
  label: string;
  locked?: boolean; // if true, cannot be deleted
}

interface ListProps {
  items: ListItemProps[];
  onClick?: (item: ListItemProps, index: number) => void; // if given, items can be "clicked/selected"
  onDelete?: (item: ListItemProps, index: number) => void; // if given, items can be deleted
}

const List: FunctionComponent<ListProps> = ({ items = [], onClick, onDelete }) => {
  return (
    <StyledList>
      {items.map((item, index: number) => {
        const fnOnClick = (event: any) => {
          if (onClick) {
            onClick(item, index);
          }
        };

        const fnOnDelete = (event: any) => {
          event.stopPropagation();
          if (onDelete) {
            onDelete(item, index);
          }
        };

        return (
          <StyledListItem key={index} onClick={fnOnClick} hasClick={!!onClick} hasDelete={!!onDelete}>
            <StyledText>{item.label}</StyledText>
            {onDelete && !item.locked && (
              <StyledDelete
                icon="times"
                onClick={fnOnDelete}
                size={10}
                color={ColorEnum.fontColor}
                hoverColor={ColorEnum.redNegative}
              />
            )}
          </StyledListItem>
        );
      })}
    </StyledList>
  );
};

export default List;
