import React, { FunctionComponent, useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";

// components
import { publicRoutes } from "./routes";
import TickerLoader from "components/loaders/ticker";
import PublicSplash from "../public/elements/splash";

// utils
import { changeLanguage } from "utils/i18n";
import { RouteModel } from "redux/models/route";
import { get } from "utils/local-storage";
import { LanguageEnum } from "consts";

// subroute handler
const SubRoutes = (route: RouteModel) => <Route path={route.path} render={props => <route.component {...props} />} />;

// private route which redirects unauthenticated request to the login page
const PublicRoute: FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // set language from local storage
    const language = get("language");
    changeLanguage(language as LanguageEnum);

    // update state
    setLoaded(true);
  }, []);

  // if not laoded, show progress bar etc
  if (!loaded) {
    return <TickerLoader />;
  }

  // render the requested route
  return (
    <PublicSplash>
      <Switch>
        {publicRoutes.map(route => (
          <SubRoutes key={route.path} {...route} />
        ))}
      </Switch>
    </PublicSplash>
  );
};

export default PublicRoute;
