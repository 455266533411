import { Validator } from "jsonschema";

const v = new Validator();

export const validate = (json: any, schema: object) => {
  try {
    v.validate(json, schema, { throwError: true });
  } catch (err) {
    console.warn(err);
    throw err;
  }
};
