import React, { FunctionComponent } from "react";

import Button, { ButtonColorEnum } from "./button";
import logo from "images/ticker_logo_circle.svg";
import { nl2br } from "utils/string";
import { StyledBulletin, StyledImage, StyledTitle, StyledText, StyledButtonWrapper } from "./bulletin.styles";

interface BulletinButtonProps {
  onClick: () => void;
  color?: ButtonColorEnum;
  text: string;
}

interface BulletinProps {
  className?: string;
  title: string;
  text: string;
  buttons?: BulletinButtonProps[];
}

const Bulletin: FunctionComponent<BulletinProps> = ({ className, title, text, buttons = [] }) => (
  <StyledBulletin className={className}>
    <StyledImage src={logo} alt="Ticker" />
    <StyledTitle>{title}</StyledTitle>
    <StyledText>{nl2br(text)}</StyledText>
    <StyledButtonWrapper>
      {buttons.map((button, index) => (
        <Button
          key={`bulletin-button-${index}`}
          onClick={button.onClick}
          color={button.color || ButtonColorEnum.PRIMARY}
        >
          {button.text}
        </Button>
      ))}
    </StyledButtonWrapper>
  </StyledBulletin>
);

export default Bulletin;
