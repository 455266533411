import React, { FunctionComponent } from "react";
import { translate } from "utils/i18n";

// components
import PublicForm from "../elements/form";
import PublicPlate from "../elements/plate";
import { PlateTypeEnum } from "../elements/plate.styles";

// redux
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { useSelector } from "react-redux";

interface LinkErrorInterface {}

const LinkError: FunctionComponent<LinkErrorInterface> = () => {
  useSelector(languageSelector, languageEquality);
  return (
    <PublicForm>
      <PublicPlate
        type={PlateTypeEnum.ERROR}
        title={translate("public.forgottenPassword.error.title_link")}
        text={translate("public.forgottenPassword.error.title_link")}
      />
    </PublicForm>
  );
};

export default LinkError;
