import { ListsActionEnum } from "redux/reducers/lists";
import { get as getLists } from "services/lists";
import { store } from "redux/store";
import { ListModel } from "redux/models/list";

export const update = async () => {
  try {
    const lists = await getLists();
    store.dispatch({
      type: ListsActionEnum.LISTS_UPDATE,
      lists,
    });
  } catch (ex) {
    store.dispatch({
      type: ListsActionEnum.LISTS_CLEAR,
    });
  }
};

// can be used to check if the user has access to a certain type of lists
export const hasListsOfType = (listsState: ListModel[], typeUuid: string) => {
  return listsState.find((list: ListModel) => list.type.uuid === typeUuid) !== undefined;
};
