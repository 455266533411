import styled, { css } from "styled-components/macro";

interface StyledLabelProps {
  checked?: boolean;
}

export const StyledWrapper = styled.div`
  margin-top: 0px;
`;

const LABEL_MARGIN = 26;

export const StyledLabel = styled.label<StyledLabelProps>`
  margin-left: ${LABEL_MARGIN}px;
  cursor: pointer;
  position: relative;
  ${props => props.theme.mixins.fontExo()};
  text-transform: normal;
  padding-top: 1px;
  padding-left: 1px;
  white-space: pre-line;
  display: inline-block;

  box-sizing: border-box;

  &::before {
    content: "";
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 10px;
    position: absolute;
    left: -${LABEL_MARGIN}px;
    border: 2px solid ${props => props.theme.colors.white};
    background-color: transparent;
    box-sizing: border-box;

    border-radius: 0px;

    ${props =>
      props.checked
        ? css`
            background-color: ${props.theme.colors.green};
            box-shadow: 0px 0px 0px 1px ${props.theme.colors.green};
          `
        : css`
            box-shadow: 0px 0px 0px 1px ${props.theme.colors.grayBorder};
          `};
  }

  &:hover {
    &::before {
      ${props =>
        !props.checked &&
        css`
          background-color: ${props.theme.colors.grayBorder};
        `}
    }
  }
`;

export const StyledInput = styled.input`
  display: none;
`;

export const StyledContainer = styled.div`
  margin-bottom: 11px;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const StyledErrorContainer = styled.div`
  ${props => props.theme.mixins.fontExo()};

  display: inline-block;
  color: ${props => props.theme.colors.redNegative};
  margin-top: 7px;
`;

export default {};
