import React, { FunctionComponent } from "react";
import styled from "styled-components/macro";

// components
import { StyledRow } from "./row";

export const StyledSeparator = styled.div`
  height: 1px;
  background-color: ${props => props.theme.colors.gray};
  margin: 30px 0 25px 0;

  /* smaller margin-top when after a row element */
  ${StyledRow} + & {
    margin-top: 15px;
  }
`;

interface SeparatorProps {
  className?: string;
}

const Separator: FunctionComponent<SeparatorProps> = ({ className = "" }) => <StyledSeparator className={className} />;

export default Separator;
