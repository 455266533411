import React, { FunctionComponent } from "react";
import { translate } from "utils/i18n";

// components
import PublicForm from "../elements/form";
import { StageTypeEnum } from "./view";
import PublicPlate from "../elements/plate";
import { PlateTypeEnum } from "../elements/plate.styles";

// redux
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { useSelector } from "react-redux";

interface EmailErrorInterface {
  onStageChange: (stage: StageTypeEnum) => void;
}

const EmailError: FunctionComponent<EmailErrorInterface> = ({ onStageChange }) => {
  useSelector(languageSelector, languageEquality);
  return (
    <PublicForm>
      <PublicPlate
        type={PlateTypeEnum.ERROR}
        title={translate("public.forgottenPassword.error.title_email")}
        text={translate("public.forgottenPassword.error.text_email")}
        button={{
          fn: () => onStageChange(StageTypeEnum.EMAIL),
          text: translate("public.forgottenPassword.buttons.retry"),
        }}
      />
    </PublicForm>
  );
};

export default EmailError;
