import React, { FunctionComponent } from "react";

// images
import logo from "images/Ticker_Logo_Nega_RGB_1100x558.png";
import { goto } from "../../app/navigation";

import { StyledPublicLogo, StyledImg } from "./logo.styles";

interface PublicLogoInterface {}

const PublicLogo: FunctionComponent<PublicLogoInterface> = () => {
  const gotoLogin = () => {
    goto("/login");
  };

  return (
    <StyledPublicLogo>
      <StyledImg src={logo} alt="Ticker" onClick={gotoLogin} />
    </StyledPublicLogo>
  );
};

export default PublicLogo;
