import React from "react";

// components
import NavigationMobile from "./mobile";
import NavigationDesktop from "./desktop";
import NavigationOrganization from "./elements/organization";

import { StyledNavigationBar, StyledNavigationBarContent } from "./bar.styles";

const NavigationBar = () => (
  <StyledNavigationBar>
    <StyledNavigationBarContent transparent nopadding>
      <NavigationOrganization />

      <NavigationDesktop />
      <NavigationMobile />
    </StyledNavigationBarContent>
  </StyledNavigationBar>
);

export default NavigationBar;
