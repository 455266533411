import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";
import { config } from "dotenv";

import AppRoot from "./modules/app/root";

// utils
import { goto } from "./modules/app/navigation";
import { uuid } from "./utils/uuid";
import { listen } from "./utils/local-storage";
import { get as getFromSessionStorage, set as setToSessionStorage } from "./utils/session-storage";
import { clearRedux } from "./utils/auth";

// load additional language packs
import "moment/locale/en-gb";
import "moment/locale/fi";
import "moment/locale/pl";
import "moment/locale/et";

// initialize font-awesome
import "./utils/fontawesome";

// load .env file
config();

// sanity check for .env
if (!process.env.REACT_APP_API_BASE_URL) {
  throw new Error("Dude, we are missing .env!");
}

// get instanceUuid from session (per tab/window)
let instanceUuid = getFromSessionStorage("instanceUuid");
if (!instanceUuid) {
  // generate an instance uuid, if not present
  instanceUuid = uuid();

  // remember inside this session
  setToSessionStorage("instanceUuid", instanceUuid);
}

// listen for instanceUuid changes
listen("instanceUuid", (oldValue: any, newValue: any) => {
  // we have logged in somewhere else, logout this tab
  if (newValue !== instanceUuid) {
    // clear redux only, do not clear cookies or anything, because that would affect the other tab too
    clearRedux();

    // force to login
    goto("/login");
  }
});

// app root
ReactDOM.render(<AppRoot />, document.getElementById("root"));
