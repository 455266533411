import React, { FunctionComponent } from "react";
import { ContentState } from "draft-js";
import { StyledLink } from "./link.styles";

interface LinkProps {
  contentState: ContentState;
  entityKey: string;
  children: any;
}

const Link: FunctionComponent<LinkProps> = ({ contentState, entityKey, children }) => {
  const { url } = contentState.getEntity(entityKey).getData();
  return <StyledLink href={url}>{children}</StyledLink>;
};

export default Link;
