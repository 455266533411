import { doGet, doPost, doPatch, doDelete } from "utils/ajax";
import { EmailTemplateModel } from "redux/models/email-templates";

export const list = async () => {
  const result = await doGet(`/email-templates`);
  return result.templates as EmailTemplateModel[];
};

export const remove = async (emailTemplateUuid: string) => {
  await doDelete(`/email-templates/${emailTemplateUuid}`);
};

export const create = async (templateJSON: EmailTemplateModel) => {
  const { contents } = templateJSON;
  delete templateJSON.contents;

  // create template
  const result = await doPost(`/email-templates`, templateJSON);
  const created = result.template;

  // create contents
  for (const contentJSON of contents) {
    await doPost(`/email-templates/${created.uuid}/contents`, contentJSON);
  }
  return created as EmailTemplateModel;
};

export const update = async (emailTemplateUuid: string, templateJSON: EmailTemplateModel) => {
  // separate contents
  const { contents } = templateJSON;
  delete templateJSON.contents;

  // update template
  await doPatch(`/email-templates/${emailTemplateUuid}`, templateJSON);

  // update contents
  for (const contentJSON of contents) {
    const contentUuid = contentJSON.uuid;
    delete contentJSON.uuid;
    if (contentUuid) {
      await doPatch(`/email-templates/${emailTemplateUuid}/contents/${contentUuid}`, contentJSON);
    } else {
      await doPost(`/email-templates/${emailTemplateUuid}/contents`, contentJSON);
    }
  }
};

export const createInclude = async (templateUuid: string, includeJSON: any) =>
  doPost(`/email-templates/${templateUuid}/email-template-includes`, includeJSON);

export const removeInclude = async (templateUuid: string, includeUuid: string) =>
  doDelete(`/email-templates/${templateUuid}/email-template-includes/${includeUuid}`);

export const createType = async (templateUuid: string, typeJSON: any) =>
  doPost(`/email-templates/${templateUuid}/email-template-list-types`, typeJSON);

export const removeType = async (templateUuid: string, listTypeUuid: string) =>
  doDelete(`/email-templates/${templateUuid}/email-template-list-types/${listTypeUuid}`);
