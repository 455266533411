import React, { Fragment } from "react";
import { translate, translateTo } from "utils/i18n";

import { ListTypeModel } from "redux/models/list-type";
import { LanguageEnum } from "consts";
import { UserModel } from "redux/models/user";
import { MessageModel } from "redux/models/message";
import { MessageEventDetailsModel } from "redux/models/event";

export const nl2br = (str: string) =>
  (str || "").split("\n").map((text: string, index: number) => (
    <Fragment key={`nl2br-${index}`}>
      {index !== 0 && <br />}
      {text}
    </Fragment>
  ));

export const formatUser = (user: UserModel) => {
  const { username = "", firstName = "", lastName = "" } = user;
  if (firstName || lastName) {
    return [firstName, lastName].filter((value: string) => !!value).join(" ");
  }
  return username;
};

export const formatListType = (listType: ListTypeModel, language?: LanguageEnum) => {
  const { alias, category } = listType;
  // custom alias given by customer
  if (alias) {
    return alias;
  }

  // localized name by category (or category string as fallback)
  if (language) {
    return translateTo(language, `lists.${category}.header`) || category;
  }
  return translate(`lists.${category}.header`) || category;
};

export const formatSubject = (message: MessageModel) => {
  const { template, event } = message;
  const { contents } = template;

  if (event) {
    const details = event.details as MessageEventDetailsModel;
    const content = contents.find(c => c.language === details.language);
    if (content) {
      return content.subject;
    }
  }
  return "";
};

export const formatSubjectTo = (message: MessageModel, language: LanguageEnum) => {
  const { template } = message;
  const { contents } = template;

  const content = contents.find(c => c.language === language);
  if (content) {
    return content.subject;
  }
  return "";
};
