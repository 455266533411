import { FunctionComponent } from "react";

interface DocTitleProps {
  title: string;
}

const DocTitle: FunctionComponent<DocTitleProps> = ({ title = "Ticker" }) => {
  document.title = `${process.env.NODE_ENV !== "production" ? "DEV " : ""}${title} | Ticker`;

  // just a dummy element, do not render anything
  return null;
};

export default DocTitle;
