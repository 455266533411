import React from "react";
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

// utils
const Notification = () => {
  return (
    <ToastContainer
      position={toast.POSITION.BOTTOM_RIGHT}
      autoClose={5000}
      pauseOnHover={false}
      draggable={false}
      pauseOnFocusLoss={false}
      transition={Slide}
      closeButton={false}
    />
  );
};

export default Notification;
