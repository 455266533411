import { ListModel } from "redux/models/list";

export enum ListsActionEnum {
  LISTS_UPDATE = "LISTS_UPDATE",
  LISTS_CLEAR = "LISTS_CLEAR",
}

// Initial state
const initialState: ListModel[] | null = null;

// Reducer
const ListsStateReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case ListsActionEnum.LISTS_UPDATE:
      const newState = action.lists ? [...action.lists] : [];
      return newState as ListModel[];

    case ListsActionEnum.LISTS_CLEAR:
      return null;

    default:
      return state;
  }
};

export default ListsStateReducer;
