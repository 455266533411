import { LanguagesActionEnum } from "redux/reducers/languages";
import { store } from "../store";
import { list as fetchAll } from "services/settings";
import { LanguageEnum } from "consts";

export const update = async () => {
  const settings = await fetchAll();
  const languages = Object.entries(settings.languageSettings)
    .filter(([key, value]) => key !== "uuid" && value)
    .map(([key]) => key as LanguageEnum);

  store.dispatch({ type: LanguagesActionEnum.LANGUAGES_UPDATE, languages });
};
