import React, { FunctionComponent } from "react";

// components
import Copyright from "./copyright";
import Logo from "./logo";
import PublicLanguage from "./language";

// assets
import { StyledPublicSplash, StyledContainer, StyledContent } from "./splash.styles";

interface PublicSplashInterface {}

const PublicSplash: FunctionComponent<PublicSplashInterface> = ({ children }) => {
  return (
    <StyledPublicSplash>
      <StyledContainer transparent nopadding>
        <Logo />
        <StyledContent>{children}</StyledContent>
        <PublicLanguage />
        <Copyright />
      </StyledContainer>
    </StyledPublicSplash>
  );
};

export default PublicSplash;
