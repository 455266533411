import { doPost } from "utils/ajax";

const { REACT_APP_TICKER_ERROR_X_AUTH_HEADER } = process.env;

export const report = (err: Error) => {
  // ignore, if no token
  if (!REACT_APP_TICKER_ERROR_X_AUTH_HEADER) {
    return;
  }

  // options with auth header
  const options = {
    headers: {
      "x-auth-token": REACT_APP_TICKER_ERROR_X_AUTH_HEADER,
    },
  };

  // send to server
  try {
    const { name, message, stack } = err;
    const json = { name, message, stack };
    doPost(`/error`, json, options);
  } catch (err) {
    // ignore
  }
};
