import { applyMiddleware, createStore, compose } from "redux";

import { get as getFromSessionStorage, set as setToSessionStorage } from "utils/session-storage";

import { middlewares, history } from "./middlewares";
import createRootReducer from "./reducer";
import { LanguageEnum } from "consts";
import { RouterModel } from "./models/router";
import { AppModel } from "./models/app";
import { ConfirmModel } from "./models/confirm";
import { UserModel } from "./models/user";
import { MemberModel } from "./models/member";
import { SettingsModel } from "./models/settings";
import { OrganizationModel } from "./models/organization";
import { TabsWrapperModel } from "./models/tabs";
import { EmailTemplateModel } from "./models/email-templates";
import { TableWrapperModel } from "./models/table";
import { ListSettingsModel } from "./models/list-settings";
import { ListModel } from "./models/list";
import { FieldSetDataModel } from "./models/field-set-data";
import { ListTypeModel } from "./models/list-type";

const STATE_KEY: string = "state";

export interface StateInterface {
  language: LanguageEnum;
  languages: LanguageEnum[];
  router: RouterModel;
  app: AppModel;
  confirm: ConfirmModel;
  user: UserModel;
  member: MemberModel | null;
  members: MemberModel[] | null;
  settings: SettingsModel;
  organization: OrganizationModel | null;
  organizations: OrganizationModel[] | null;
  tabs: TabsWrapperModel;
  emailTemplates: EmailTemplateModel[] | null;
  table: TableWrapperModel;
  listSettings: ListSettingsModel | null;
  lists: ListModel[] | null;
  fieldSetData: FieldSetDataModel[] | null;
  listTypes: ListTypeModel[] | null;
  list: ListModel | null;
}

const loadState = () => {
  try {
    const json = getFromSessionStorage(STATE_KEY) as string;
    return JSON.parse(json) as Partial<StateInterface>;
  } catch (err) {
    return undefined;
  }
};

const saveState = (state: Partial<StateInterface>) => {
  try {
    setToSessionStorage(STATE_KEY, JSON.stringify(state));
  } catch (err) {
    // pass
  }
};

// attach redux dev tools
const windowIfDefined = typeof window === "undefined" ? null : (window as any);
const composeEnhancers = windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// load current state
const currentState: any = loadState() || {};

// create the store
export const store = createStore(
  createRootReducer(history),
  { ...currentState },
  composeEnhancers(applyMiddleware(...middlewares))
);

// will update sessionstorage after every dispatch
store.subscribe(() => {
  // get certain fields from the store
  const {
    user,
    organization,
    organizations,
    language,
    languages,
    tabs,
    settings,
    table,
    lists,
    listTypes,
    emailTemplates,
  } = store.getState();

  // syncronize to session store
  saveState({
    user,
    organization,
    organizations,
    language,
    languages,
    tabs,
    settings,
    table,
    lists,
    listTypes,
    emailTemplates,
  });
});

export default store;
