export const unique = <T>(array: T[]) => {
  const newArray: T[] = [];
  for (const item of array) {
    if (!newArray.includes(item)) {
      newArray.push(item);
    }
  }

  return newArray;
};

export const sortBy = <T>(array: T[], attribute: string): T[] => {
  return array.sort((a: any, b: any) => {
    const valueA = a[attribute];
    const valueB = b[attribute];
    if (valueA < valueB) {
      return -1;
    } else if (valueA === valueB) {
      return 0;
    }
    return 1;
  });
};

export const chunk = <T>(array: T[], size: number): T[][] => {
  const chunks: T[][] = [];

  let iterator = 0;
  while (iterator < array.length) {
    chunks.push(array.slice(iterator, (iterator += size)));
  }

  return chunks;
};
