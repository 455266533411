import React, { FunctionComponent, Fragment } from "react";
import { translate } from "utils/i18n";

// components
import NavigationLanguage from "./elements/language";
import NavigationAccount from "./elements/account";
import { goto } from "modules/app/navigation";
import NavigationRoute, { RouteInterface, isListRoute, getListTypeUuidFromPath } from "./elements/route";
import { StyledNavigationDesktop } from "./desktop.styles";

// redux
import { RoleEnum } from "consts";
import { selector as listTypesSelector, equalityFn as listTypesEquality } from "redux/hooks/list-types";
import { hasListsOfType } from "redux/actions/lists";
import { hasRole, isTokenUser } from "redux/actions/user";
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { useSelector } from "react-redux";
import { selector as listsSelector, equalityFn as listsEquality } from "redux/hooks/lists";
import { selector as routerSelector, equalityFn as routerEquality } from "redux/hooks/router";
import { selector as appSelector, equalityFn as appEquality } from "redux/hooks/app";

// utils
import { formatListType } from "utils/string";
import { plainSort } from "utils/table";

interface NavigationDesktopInterface {}

const NavigationDesktop: FunctionComponent<NavigationDesktopInterface> = () => {
  useSelector(languageSelector, languageEquality);
  const listTypesState = useSelector(listTypesSelector, listTypesEquality);
  const listsState = useSelector(listsSelector, listsEquality);
  const routerState = useSelector(routerSelector, routerEquality);
  const currentPath = routerState.location.pathname;
  const appState = useSelector(appSelector, appEquality);
  const { navigationReference } = appState;

  if (!listTypesState) {
    return null;
  }

  // convert list types into an array of routes
  const routes: RouteInterface[] = [];
  listTypesState
    // sort first
    .sort((a, b) => plainSort(a.sorting, b.sorting, false))
    // then create a route for each
    .forEach(listType => {
      const { uuid, settings } = listType;
      const route = { name: uuid || "", path: `/lists/${uuid}`, text: formatListType(listType) };

      // skip disabled list types
      if (!settings.enabled) {
        return;
      }

      // if unique, but the user does not have any lsits, skip
      if (settings.unique && listsState) {
        // ignore, if the user has not access to an unique list
        if (!hasListsOfType(listsState, listType.uuid || "")) {
          return null;
        }

        // change route to match the first actual list
        const list = listsState.find(list => list.type.uuid === listType.uuid);
        if (list) {
          route.path = `/list/${list.uuid}`;
        }
      }

      // push to be rendered
      routes.push(route);
    });

  // add "All lists" route
  const homeRoute = { name: "home", text: translate("home"), path: "/lists" };
  routes.splice(0, 0, homeRoute);

  const fnOnClick = (route: RouteInterface) => {
    const { path } = route;
    if (path) {
      goto(path);
    }
  };

  const getRouteText = () => {
    let text = translate("chooseView");

    routes.forEach(route => {
      if (!route.path || !route.text) {
        return;
      }

      // test for exact match
      if (currentPath === route.path) {
        text = route.text;
      }

      if (isListRoute(currentPath)) {
        const listTypeUuid = getListTypeUuidFromPath(route.path);
        if (navigationReference === listTypeUuid) {
          text = route.text;
        }
      }
    });

    return text;
  };

  const renderRoutes = () => {
    if (routes.length >= 5) {
      const wrapperRoute: RouteInterface = { name: "not-used", subroutes: routes, text: getRouteText() };
      return <NavigationRoute route={wrapperRoute} onClick={fnOnClick} />;
    }
    return (
      <Fragment>
        {routes.map(route => (
          <NavigationRoute onClick={fnOnClick} route={route} key={route.path} />
        ))}
      </Fragment>
    );
  };

  return (
    <StyledNavigationDesktop>
      {!isTokenUser() && hasRole(RoleEnum.INSIDER_LIST_ADMIN) && renderRoutes()}
      <NavigationLanguage />
      <NavigationAccount />
    </StyledNavigationDesktop>
  );
};

export default NavigationDesktop;
