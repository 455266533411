import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components/macro";
import { ColorEnum } from "theme/colors";

interface IconProps {
  id?: string;
  fontSize: number;
}

export const StyledIcon = styled(FontAwesomeIcon)<IconProps>`
  ${props => props.theme.mixins.noSelect()};

  font-size: ${props => props.fontSize}px;
  display: inline;
`;

interface IconWrapperProps {
  padding: number;
  onClick?: (event?: any) => void;
  color: ColorEnum;
  hoverColor: ColorEnum;
}

// styled components
export const StyledIconWrapper = styled.div<IconWrapperProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;

  ${StyledIcon} {
    color: ${props => props.color};
  }

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;

      &:hover {
        ${StyledIcon} {
          cursor: pointer;
          color: ${props.hoverColor};
        }
      }
    `};
`;
