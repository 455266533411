import React, { FunctionComponent, Fragment } from "react";
import { EditorState } from "draft-js";
import { translate } from "utils/i18n";
import { StyledToolbox, StyledButton } from "./toolbox.styles";

interface InlineStyleControlsProps {
  editorState: EditorState;
  onToggleInline: (style: string) => void;
  onToggleBlock: (style: string) => void;
  onToggleLink: () => void;
}
interface ButtonDefinition {
  label: string;
  style: string;
}

const Toolbox: FunctionComponent<InlineStyleControlsProps> = ({
  editorState,
  onToggleInline,
  onToggleBlock,
  onToggleLink,
}) => {
  var currentStyle = editorState.getCurrentInlineStyle();
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const BLOCK_BUTTONS: ButtonDefinition[] = [
    { label: translate("markdownEditor.styles.headerOne"), style: "header-one" },
    { label: translate("markdownEditor.styles.headerTwo"), style: "header-two" },
    { label: translate("markdownEditor.styles.headerThree"), style: "header-three" },
  ];

  const LIST_BUTTONS: ButtonDefinition[] = [
    { label: translate("markdownEditor.styles.ol"), style: "ordered-list-item" },
    { label: translate("markdownEditor.styles.ul"), style: "unordered-list-item" },
  ];

  const INLINE_BUTTONS: ButtonDefinition[] = [
    { label: translate("markdownEditor.styles.bold"), style: "BOLD" },
    { label: translate("markdownEditor.styles.italic"), style: "ITALIC" },
    { label: translate("markdownEditor.styles.underline"), style: "UNDERLINE" },
    { label: translate("markdownEditor.styles.strikethrough"), style: "STRIKETHROUGH" },
  ];

  const LINK_BUTTONS: ButtonDefinition[] = [{ label: translate("markdownEditor.styles.link"), style: "LINK" }];

  const renderButton = (
    active: boolean,
    last: boolean,
    definition: ButtonDefinition,
    onClick: (style: string) => void
  ) => {
    return (
      <StyledButton
        key={definition.style}
        active={active}
        last={last}
        onClick={(event: any) => {
          event.stopPropagation();
          onClick(definition.style);
          return false;
        }}
      >
        {definition.label}
      </StyledButton>
    );
  };

  const renderInlineButtons = () => {
    return (
      <Fragment>
        {INLINE_BUTTONS.map((definition, index) =>
          renderButton(
            currentStyle.has(definition.style),
            index === INLINE_BUTTONS.length - 1,
            definition,
            onToggleInline
          )
        )}
      </Fragment>
    );
  };

  const renderBlockButtons = () => {
    return (
      <Fragment>
        {BLOCK_BUTTONS.map((definition, index) =>
          renderButton(definition.style === blockType, index === BLOCK_BUTTONS.length - 1, definition, onToggleBlock)
        )}
      </Fragment>
    );
  };

  const renderListButtons = () => {
    return (
      <Fragment>
        {LIST_BUTTONS.map((definition, index) =>
          renderButton(definition.style === blockType, index === LIST_BUTTONS.length - 1, definition, onToggleBlock)
        )}
      </Fragment>
    );
  };

  const renderLinkButtons = () => {
    return (
      <Fragment>
        {LINK_BUTTONS.map((definition, index) =>
          renderButton(definition.style === blockType, index === LINK_BUTTONS.length - 1, definition, onToggleLink)
        )}
      </Fragment>
    );
  };

  return (
    <StyledToolbox>
      {renderInlineButtons()}
      {renderBlockButtons()}
      {renderListButtons()}
      {renderLinkButtons()}
    </StyledToolbox>
  );
};

export default Toolbox;
