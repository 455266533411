import React, { FunctionComponent } from "react";

// utils
import { StyledNavigationSubroute } from "./subroute.styles";
import { RouteInterface } from "./route";

interface NavigationSubrouteInterface {
  route: RouteInterface;
  onClick?: (route: RouteInterface) => void;
}

const NavigationSubroute: FunctionComponent<NavigationSubrouteInterface> = ({ route, onClick }) => {
  const fnOnClick = (event: any) => {
    // prevent leaking
    event.stopPropagation();

    // delegate to actual callback
    if (onClick) {
      onClick(route);
    }
  };

  return <StyledNavigationSubroute onClick={fnOnClick}>{route.text}</StyledNavigationSubroute>;
};

export default NavigationSubroute;
