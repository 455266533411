import styled, { css } from "styled-components/macro";

import Icon from "components/icon";

import { StyledInput, StyledContainer } from "./checkbox.styles";
import { StyledErrorContainer } from "../error.styles";

export const StyledTickInput = styled(StyledInput)``;

export const StyledTickContainer = styled(StyledContainer)`
  display: flex;
  height: 40px;
  margin: 0;
  align-items: center;

  ${StyledErrorContainer} {
    margin-top: 0px;
    margin-left: 7px;
  }
`;

export const StyledTickIcon = styled(Icon)`
  display: none;
`;

export const StyledTickLabel = styled.label`
  margin-left: 60px;
  cursor: pointer;
  box-sizing: border-box;
  margin-bottom: 0px;
  font-weight: 400;
  flex: 0;
  white-space: nowrap;
`;

interface MyProps {
  checked: boolean;
}

export const StyledTickBox = styled.label<MyProps>`
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.gray};

  ${props =>
    props.checked &&
    css`
      border-color: ${props.theme.colors.green};
      ${StyledTickIcon} {
        display: inline-block;
      }
    `};
`;
