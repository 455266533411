import styled from "styled-components/macro";

export const StyledFormikFieldContainer = styled.div`
  display: inline-block;
  width: 100%;
  vertical-align: top;
  position: relative;
  margin-bottom: 17px;

  input,
  select,
  textarea {
    box-sizing: border-box;
    &:focus {
      outline: 0px transparent;
      box-shadow: none;
    }
  }
`;

export const StyledWrapper = styled.div`
  position: relative;
`;

export default StyledFormikFieldContainer;
