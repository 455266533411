import styled, { css } from "styled-components/macro";

import { StyledNav } from "./nav.styles";
import Icon from "components/icon";
import Image from "components/image";

// plate types
export enum PlateTypeEnum {
  NORMAL = "normal",
  ERROR = "error",
}

export const StyledTitle = styled.div`
  ${props => props.theme.mixins.fontFira()};
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: 400;
`;

export const StyleImage = styled(Image)`
  margin-bottom: 20px;
`;

export const StyledIcon = styled(Icon)`
  margin-bottom: 20px;
`;

interface StyledPlateInterface {
  type: PlateTypeEnum;
}

export const StyledPlate = styled.div<StyledPlateInterface>`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.black};
  text-align: center;
  text-transform: initial;

  ${props =>
    props.type === PlateTypeEnum.NORMAL &&
    css`
      ${StyledTitle} {
        color: ${props.theme.colors.greenAlternative};
      }
    `}
  ${props =>
    props.type === PlateTypeEnum.ERROR &&
    css`
      ${StyledTitle} {
        color: ${props.theme.colors.redNegative};
      }
    `};

  ${StyledNav} {
    display: inline-block;
    margin-top: 20px;
  }
`;

export const StyledText = styled.div`
  ${props => props.theme.mixins.fontFira()};
`;
