import { doPost, doGet, doPatch } from "utils/ajax";

export const create = async (username: string) => doPost("/forgotten-passwords", { username });

export const get = async (uuid: string) => {
  const result = await doGet(`/forgotten-passwords/${uuid}`);
  return result.link;
};

export const restore = async (uuid: string, newPassword: string, newPasswordConfirmed: string) => {
  await doPatch(`/forgotten-passwords/${uuid}`, { newPassword, newPasswordConfirmed });
};
