import React, { FunctionComponent } from "react";
import { translate } from "utils/i18n";

// components
import PublicForm from "../elements/form";
import PublicPlate from "../elements/plate";
import { PlateTypeEnum } from "../elements/plate.styles";
import { goto } from "modules/app/navigation";

// redux
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { useSelector } from "react-redux";

interface RestoreDoneInterface {}

const RestoreDone: FunctionComponent<RestoreDoneInterface> = () => {
  useSelector(languageSelector, languageEquality);
  return (
    <PublicForm>
      <PublicPlate
        type={PlateTypeEnum.NORMAL}
        title={translate("public.forgottenPassword.success.title_restore")}
        text={translate("public.forgottenPassword.success.text_restore")}
        button={{
          fn: () => goto("/login"),
          text: translate("public.forgottenPassword.success.button_continue"),
        }}
      />
    </PublicForm>
  );
};

export default RestoreDone;
