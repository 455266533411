import { LanguageEnum } from "consts";

export enum EmailIncludeEnum {
  FORGOTTEN_PASSWORD = "forgotten_password",
  LOGIN_LINK = "login_link",
  VERIFICATION_CODE = "verification_code",
}

export interface EmailTemplateContentOptionModel {
  ctaText?: string;
}

export interface EmailTemplateContentModel {
  uuid: string;
  language: LanguageEnum;
  subject: string;
  content: string;
  optionals: EmailTemplateContentOptionModel;
}

export interface EmailTemplateIncludeModel {
  uuid: string;
  include: EmailIncludeEnum;
}

export interface EmailTemplateModel {
  uuid: string;
  contents: EmailTemplateContentModel[];
  static: boolean;
  alias: string;
  includes: EmailTemplateIncludeModel[];
  listTypes: string[];
  created_at?: string;
  updated_at?: string;
}
