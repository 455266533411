import React, { FunctionComponent, Fragment } from "react";

// utils
import { translateTo } from "utils/i18n";
import { getLanguages, changeLanguage } from "utils/i18n";
import { StyledPipe } from "./splash.styles";
import { LanguageEnum } from "consts";

import { StyledPublicLanguageContainer, StyledLanguageItem } from "./language.styles";
import { useSelector } from "react-redux";
import { selector, equalityFn } from "redux/hooks/language";

interface PublicLanguageInterface {}

const PublicLanguage: FunctionComponent<PublicLanguageInterface> = () => {
  const languageState = useSelector(selector, equalityFn);

  const onChange = (lang: LanguageEnum) => {
    changeLanguage(lang);
  };

  const renderPipe = () => <StyledPipe>|</StyledPipe>;

  const languages = getLanguages();
  const ref = onChange;

  return (
    <StyledPublicLanguageContainer>
      {languages.map((lang, index) => {
        const name = translateTo(lang, `languages.${lang}`);

        const fn = () => {
          ref(lang);
        };
        return (
          <Fragment key={`public-language-${lang}`}>
            {index !== 0 && renderPipe()}{" "}
            <StyledLanguageItem active={lang === languageState} onClick={fn}>
              {name}
            </StyledLanguageItem>
          </Fragment>
        );
      })}
    </StyledPublicLanguageContainer>
  );
};

export default PublicLanguage;
