import styled, { css } from "styled-components/macro";

import Layer from "./layer";
import Icon from "../icon";
import { ZEnum } from "theme/layers";

import { ModalSizeEnum } from "./modal";
import { ColorEnum } from "theme/colors";

interface ModalSizeProps {
  modalSize?: ModalSizeEnum;
}

export const StyledModal = styled.div`
  z-index: ${ZEnum.MODAL};
`;

export const StyledLayer = styled(Layer)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  background-color: ${props => props.theme.colors.grayBlue};
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: default;
`;

export const StyledBar = styled.div<ModalSizeProps>`
  position: relative;
  width: 75%;
  ${props => props.theme.media.phone`width: 90%;`};
  max-width: 1160px;
  z-index: ${ZEnum.MODAL};
  padding: 0 40px;

  ${props =>
    props.modalSize === ModalSizeEnum.SMALL &&
    css`
      max-width: 580px;
    `};

  ${props =>
    props.modalSize === ModalSizeEnum.LARGE &&
    css`
      max-width: none;
      width: calc(100% - 60px);
    `};
`;

export const StyledIcon = styled(Icon)<ModalSizeProps>`
  opacity: 1 !important;
  position: absolute;
  top: -20px;
  right: -20px;

  background-color: ${props => props.theme.colors.white};
  height: 40px !important;
  width: 40px !important;
  padding: 8px;
  border-radius: 50%;
  box-sizing: border-box;

  ${props =>
    props.modalSize === ModalSizeEnum.LARGE &&
    css`
      top: -20px;
      right: -20px;
    `};

  &:hover {
    background-color: ${ColorEnum.greenLight};
  }
`;

export const StyledFrame = styled.div<ModalSizeProps>`
  position: relative;
  padding: 30px;
  background-color: ${props => props.theme.colors.white};
  width: 75%;
  ${props => props.theme.media.phone`width: 90%;`};

  max-width: 1160px;
  max-height: calc(90%);
  overflow: auto;
  cursor: default;

  h1 {
    margin-bottom: 30px;
  }

  h2 {
    margin-top: 0;
  }

  button {
    margin-bottom: 0px;
  }

  ${props =>
    props.modalSize === ModalSizeEnum.SMALL &&
    css`
      max-width: 580px;
    `};

  ${props =>
    props.modalSize === ModalSizeEnum.LARGE &&
    css`
      max-width: none;
      width: calc(100% - 60px);

      max-height: calc(100% - 60px);
      padding: 30px;
    `};
`;

export default StyledModal;
