import styled from "styled-components/macro";

import Button from "components/button";
import { StyledFrame, StyledBar, StyledIcon } from "components/layout/modal.styles";
import { StyledSeparator } from "components/layout/separator";

export const StyledFrameExt = styled(StyledFrame)`
  width: 100%;
  max-width: 316px;
  max-height: auto;
  padding: 30px;
  overflow: hidden;
`;

export const StyledBarExt = styled(StyledBar)`
  width: 100%;
  max-width: 316px;
`;

export const StyledIconExt = styled(StyledIcon)``;

export const StyledSeparatorExt = styled(StyledSeparator)`
  margin: 5px;
`;

export const StyleButtonContainer = styled.div`
  position: relative;
  height: 60px;
  margin-top: 20px;
`;

export const StyledButton = styled(Button)`
  position: absolute;
  width: calc(50% - 5px);
  padding-top: 12px;
  padding-bottom: 12px;
`;

export const StyledButtonLeft = styled(StyledButton)`
  left: 0px;
`;

export const StyledButtonRight = styled(StyledButton)`
  right: 0px;
`;
