import React, { FunctionComponent, useState, useRef } from "react";
import { Formik, Form as FormikForm, FormikProps, FormikHelpers } from "formik";
import { translate } from "utils/i18n";

import Col from "../layout/col";
import Button, { ButtonProps } from "../button";

import { StyledButtonRow, StyledFormikWrapper } from "./form.styles";
import { failure } from "utils/notification";

export interface FormButtonProps extends ButtonProps {
  // for buttons like cancel or close, all other buttons will go through build-in onSubmit
  fn?: (values: any, formikHelpers: FormikHelpers<any>) => void | Promise<void>;
}

export interface FormProps {
  buttons?: FormButtonProps[] | ((formikProps: FormikProps<any>) => FormButtonProps[]);

  // this is a must be, needs to match to the rendered structure
  initialValues: any;

  // this also needs to match the schema
  validationSchema?: any;

  // optional method to allow manual rendering
  onRender: (formikProps: FormikProps<any>) => any;

  // generic handler for onSubmit
  onSubmit?: (values: any, formikHelpers: FormikHelpers<any>) => Promise<void>;

  // enables debug mode
  debug?: boolean;
}

const Form: FunctionComponent<FormProps> = ({
  onRender,
  initialValues = {},
  validationSchema,
  onSubmit = async () => {},
  buttons,
  debug = false,
}) => {
  const [sendingButtonIndex, setSendingButtonIndex] = useState<number | undefined>(undefined);
  // create a reference to be used with the form
  const ref = useRef<HTMLDivElement>(null);

  // builds the form
  const buildForm = (formikProps: FormikProps<any>) => {
    return onRender(formikProps);
  };

  // each button can have a callback, not required.
  // the callback will be called first
  const buildButtons = (buttons: FormButtonProps[], formikProps: FormikProps<any>) => {
    return (
      <StyledButtonRow>
        {buttons.map((button, index) => {
          const { fn, children, isDisabled } = button;

          // handle onClick for the button
          const handleClick = async () => {
            // set as submitting
            setSendingButtonIndex(index);

            if (fn) {
              // set form to submitting state
              formikProps.setSubmitting(true);

              // invoke the defined method (NOTICE: does not validate, so do the validation manually in the "fn")
              fn(formikProps.values, formikProps);
            } else {
              // find formikwrapper DOM element before validation (validation causes rerender and the ref is nullified)
              const formikWrapper = ref.current;

              // validates and goes to "onSubmit", if the form is valid
              await formikProps.submitForm();

              try {
                if (!formikWrapper) {
                  return null;
                }
                // after validation, if errors exists, scroll to first
                const errors = formikWrapper.getElementsByClassName("error");
                if (errors.length > 0) {
                  const firstError: any = errors[0];
                  if (firstError) {
                    const firstFieldWithError = firstError.parentElement.parentElement;
                    firstFieldWithError.scrollIntoView();
                    failure(translate("fillAllRequired"));
                  }
                }
              } catch (err) {
                // ignore the error, this might be rather error prone code
                // but not critical for execution
              }
            }
          };

          const isLoading =
            formikProps.isSubmitting && (sendingButtonIndex === index || sendingButtonIndex === undefined);

          // render the button
          return (
            <Col key={`button-${index}`}>
              <Button
                // use type button to avoid auto-enter-submit
                type={"button"}
                key={index}
                // pass on normal button properties
                {...button}
                isDisabled={isDisabled}
                // loading, if sending and button index matches (or if sendingButtonIndex is undefined)
                isLoading={isLoading}
                // wrap onClick, use existing, if defined
                onClick={handleClick}
              >
                {children}
              </Button>
            </Col>
          );
        })}
      </StyledButtonRow>
    );
  };

  if (debug) {
    console.groupCollapsed("DEBUG: FORM RENDER");
  }

  return (
    <StyledFormikWrapper ref={ref}>
      <Formik
        enableReinitialize
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formikProps: FormikProps<any>) => {
          // debug
          if (debug) {
            console.info("Initial values:");
            console.info(initialValues);
            console.info("Values:");
            console.info(formikProps.values);
            console.info("Errors:");
            console.info(formikProps.errors);
          }

          // build buttons
          const buttonArray = typeof buttons === "function" ? buttons(formikProps) : buttons;
          if (debug) {
            console.info("Buttons:");
            console.info(buttonArray);
          }

          // render the content
          const rendered = (
            <FormikForm>
              {buildForm(formikProps)}
              {buttonArray && buildButtons(buttonArray, formikProps)}
            </FormikForm>
          );
          if (debug) {
            console.groupEnd();
          }
          return rendered;
        }}
      </Formik>
    </StyledFormikWrapper>
  );
};

export default Form;
