import styled from "styled-components/macro";

import { StyledLabel, StyledInput, StyledContainer, StyledErrorContainer } from "./checkbox.styles";

export const StyledWrapper = styled.div``;

export const StyledLabelRadio = styled(StyledLabel)`
  &::before {
    border-radius: 50%;
  }
`;

export { StyledLabel, StyledInput, StyledContainer, StyledErrorContainer };
