import styled from "styled-components/macro";

import Icon from "components/icon";
import { ZEnum } from "theme/layers";
import { transition } from "theme/mixins";

export const StyledSplash = styled.div`
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  z-index: ${ZEnum.HIGH};
  background-color: ${props => props.theme.mixins.hexToRgba(props.theme.colors.white, 0.94)};

  display: flex;

  opacity: 0;
  ${transition(1, "opacity")};

  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledBox = styled.div`
  text-align: center;
  width: 400px;
  display: block;
`;

export const StyledIcon = styled(Icon)`
  margin-bottom: 20px;
`;

export const StyledTitle = styled.div`
  ${props => props.theme.mixins.fontFira()};
  color: ${props => props.theme.colors.green};
  font-size: 36px;
  margin-bottom: 20px;
`;

export const StyledText = styled.div`
  ${props => props.theme.mixins.fontFira()};
  color: ${props => props.theme.colors.fontColor};
`;

export default {};
