import styled from "styled-components/macro";

import StyledNav from "./nav";
import Icon from "components/icon";

export const StyledCopyright = styled(StyledNav)`
  margin-top: 60px;
  display: flex;
`;

export const StyledIcon = styled(Icon)`
  margin-right: 5px;
`;
