import styled, { css } from "styled-components/macro";

interface MyProps {
  height?: number;
  error?: any;
}

// styled components
export const StyledTextarea = styled.textarea<MyProps>`
  background-color: ${props => props.theme.colors.grayLight};
  border-radius: 0px;
  padding: 10px 16px;
  width: 100%;
  min-height: 50px;
  height: ${props => `${props.height || 150}px`};
  ${props => props.theme.mixins.fontFira()};
  font-weight: 400;
  line-height: 1.4;
  resize: vertical;

  border: 1px solid ${props => props.theme.colors.grayBorder};

  &:focus {
    border-color: ${props => props.theme.colors.green};
  }

  ::placeholder {
    color: ${props => props.theme.colors.grayPlaceholder};
    font-style: italic;
    text-transform: inherit;
  }

  ${props =>
    props.error &&
    css`
      border-color: ${props.theme.colors.redNegative} !important;
    `};
`;
