import React, { FunctionComponent } from "react";

// components
import MonthSelector from "./month-selector";
import YearSelector from "./year-selector";

import { StyledViewSelector } from "./view-selector.styles";

interface ViewSelectorProps {
  className?: string;
  day: number;
  month: number;
  year: number;
  onChange: (day: number, month: number, year: number) => void;
}

const ViewSelector: FunctionComponent<ViewSelectorProps> = ({ className, day, month, year, onChange }) => {
  const onChangeMonth = (newMonth: number) => onChange(day, newMonth, year);

  const onChangeYear = (newYear: number) => onChange(day, month, newYear);

  return (
    <StyledViewSelector className={className}>
      <MonthSelector month={month} onChange={onChangeMonth} />
      <YearSelector year={year} onChange={onChangeYear} />
    </StyledViewSelector>
  );
};

export default ViewSelector;
