import styled from "styled-components/macro";
import { StyledLabel } from "./component-dropdown.styles";

export const StyledYearSelector = styled.div`
  position: absolute;
  right: 0px;

  ${StyledLabel} {
    ${props => props.theme.mixins.fontFira()};
    color: ${props => props.theme.colors.green};
    font-size: 16px;
    font-weight: 400;
  }
`;
