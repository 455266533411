import { saveAs } from "file-saver";

import { AttachmentModel } from "redux/models/attachment";
import { AttachmentLinkModel } from "redux/models/attachment-link";
import { validateSize } from "./attachment";

export enum FileError {
  FILETYPE = "filetype",
  TOOLARGE = "toolarge",
}

// Validation that prevents browser crash with huge filee
export const validateFile = (attachments: AttachmentModel[], file: File) => {
  // check if the file itself is fine
  if (!isFileAllowed(file)) {
    throw new Error(FileError.FILETYPE);
  }

  if (!validateSize(attachments, file.size)) {
    throw new Error(FileError.TOOLARGE);
  }
};

export const bytesToMegaBytes = (bytes: number) => Math.floor(bytes / 1024 / 1024);

export const uploadFileAsBase64 = async (file: File, callback: (attachment: AttachmentModel) => void) => {
  const reader = new FileReader();
  reader.addEventListener(
    "load",
    () => {
      const result: any = reader.result;

      // should be only string in our case, TS required this check :P
      if (typeof result === "string") {
        callback({
          name: file.name,
          content: result.split(",")[1],
          format: "base64",
          contentType: file.type,
          size: result.split(",")[1].length,
        });
      }
    },
    false
  );

  // read file as data url
  await reader.readAsDataURL(file);
};

export const sizeAsMb = (size: number) => size / 1024 / 1024;

export const AllowedMimes = [
  "application/pdf",
  "application/json",
  "application/xml",
  "application/rtf",
  "text/csv",
  "text/plain",
  "text/richtext",
  "image/jpeg",
  "image/png",
  "image/gif",
  "application/vnd.ms-excel",
  "application/vnd.ms-powerpoint",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.apple.keynote",
  "application/vnd.apple.numbers",
  "application/vnd.apple.pages",
];

export const isFileAllowed = (file: File) => {
  const allowed = AllowedMimes.includes(file.type);
  return allowed;
};

export const base64ToBlob = (base64: string, type: string = "image/jpeg") => {
  const byteString = atob(base64);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type });
};

export const downloadBlob = (blob: Blob, filename: string) => {
  if (navigator.msSaveOrOpenBlob) {
    // native ie/edge thingie?
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    // use file-saver
    saveAs(blob, filename);
  }
};

const { REACT_APP_API_BASE_URL } = process.env;

export const composeAttachmentUrl = (attachment: AttachmentModel) => {
  const { attachmentLink } = attachment;
  if (attachmentLink) {
    const { uuid, hash } = attachmentLink;
    const url = `${
      (REACT_APP_API_BASE_URL as string).includes("localhost") ? "http://localhost:3000" : ""
    }/api/v1/attachment/${uuid}?hash=${hash}`;
    return url;
  }
  return "";
};

export const downloadAttachment = (attachment: AttachmentModel) => {
  const { attachmentLink, name, content, contentType } = attachment;
  if (attachmentLink) {
    const url = composeAttachmentUrl(attachment);
    window.open(url, "_blank");
  } else if (content) {
    const blob = base64ToBlob(content, contentType);
    downloadBlob(blob, name);
  } else {
    throw new Error("Something wrong with an attachment");
  }
};

export const downloadAttachmentLink = (attachmentLink: AttachmentLinkModel) => {
  // compile a reversed json and pass to previous method
  const { attachment } = attachmentLink;
  downloadAttachment({ ...attachment, attachmentLink });
};
