import React, { FunctionComponent } from "react";

import { StyledPulseLoader } from "./pulse.styles";

interface PulseLoaderComponentProps {
  className?: string;
}

const PulseLoader: FunctionComponent<PulseLoaderComponentProps> = ({ className = "" }) => {
  return (
    <StyledPulseLoader className={className}>
      <svg viewBox="0 0 100 40" preserveAspectRatio="xMidYMid">
        <g transform="translate(20 20)">
          <circle cx="0" cy="0" r="8" fill="#ffffff" transform="scale(0.330424 0.330424)">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.375s"
              calcMode="spline"
              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
              values="0;1;0"
              keyTimes="0;0.5;1"
              dur="1s"
              repeatCount="indefinite"
            />
          </circle>
        </g>
        <g transform="translate(40 20)">
          <circle cx="0" cy="0" r="8" fill="#ffffff" transform="scale(0.0519456 0.0519456)">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.25s"
              calcMode="spline"
              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
              values="0;1;0"
              keyTimes="0;0.5;1"
              dur="1s"
              repeatCount="indefinite"
            />
          </circle>
        </g>
        <g transform="translate(60 20)">
          <circle cx="0" cy="0" r="8" fill="#ffffff" transform="scale(0.0455507 0.0455507)">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.125s"
              calcMode="spline"
              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
              values="0;1;0"
              keyTimes="0;0.5;1"
              dur="1s"
              repeatCount="indefinite"
            />
          </circle>
        </g>
        <g transform="translate(80 20)">
          <circle cx="0" cy="0" r="8" fill="#ffffff" transform="scale(0.318294 0.318294)">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="0s"
              calcMode="spline"
              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
              values="0;1;0"
              keyTimes="0;0.5;1"
              dur="1s"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </svg>
    </StyledPulseLoader>
  );
};

export default PulseLoader;
