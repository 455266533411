import styled, { css } from "styled-components/macro";

export enum ButtonColorEnum {
  PRIMARY = "primary", // mainly used in forms
  SECONDARY = "secondary", // mainly used in forms
  NEGATIVE = "negative", // delete button
  ACTION = "action", // top of tables, etc, etc
  LABEL = "label",
  PUBLIC = "public", // public views, with background image
}

const colorMap = (props: any) => {
  const { isLoading, color } = props;
  const { colors, mixins } = props.theme;
  const colorScheme = color ? color : ButtonColorEnum.PRIMARY;

  switch (colorScheme) {
    case ButtonColorEnum.PRIMARY:
      return css`
        background-color: ${colors.greenAlternative};
        border: 1px solid ${colors.greenAlternative};
        color: ${colors.white};

        &:hover:not([disabled]) {
          opacity: 0.7;
        }

        &[disabled] {
          background-color: ${isLoading || colors.grayDisabled};
          border-color: ${isLoading || colors.grayDisabled};
        }
      `;

    case ButtonColorEnum.NEGATIVE:
      return css`
        background-color: ${colors.redNegative};
        border: 1px solid ${colors.redNegative};
        color: ${colors.white};

        &:hover:not([disabled]) {
          opacity: 0.7;
        }

        &[disabled] {
          background-color: ${isLoading || colors.grayDisabled};
          border-color: ${isLoading || colors.grayDisabled};
        }
      `;

    case ButtonColorEnum.SECONDARY:
      return css`
        background-color: ${colors.white};
        border: 1px solid ${colors.green};
        color: ${colors.green};

        &:hover:not([disabled]) {
          background-color: ${colors.greenLight};
        }

        &[disabled] {
          border-color: ${colors.grayDisabled};
          color: ${colors.grayDisabled};
        }
      `;

    case ButtonColorEnum.ACTION:
      return css`
        font-size: 11px;

        background-color: ${colors.red};
        border: 1px solid ${colors.red};
        color: ${colors.white};

        height: 40px;

        &:hover:not([disabled]) {
          opacity: 0.7;
        }

        &[disabled] {
          background-color: ${colors.grayDisabled};
          border-color: ${colors.grayDisabled};
        }
      `;

    case ButtonColorEnum.LABEL:
      return css`
        ${mixins.fontExo()};

        background-color: ${colors.greenLight};
        color: ${colors.fontColor};

        border: 1px solid transparent;
        margin: 0 5px 5px 0;
        font-size: 10px;
        height: 40px;
        padding: 10px;
        text-transform: none;
        width: auto;

        &:hover:not([disabled]) {
          background-color: ${colors.redLight};
        }

          background-color: ${colors.grayDisabled};
          border-color: ${colors.grayDisabled};
        }
      `;

    case ButtonColorEnum.PUBLIC:
      return css`
        background-color: ${colors.green};
        border: 1px solid ${colors.green};
        color: ${colors.white};

        &:hover:not([disabled]) {
          opacity: 0.7;
        }

        &[disabled] {
          background-color: ${isLoading || colors.grayDisabled};
          border-color: ${isLoading || colors.grayDisabled};
        }
      `;
    default:
      break;
  }
};

interface MyProps {
  isLoading?: boolean;
  inline?: boolean;
}

export const StyledButton = styled.button<MyProps>`
  ${props => props.theme.mixins.fontUpperExo()};
  ${props => props.theme.mixins.transition()};

  font-weight: 600;
  font-size: 13px;

  border-radius: 0px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => colorMap(props)};

  &:focus {
    box-shadow: none;
    outline: 0px;
  }

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}

  ${(props: any) =>
    props.inline &&
    css`
      display: inline-flex;
      margin-right: 11px;
    `}
`;

export default StyledButton;
