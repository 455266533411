import styled from "styled-components/macro";

export const LanguageSelectorContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-top: 8px;
  margin-left: 20px;
`;

interface StyledLabelInterface {
  selected: boolean;
}

export const StyledLabel = styled.label<StyledLabelInterface>`
  display: inline-block;
  margin-left: 3px;
  width: 30px;

  &:first-child {
    margin-left: 0px;
  }

  height: 30px;
  cursor: pointer;
  border-radius: 50%;
  color: ${props => props.theme.colors.white};
  font: ${props => props.theme.mixins.fontExo()};
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;

  background-color: ${props => (props.selected ? props.theme.colors.greenAlternative : "transparent")};

  opacity: ${props => (props.selected ? 1 : 0.8)};
  &:hover {
    opacity: 1;
  }
`;

export const StyledText = styled.div`
  padding-top: 7px;
`;

export const StyledRadio = styled.input`
  display: none;
`;
