import styled from "styled-components/macro";
import Button from "components/button";
import { StyledList } from "components/list.styles";

export const StyledFileInput = styled.input`
  display: none;
`;

export const StyledAttachmentContainer = styled.div`
  display: inline-block;

  & > ${StyledList} {
    margin-bottom: 3px;
  }
`;

interface StyledButtonProps {
  htmlFor: any;
}

export const StyledButton = styled(Button)<StyledButtonProps>`
  ${props => props.theme.mixins.fontUpperExo()};
  ${props => props.theme.mixins.transition()};

  border: 1px solid ${props => props.theme.colors.red};
  border-radius: 0px;
  padding: 6px 20px 20px 20px;
  margin-bottom: 0px !important;
  margin-top: 0px;
  margin-right: 10px;

  cursor: pointer;
  position: relative;
  height: 30px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  width: auto !important;
  vertical-align: top;

  background-color: ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};

  &:hover {
    opacity: 0.7;
  }
`;

export const StyledWrapper = styled.div``;
