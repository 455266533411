import { AppActionEnum } from "redux/reducers/app";
import { store } from "../store";

export const redirect = (path?: string) => store.dispatch({ type: AppActionEnum.REDIRECT, path });

export const online = () => {
  if (store.getState().app.offline) {
    store.dispatch({ type: AppActionEnum.APP_ONLINE });
  }
};

export const offline = () => {
  if (!store.getState().app.offline) {
    store.dispatch({ type: AppActionEnum.APP_OFFLINE });
  }
};

export const setNavigationReference = (navigationReference: string) =>
  store.dispatch({ type: AppActionEnum.APP_SET_NAVIGATION_REFERENCE, navigationReference });
