import React, { FunctionComponent, Fragment } from "react";

import FormikField, { setValue } from "../field";

// json editing
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import { validate } from "utils/json";
import { FieldTypeEnum } from "redux/models/field-type";
import { FieldModel } from "../utils";

export interface JsonOptions {
  schema?: any;
}

const JsonField: FunctionComponent<FieldModel> = ({
  name,
  label,
  guide,
  required,
  onChange = () => {},
  jsonOptions = {},
}) => {
  return (
    <FormikField name={name} type={FieldTypeEnum.JSON} label={label} guide={guide} required={required}>
      {({ field, form }) => {
        const { schema } = jsonOptions;

        const fnOnChange = (json: any) => {
          setValue(form, field.name, json);
          validate(json, schema);
          onChange(json);
        };

        return (
          <Fragment>
            <Editor value={field.value} onChange={fnOnChange} />
          </Fragment>
        );
      }}
    </FormikField>
  );
};

export default JsonField;
