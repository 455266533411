import React, { FunctionComponent } from "react";

// child components
import PublicForm from "../elements/form";
import { StageTypeEnum } from "./view";
import { requestCode } from "services/link";

// redux
import { TwoFactorDeliveryTypeEnum } from "redux/models/user";
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { useSelector } from "react-redux";

// services
import Button, { ButtonColorEnum } from "components/button";
import PublicGuide from "../elements/guide";
import { translate } from "utils/i18n";

interface PreCodeFormInterface {
  uuid: string;
  hash: string;
  onStageChange: (stage: StageTypeEnum, method?: TwoFactorDeliveryTypeEnum) => void;
}

const PreCodeForm: FunctionComponent<PreCodeFormInterface> = ({ uuid, hash, onStageChange }) => {
  useSelector(languageSelector, languageEquality);

  const onSubmit = async () => {
    const result = await requestCode(uuid, "not-valid-email-as-not-needed", "email", hash);
    onStageChange(StageTypeEnum.CODE, result.verificationCode.method);
  };

  return (
    <PublicForm>
      <PublicGuide>{translate("public.2fa.guides.precode")}</PublicGuide>
      <Button onClick={onSubmit} color={ButtonColorEnum.PRIMARY}>
        {translate("public.2fa.buttons.sendCode")}
      </Button>
    </PublicForm>
  );
};

export default PreCodeForm;
