import React, { FunctionComponent, MouseEventHandler } from "react";
import styled from "styled-components/macro";

interface ImageProps {
  src: string;
  alt?: string;
  title?: string;
  className?: string;
  width?: number;
  height?: number;
  onClick?: MouseEventHandler;
}

export const StyledImage = styled.img``;

const Image: FunctionComponent<ImageProps> = ({ src, alt, title, className, width, height, onClick }) => (
  <StyledImage
    className={className}
    src={src}
    alt={alt}
    title={title}
    width={width}
    height={height}
    onClick={onClick}
  />
);

export default Image;
