import styled, { css } from "styled-components/macro";

interface MyProps {
  error: any;
}

export const StyledInput = styled.input<MyProps>`
  position: relative;

  ::placeholder {
    color: ${props => props.theme.colors.grayPlaceholder};
    font-style: italic;
    text-transform: inherit;
  }

  border: 1px solid ${props => props.theme.colors.grayBorder};

  &:focus {
    border-color: ${props => props.theme.colors.green};
  }

  ${props =>
    props.error &&
    css`
      border-color: ${props.theme.colors.redNegative} !important;
    `};

  background-color: ${props => props.theme.colors.grayLight};
  border-radius: 0px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 3px;
  width: 100%;
  height: 50px;

  ${props => props.theme.mixins.transition()};
  ${props => props.theme.mixins.fontFira()};
  font-weight: 400;

  &[disabled] {
    cursor: default;
  }
`;

export default StyledInput;
