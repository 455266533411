export const get = (key: string) => {
  try {
    const value = sessionStorage.getItem(key);
    return value || undefined;
  } catch (error) {
    // this could happen if user has a strict private mode in browser that
    // disallow usage of session- and localstorages
    return undefined;
  }
};

export const set = (key: string, value: string) => {
  try {
    sessionStorage.setItem(key, value);
  } catch (error) {
    // pass
  }
};

export const remove = (key: string) => {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    // pass
  }
};
