import React, { FunctionComponent, Fragment, useRef, useEffect } from "react";

import { StyledCodeContainer, StyledCode, StyledWhitey } from "./code.styles";
import FormikError, { parseError } from "../error";
import FormikField, { setValue } from "../field";
import { FieldTypeEnum } from "redux/models/field-type";
import { SubmitOnEnterOpts, MaxLengthOpts, FieldModel } from "../utils";

export interface CodeOptions extends SubmitOnEnterOpts, MaxLengthOpts {}

const FormikCode: FunctionComponent<FieldModel> = ({
  name,
  label,
  guide,
  required,
  codeOptions = {},
  onChange = () => {},
  onKeyDown = () => {},
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const { maxLength = 4, submitOnEnter = false } = codeOptions;

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    <FormikField name={name} type={FieldTypeEnum.CODE} label={label} guide={guide} required={required}>
      {({ field, form }) => {
        const error = parseError(form, field);

        const fnOnChange = (event: any) => {
          const value = event.target.value;
          setValue(form, field.name, value);
          onChange(value);
        };

        // wrap onkeydown
        const fnOnKeyDown = (event: any) => {
          if (submitOnEnter && event.key === "Enter") {
            form.submitForm();
          }
          onKeyDown(event, form);
        };

        const fnOnPaste = () => {
          if (ref && ref.current) {
            ref.current.scrollLeft = 0;
          }
        };

        return (
          <Fragment>
            <StyledCodeContainer length={maxLength}>
              <StyledCode
                id={field.name}
                name={field.name}
                ref={ref}
                autoComplete="off"
                spellCheck={false}
                type="text"
                value={field.value}
                length={maxLength}
                maxLength={maxLength}
                onChange={fnOnChange}
                onKeyDown={fnOnKeyDown}
                onPaste={fnOnPaste}
                placeholder=""
              />
              <StyledWhitey />
            </StyledCodeContainer>

            <FormikError error={error} />
          </Fragment>
        );
      }}
    </FormikField>
  );
};

export default FormikCode;
