import moment from "moment";

import { TableColumnProps, TableSortProps } from "components/table/table";

// do the magic, do not accept nulls or undefined - they must be converted into empty strings
export const plainSort = (
  valueA: string | number | boolean | moment.Moment = "",
  valueB: string | number | boolean | moment.Moment = "",
  desc: boolean
) => {
  const prepareValue = (value: string | number | boolean | moment.Moment) =>
    typeof value === "string" ? value.toLowerCase() : value;

  // sorting itself
  const valA = prepareValue(valueA);
  const valB = prepareValue(valueB);
  if (valA < valB) {
    return desc ? 1 : -1;
  } else if (valA === valB) {
    return 0;
  }
  return desc ? -1 : 1;
};

export const plainSearch = (value: string = "", keyword: string = "") =>
  (value || "").toLowerCase().includes(keyword.toLowerCase());

const compileString = (obj: any, components: string[]): string => {
  const values: string[] = [];
  components.forEach(c => {
    const value = obj[c];
    values.push(value);
  });

  return values.join(" ").toLowerCase();
};

// "a" and "b" are objects to compare, components defined object keys that create the compare string
export const itemSort = (a: any = {}, b: any = {}, components: string[] = [], desc: boolean = false): number => {
  // compose values from components
  const valueA: string = compileString(a, components);
  const valueB: string = compileString(b, components);
  return plainSort(valueA, valueB, desc);
};

export const itemSortNumeric = (a: any = {}, b: any = {}, component: string, desc: boolean = false): number => {
  // compose values from components
  const valueA: number = a[component];
  const valueB: number = b[component];
  return plainSort(valueA, valueB, desc);
};

export const itemSortDate = (a: any = {}, b: any = {}, component: string, desc: boolean = false): number => {
  // compose values from components
  const valueA: number = moment(a[component]).valueOf();
  const valueB: number = moment(b[component]).valueOf();
  return plainSort(valueA, valueB, desc);
};

export const sortTable = (data: any[], sort: TableSortProps, columns: TableColumnProps[]) => {
  // find a column to sort by
  const { desc } = sort;
  const column = columns.find(col => col.key === sort.value);

  // if the column does not exist, return unchanged data
  if (!column) {
    return [...data];
  }

  // sort
  return data.sort((a: any, b: any) => {
    // if the column has sort method, use that one
    if (column.sort) {
      return column.sort(a, b, desc);
    }

    // otherwise, default to plain sort
    return itemSort(a, b, [sort.value], desc);
  });
};

export const searchTable = (data: any[], keyword: string, columns: TableColumnProps[]) => {
  // skip, if no keyword
  if (!keyword) {
    return [...data];
  }

  // split keyword into chunks
  const chunks: string[] = keyword.split(" ");

  // check every row
  return data.filter((item: any) => {
    let matches = 0;

    // check every keyword
    for (const chunk of chunks) {
      let doesChunkMatch = false;

      // iterate columns
      for (const column of columns) {
        const { search } = column;

        // if this keyword does not have match, and search function exists, try to search
        if (!doesChunkMatch && search && search(item, chunk)) {
          // this keyword matches, add matches count and continue to next
          doesChunkMatch = true;
        }
      }

      // add total count, if chunk has a match
      if (doesChunkMatch) {
        matches += 1;
      }
    }

    // if we have same number of matches, accept this item
    return matches === chunks.length;
  });
};
