import React, { FunctionComponent } from "react";
import { translate } from "utils/i18n";

// components
import PublicForm from "../elements/form";
import PublicNav from "../elements/nav";
import Form, { FormButtonProps } from "components/formik/form";
import { ButtonColorEnum } from "components/button";
import { goto } from "modules/app/navigation";
import { StageTypeEnum } from "./view";
import PublicGuide from "../elements/guide";

// services
import { create } from "services/forgotten-passwords";

// redux
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { useSelector } from "react-redux";
import { FieldTypeEnum } from "redux/models/field-type";
import FormikLayoutColumns from "components/formik/layout/columns";
import { FieldModel } from "components/formik/utils";

interface EmailFormProps {
  setUsername: (username: string) => void;
  onStageChange: (stage: StageTypeEnum) => void;
}

interface FormValues {
  username: string;
}

const EmailForm: FunctionComponent<EmailFormProps> = ({ setUsername, onStageChange }) => {
  useSelector(languageSelector, languageEquality);

  const getSchemaForEmail = () => {
    const schema: FieldModel[] = [
      {
        name: "username",
        type: FieldTypeEnum.USERNAME,
        placeholder: translate("public.forgottenPassword.placeholders.email"),
        label: translate("public.forgottenPassword.labels.email"),
        usernameOptions: { submitOnEnter: false },
      },
    ];
    return schema;
  };

  const getInitialValuesForEmail = () => {
    const initialValues: FormValues = { username: "" };
    return initialValues;
  };

  const getButtonsForEmail = () => {
    const buttons: FormButtonProps[] = [
      {
        children: translate("public.forgottenPassword.buttons.changePassword"),
        color: ButtonColorEnum.PUBLIC,
      },
    ];
    return buttons;
  };

  // when the login form is "sent"
  const onSubmitEmail = async (values: FormValues) => {
    // validate fields
    try {
      await create(values.username);
    } catch (error) {
      onStageChange(StageTypeEnum.EMAIL_ERROR);
    }

    // go to confirmation page
    onStageChange(StageTypeEnum.EMAIL_DONE);
    setUsername(values.username);
  };

  const onRender = () => <FormikLayoutColumns formSchema={getSchemaForEmail()} />;

  return (
    <PublicForm>
      <PublicGuide>{translate("public.forgottenPassword.guide")}</PublicGuide>

      <Form
        initialValues={getInitialValuesForEmail()}
        buttons={getButtonsForEmail()}
        onSubmit={onSubmitEmail}
        onRender={onRender}
      />

      <PublicNav onClick={() => goto("/login")}>{translate("public.forgottenPassword.buttons.return")}</PublicNav>
    </PublicForm>
  );
};

export default EmailForm;
