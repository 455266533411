import React, { FunctionComponent } from "react";
import { translate } from "utils/i18n";

// redux
import { selector as appSelector, equalityFn as appEquality } from "redux/hooks/app";
import { useSelector } from "react-redux";
import Splash from "components/splash";

interface OfflineErrorInterface {}

const OfflineError: FunctionComponent<OfflineErrorInterface> = () => {
  const appState = useSelector(appSelector, appEquality);
  const { offline } = appState;
  return offline ? <Splash icon="signal" title={translate("offline.title")} text={translate("offline.text")} /> : null;
};

export default OfflineError;
