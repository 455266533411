import styled from "styled-components/macro";

export const StyledNav = styled.a`
  ${props => props.theme.mixins.fontUpperExo()};
  font-size: 13px;

  color: ${props => props.theme.colors.white};
  border-bottom: 1px solid transparent;
  padding-bottom: 3px;
  margin-top: 15px;
  display: inline-block;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: ${props => props.theme.colors.white};
    border-bottom: 1px solid ${props => props.theme.colors.white};
  }
`;

export const NavContainer = styled.div`
  ${props => props.theme.mixins.fontFira()};
  text-transform: initial;
  font-weight: 400;
  color: ${props => props.theme.colors.fontColor};
  text-align: center;
  margin-bottom: 30px;
`;
