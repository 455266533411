export const get = (key: string) => {
  try {
    const value = localStorage.getItem(key);
    return value || "";
  } catch (error) {
    // this could happen if user has a strict private mode in browser that
    // disallow usage of session- and localstorages
    return undefined;
  }
};

export const set = (key: string, value: any) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    // pass
  }
};

export const remove = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    // pass
  }
};

export const listen = (to: string, callback: (oldValue: any, newValue: any, key: string) => void) => {
  const fn = (event: any) => {
    const { key, oldValue, newValue } = event;
    if (key === to) {
      callback(oldValue, newValue, key);
    }
  };
  window.addEventListener("storage", fn);
};
