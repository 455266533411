import React, { Fragment, FunctionComponent, useState } from "react";
import moment from "moment";

// child component
import DateTimePicker from "./datetime/picker";
import FormikLock from "components/formik/lock";
import FormikError, { parseError } from "components/formik/error";
import FormikField, { setValue } from "../field";
import { StyledDateInput, FormikClearExt, StyledCalendar } from "./datetime.styles";

// utils
import { translate } from "utils/i18n";
import { MIN_YEAR, MAX_YEAR } from "./datetime/year-selector";
import { toFormat, toMoment, getFormats } from "utils/date";
import { ColorEnum } from "theme/colors";
import { FieldTypeEnum } from "redux/models/field-type";
import { FieldModel } from "../utils";

// parses a string to a moment object
export const parse = (value: string | undefined, time: boolean) => {
  if (!value) {
    return moment.invalid();
  }

  // parse using defined format hints
  const parsed = moment(value, getFormats(), false);

  // if no time, set to noon
  if (!time) {
    parsed.hour(12);
    parsed.minute(0);
    parsed.second(0);
  }

  // return
  return parsed;
};

export interface DateTimeOptions {
  // include time picker
  time?: boolean;

  // for ranging date picker
  min?: moment.Moment;
  max?: moment.Moment;
}

const DateTimeField: FunctionComponent<FieldModel> = ({
  name,
  label,
  guide,
  disabled = false,
  onChange = () => {},
  required,
  datetimeOptions = {},
}) => {
  const {
    time = true,
    min = moment([MIN_YEAR, 0, 1, 0, 0, 0, 0]),
    max = moment([MAX_YEAR, 11, 31, 23, 59, 59, 999]),
  } = datetimeOptions;

  const [pickerOpen, setPickerOpen] = useState<boolean>(false);
  const [internalValue, setInternalValue] = useState<string | undefined>(undefined);
  const [editing, setEditing] = useState<boolean>(false);
  const desiredFormat = time ? translate("date.full") : translate("date.short");

  const formikDatetimeValidate = (date: string) => {
    if (!date) {
      // empty is not invalid, just missing
      return undefined;
    }

    try {
      // use strict parsing
      const obj = parse(date, time);
      if (!obj.isValid()) {
        throw new Error();
      }

      // all clear, no error
      return undefined;
    } catch (err) {
      return time ? translate("formik.datetime.invalid") : translate("formik.date.invalid");
    }
  };

  return (
    <FormikField
      name={name}
      type={time ? FieldTypeEnum.DATETIME : FieldTypeEnum.DATE}
      label={label}
      guide={guide}
      required={required}
      validate={formikDatetimeValidate}
    >
      {({ field, form }: any) => {
        const fnOnFocus = () => {
          // enable editing mode on focus
          setEditing(true);

          const value = parse(field.value, time);
          if (value.isValid()) {
            // since value is valid, use formatted version (most likely already the same format)
            setInternalValue(toFormat(value, desiredFormat));
          } else {
            // not a valid, use plain string value
            setInternalValue(field.value || "");
          }
        };

        const fnOnBlur = () => {
          // disable editing mode
          setEditing(false);

          // if a valid value on the moment of blur, save as a proper timestamp
          const value = parse(field.value, time);
          if (value.isValid()) {
            fnSetValue(value);
          }
        };

        // set value, in the correct format and stuff
        const fnSetValue = (value: moment.Moment | null = null) => {
          if (value) {
            // if less than min or more than max
            if (min && value.isBefore(min)) {
              value = moment(min);
            } else if (max && value.isAfter(max)) {
              value = moment(max);
            }

            // for valid moments only
            const v = value.format();
            setValue(form, field.name, v);
            onChange(v);
          } else {
            setValue(form, field.name, null);
            onChange(null);
          }
        };

        // user changes value from the picker
        const onChangePicker = (value: moment.Moment) => {
          // close the picker and delegate higher
          setPickerOpen(false);
          fnSetValue(value);
        };

        // user changes the input value manually
        const fnOnChange = (event: any) => {
          let value: string = event.target.value;
          if (value.includes("\t")) {
            // if pasted tabs, replace with spaces
            value = value.replace(/\t/g, " ");
          }

          // set internal status
          setInternalValue(value);

          // change field state to validate
          setValue(form, field.name, value);
          onChange(value);
        };

        const getValueForInput = () => {
          if (editing) {
            return internalValue;
          }

          if (!field.value) {
            return "";
          }

          const value = parse(field.value, time);
          return value.isValid() ? toFormat(value, desiredFormat) : field.value;
        };

        const getValueForPicker = () => {
          if (!field.value) {
            return moment();
          }

          const value = toMoment(field.value);
          return value.isValid() ? value : moment();
        };

        // parse value to show
        const error = parseError(form, field);

        return (
          <Fragment>
            <StyledDateInput
              type="text"
              id={field.name}
              name={field.name}
              error={error}
              maxLength={255}
              placeholder={time ? translate("date.full") : translate("date.short")}
              value={getValueForInput()}
              disabled={disabled}
              autoComplete="off"
              onFocus={fnOnFocus}
              onBlur={fnOnBlur}
              onChange={fnOnChange}
            />

            {/* calendar button */}
            {!disabled && (
              <StyledCalendar
                icon="calendar-alt"
                onClick={() => setPickerOpen(true)}
                color={ColorEnum.grayDisabled}
                hoverColor={ColorEnum.green}
              />
            )}

            <FormikClearExt
              disabled={disabled}
              value={field.value}
              onClear={() => {
                fnSetValue(null);
              }}
            />

            <FormikLock disabled={disabled} />
            <FormikError error={error} />

            {/* actual ui widget to choose the date */}
            {pickerOpen && (
              <DateTimePicker
                value={getValueForPicker()}
                time={time}
                min={min}
                max={max}
                onCancel={() => setPickerOpen(false)}
                onChange={onChangePicker}
              />
            )}
          </Fragment>
        );
      }}
    </FormikField>
  );
};

export default DateTimeField;
