import { ConfirmActionEnum } from "redux/reducers/confirm";
import { ConfirmModel } from "redux/models/confirm";
import { store } from "redux/store";

export const show = (confirm: ConfirmModel) =>
  store.dispatch({
    type: ConfirmActionEnum.SHOW_CONFIRM,
    confirm,
  });

export const hide = () =>
  store.dispatch({
    type: ConfirmActionEnum.HIDE_CONFIRM,
  });
