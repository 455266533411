import React, { FunctionComponent, Fragment } from "react";
import { translate } from "utils/i18n";

import { StyledInput } from "./text.styles";
import FormikLock from "../lock";
import FormikError, { parseError } from "../error";

import FormikField, { setValue } from "../field";
import { FieldTypeEnum } from "redux/models/field-type";
import { SubmitOnEnterOpts, FieldModel } from "../utils";

const regexp = /^[+]{1}[0-9\s-()]{5,}$/i;

export const formikPhoneValidate = (phone: string) => {
  if (!phone) {
    // empty is not invalid, just missing
    return undefined;
  }

  try {
    // split and validate further
    if (!regexp.test(phone)) {
      throw new Error();
    }

    // all clear, no error
    return undefined;
  } catch (err) {
    return translate("formik.phone.invalid");
  }
};

export interface PhoneOptions extends SubmitOnEnterOpts {}

const PhoneField: FunctionComponent<FieldModel> = ({
  name,
  label,
  guide,
  required,
  disabled = false,
  phoneOptions = {},
  onChange = () => {},
  onKeyDown = () => {},
}) => {
  const { submitOnEnter = false } = phoneOptions;

  return (
    <FormikField
      name={name}
      type={FieldTypeEnum.PHONE}
      label={label}
      guide={guide}
      required={required}
      validate={formikPhoneValidate}
    >
      {({ field, form }) => {
        const fnOnChange = (event: any) => {
          const { target } = event;
          const { value = "" } = target;

          // save value
          setValue(form, field.name, value);
          onChange(value);
        };

        // wrap onkeydown
        const fnOnKeyDown = (event: any) => {
          if (submitOnEnter && event.key === "Enter") {
            form.submitForm();
          }
          onKeyDown(event, form);
        };

        const error = parseError(form, field);

        const placeholder = translate("formik.phone.placeholder");

        return (
          <Fragment>
            {/* Normal input element, with some extra juicy props */}
            <StyledInput
              error={error}
              autoComplete="off"
              maxLength={255}
              type="text"
              id={field.name}
              name={field.name}
              value={field.value}
              disabled={disabled}
              placeholder={placeholder}
              onChange={fnOnChange}
              onKeyDown={fnOnKeyDown}
            />

            <FormikLock disabled={disabled} />

            <FormikError error={error} />
          </Fragment>
        );
      }}
    </FormikField>
  );
};

export default PhoneField;
