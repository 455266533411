import React, { FunctionComponent, Fragment } from "react";
import { translate } from "utils/i18n";

import { StyledInput } from "./text.styles";
import FormikLock from "../lock";
import FormikError, { parseError } from "../error";

import FormikField, { setValue } from "../field";
import { FieldTypeEnum } from "redux/models/field-type";
import { SubmitOnEnterOpts, FieldModel } from "../utils";

const REGEXP = /^(?!.*[.-]{2,})(?!.*[.]{2,})[a-z0-9_-]+([.+-_][a-z0-9]{1,}){0,}@([a-z0-9.-]+)([.]{1}[a-z]{2,})+$/;

export const formikUsernameValidate = (username: string) => {
  if (!username) {
    // empty is not invalid, just missing
    return undefined;
  }

  try {
    if (!REGEXP.test(username)) {
      throw new Error();
    }

    // all clear, no error
    return undefined;
  } catch (err) {
    return translate("formik.username.invalid");
  }
};

export interface UsernameOptions extends SubmitOnEnterOpts {}

const UsernameField: FunctionComponent<FieldModel> = ({
  name,
  label,
  guide,
  required,
  placeholder,
  disabled = false,
  usernameOptions = {},
  onChange = () => {},
  onKeyDown = () => {},
}) => {
  const { submitOnEnter = false } = usernameOptions;

  return (
    <FormikField
      name={name}
      type={FieldTypeEnum.USERNAME}
      label={label}
      guide={guide}
      required={required}
      validate={formikUsernameValidate}
    >
      {({ field, form }) => {
        const fnOnChange = (event: any) => {
          const { target } = event;

          // get value and lowercase it
          const value: string = target.value || "";
          const lowercasedValue = value.toLowerCase();

          // save value
          setValue(form, field.name, lowercasedValue);
          onChange(lowercasedValue);
        };

        // wrap onkeydown
        const fnOnKeyDown = (event: any) => {
          if (submitOnEnter && event.key === "Enter") {
            form.submitForm();
          }
          onKeyDown(event, form);
        };

        const error = parseError(form, field);

        return (
          <Fragment>
            {/* Normal input element, with some extra juicy props */}
            <StyledInput
              error={error}
              autoComplete="off"
              maxLength={255}
              type="text"
              id={field.name}
              name={field.name}
              value={field.value}
              disabled={disabled}
              placeholder={placeholder}
              onChange={fnOnChange}
              onKeyDown={fnOnKeyDown}
            />

            <FormikLock disabled={disabled} />

            <FormikError error={error} />
          </Fragment>
        );
      }}
    </FormikField>
  );
};

export default UsernameField;
