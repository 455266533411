import styled from "styled-components/macro";

export const OFFSET = 1570;
export const DURATION = 1.2;

export const TickerLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
`;

export const StyledTickerLoader = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledTickerLoaderCircle = styled.div`
  width: 100px;
  height: 100px;
`;

export const StyledTickerLoaderLetters = styled.div`
  margin-top: 5px;
  position: absolute;
  polygon,
  rect {
    fill: ${props => props.theme.colors.green};
  }
`;
