import { OrganizationModel } from "redux/models/organization";

export enum OrganizationsActionEnum {
  ORGANIZATIONS_UPDATE = "ORGANIZATIONS_UPDATE",
  ORGANIZATIONS_CLEAR = "ORGANIZATIONS_CLEAR",
}

// Initial state
const initialState: OrganizationModel[] | null = null;

// Reducer
const OrganizationStateReducer = (state: any = initialState, action: any = {}) => {
  const { organizations } = action;
  switch (action.type) {
    case OrganizationsActionEnum.ORGANIZATIONS_UPDATE:
      return [...organizations] as OrganizationModel[];

    case OrganizationsActionEnum.ORGANIZATIONS_CLEAR:
      return null;

    default:
      return state;
  }
};

export default OrganizationStateReducer;
