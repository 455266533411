import { UserModel, UserTypeEnum } from "redux/models/user";

export enum UserActionEnum {
  USER_LOGIN = "USER_LOGIN",
  USER_UPDATE = "USER_UPDATE",
  USER_LOGOUT = "USER_LOGOUT",
}

// Initial state
const initialState: UserModel = {
  authenticated: false,
  userType: UserTypeEnum.USER,
};

// Reducer
const UserStateReducer = (state = initialState, action: any = {}) => {
  const { payload } = action;

  switch (action.type) {
    case UserActionEnum.USER_LOGIN:
      return {
        ...state,
        authenticated: true,
        authType: action.authType || state.authType,
        redirectPath: action.redirectPath || undefined,
      } as UserModel;

    case UserActionEnum.USER_UPDATE:
      // user info is updated from the server
      return {
        ...state,
        authenticated: true,
        uuid: payload.user.uuid,
        firstName: payload.user.firstName,
        lastName: payload.user.lastName,
        userType: payload.user.userType,
        username: payload.user.username,
        phoneNumber: payload.user.phoneNumber,
        twoFactorDeliveryType: payload.user.twoFactorDeliveryType,
      } as UserModel;

    case UserActionEnum.USER_LOGOUT:
      // user info is updated from the server
      return {
        ...state, // use spread, so we can keep some fields, like "redirect" (?)
        authenticated: false,
        uuid: undefined,
        firstName: undefined,
        lastName: undefined,
        userType: undefined,
        authType: undefined,
        username: undefined,
        phoneNumber: undefined,
        twoFactorDeliveryType: undefined,
      } as UserModel;

    default:
      return state;
  }
};

export default UserStateReducer;
