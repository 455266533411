import React, { FunctionComponent } from "react";
import { translate } from "utils/i18n";
import moment from "moment";

// redux
import { StyledCopyright, StyledIcon } from "./copyright.styles";
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { useSelector } from "react-redux";
import { ColorEnum } from "theme/colors";

interface PublicCopyrightInterface {}

const PublicCopyright: FunctionComponent<PublicCopyrightInterface> = () => {
  useSelector(languageSelector, languageEquality);

  const year = moment().format("YYYY");

  return (
    <StyledCopyright href="https://www.ticker.software">
      <StyledIcon icon="copyright" size={13} color={ColorEnum.white} /> {translate("public.splash.copyright")}
      &nbsp;
      {year}
    </StyledCopyright>
  );
};

export default PublicCopyright;
