import React, { FunctionComponent, useState } from "react";

// components
import InputComponent from "./component-input";
import { StyledHourSelector } from "./hour-selector.styles";

const MIN_HOUR: number = 0;
const MAX_HOUR: number = 23;

interface HourSelectorProps {
  onChange: (hour: number) => void;
  hour: number;
  className?: string;
}

const validateHour = (hour: number) => {
  return hour >= MIN_HOUR && hour <= MAX_HOUR;
};

const HourSelector: FunctionComponent<HourSelectorProps> = ({ onChange, hour, className }) => {
  const [rawInputHour, setRawInputHour] = useState<string | null>(null);

  const onBlur = () => {
    const hourAsNumber = parseInt(rawInputHour || "", 10);
    setRawInputHour(null);
    if (validateHour(hourAsNumber)) {
      onChange(hourAsNumber);
    }
  };

  // the value in the input has changed
  const fnOnChange = (value: string, validate: boolean) => {
    if (value === "" || !validate) {
      setRawInputHour(value);
    } else {
      // if a valid number, validate in range
      const hourAsNumber = parseInt(value, 10);
      if (!isNaN(hourAsNumber) && validateHour(hourAsNumber)) {
        setRawInputHour(null);

        // delegate
        onChange(hourAsNumber);
      }
    }
  };

  const fnOnIncrease = () => {
    // get the current value
    let rawInputHourNumeric = parseInt(rawInputHour || "", 10) || hour;
    if (!isNaN(rawInputHourNumeric)) {
      // increase
      rawInputHourNumeric = rawInputHourNumeric < MAX_HOUR ? rawInputHourNumeric + 1 : MIN_HOUR;

      // delegate
      fnOnChange(String(rawInputHourNumeric), true);
    }
  };

  const fnOnDecrease = () => {
    // get the current value
    let rawInputHourNumeric = parseInt(rawInputHour || "", 10) || hour;
    if (!isNaN(rawInputHourNumeric)) {
      // increase
      rawInputHourNumeric = rawInputHourNumeric > MIN_HOUR ? rawInputHourNumeric - 1 : MAX_HOUR;

      // delegate
      fnOnChange(String(rawInputHourNumeric), true);
    }
  };

  // parse value
  let value = String(hour);
  if (rawInputHour !== null) {
    value = rawInputHour;
  }

  return (
    <StyledHourSelector className={className}>
      <InputComponent
        width={35}
        value={value}
        onBlur={onBlur}
        onChange={fnOnChange}
        onIncrease={fnOnIncrease}
        onDecrease={fnOnDecrease}
      />
    </StyledHourSelector>
  );
};

export default HourSelector;
