import { AppModel } from "redux/models/app";

export enum AppActionEnum {
  REDIRECT = "REDIRECT",
  APP_ONLINE = "APP_ONLINE",
  APP_OFFLINE = "APP_OFFLINE",
  APP_SET_NAVIGATION_REFERENCE = "APP_SET_NAVIGATION_REFERENCE",
}

// Initial state
const initialState: AppModel = {
  offline: false,
};

// Reducer
const AppStateReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case AppActionEnum.REDIRECT:
      return { ...state, redirect: action.path } as AppModel;

    case AppActionEnum.APP_ONLINE:
      return { ...state, offline: false } as AppModel;

    case AppActionEnum.APP_OFFLINE:
      return { ...state, offline: true } as AppModel;

    case AppActionEnum.APP_SET_NAVIGATION_REFERENCE:
      return { ...state, navigationReference: action.navigationReference } as AppModel;

    default:
      return state as AppModel;
  }
};

export default AppStateReducer;
