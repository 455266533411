import React, { FunctionComponent } from "react";

import { StyledGuide } from "./guide.styles";

interface PublicGuideInterface {
  className?: string;
}

const PublicGuide: FunctionComponent<PublicGuideInterface> = ({ children, className }) => (
  <StyledGuide className={className}>{children}</StyledGuide>
);

export default PublicGuide;
