import React, { FunctionComponent } from "react";
import Modal, { ModalSizeEnum, ModalProps } from "components/layout/modal";
import Form, { FormButtonProps } from "components/formik/form";
import { FieldTypeEnum } from "redux/models/field-type";
import { translate } from "utils/i18n";
import { ButtonColorEnum } from "components/button.styles";
import { FormikHelpers, FormikProps } from "formik";
import { FieldModel } from "components/formik/utils";
import { buildFormValidationObjectSchema } from "utils/form";
import FormikLayoutColumns from "components/formik/layout/columns";

interface LinkModalProps extends ModalProps {
  url: string;
  onSubmit: (url: string) => void;
}

interface FormValues {
  url: string;
}

const LinkModal: FunctionComponent<LinkModalProps> = ({ url, onClose, onSubmit }) => {
  const getSchema = () => {
    const schema: FieldModel[] = [
      {
        type: FieldTypeEnum.URL,
        name: "url",
        label: translate("markdownEditor.linkModal.labels.url"),
      },
    ];
    return schema;
  };

  const getInitialValues = () => {
    const initialValues: FormValues = { url };
    return initialValues;
  };

  const getButtons = () => {
    const buttons: FormButtonProps[] = [
      {
        fn: async () => onClose(),
        color: ButtonColorEnum.SECONDARY,
        children: translate("markdownEditor.linkModal.buttons.cancel"),
      },
      {
        children: translate("markdownEditor.linkModal.buttons.save"),
      },
    ];
    return buttons;
  };

  const getValidationSchema = () => {
    const validationSchema = buildFormValidationObjectSchema(getSchema());
    return validationSchema;
  };

  const fnOnSubmit = async (values: FormValues, formikHelpers: FormikHelpers<any>) => {
    const { url } = values;
    onSubmit(url);
  };

  const fnOnRender = (formikProps: FormikProps<any>) => <FormikLayoutColumns formSchema={getSchema()} />;

  return (
    <Modal modalSize={ModalSizeEnum.SMALL} onClose={onClose}>
      <Form
        initialValues={getInitialValues()}
        buttons={getButtons()}
        validationSchema={getValidationSchema()}
        onSubmit={fnOnSubmit}
        onRender={fnOnRender}
      />
    </Modal>
  );
};

export default LinkModal;
