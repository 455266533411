import { TabsWrapperModel } from "redux/models/tabs";

export enum TabsActionEnum {
  SET_TABS_STATE = "SET_TABS_STATE",
  CLEAR_TABS_STATE = "CLEAR_TABS_STATE",
}

// Initial state
const initialState: TabsWrapperModel = {};

// Reducer
export const TabsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TabsActionEnum.SET_TABS_STATE:
      return { ...state, [action.name]: action.state };

    case TabsActionEnum.CLEAR_TABS_STATE:
      return { ...state, [action.name]: undefined };

    default:
      return state;
  }
};

export default TabsReducer;
