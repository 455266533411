import { css } from "styled-components/macro";
import hex2rgba from "hex2rgba";
import { ColorEnum } from "./colors";

// export hex to rgba, note opacity is from 0 to 1
export const hexToRgba = (hex: string, opacity: number = 1) => hex2rgba(hex, opacity);

export const fontFira = () => css`
  font-size: 14px;
  font-family: "Fira Sans", sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const fontFiraMono = () => css`
  font-size: 14px;
  font-family: "Fira Mono", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const fontExo = () => css`
  font-family: "Exo 2", sans-serif;
  font-size: 11px;
  letter-spacing: 0.02em;
  font-weight: 400;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const fontUpperExo = () => css`
  font-family: "Exo 2", sans-serif;
  font-size: 11px;
  letter-spacing: 0.02em;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const noSelect = () => css`
  -webkit-touch-callout: none; /* iOS Safari */
  user-select: none;
`;

export const boxShadow = () => css`
  box-shadow: 0px 0px 5px ${hexToRgba(ColorEnum.black, 0.1)};
`;

export const transition = (time: number = 0.2, attribute: string = "all") => css`
  transition: ${attribute} ${time}s ease-in-out;
`;

export default {
  fontFira,
  fontExo,
  fontUpperExo,
  noSelect,
  boxShadow,
  transition,
  hexToRgba,
};
