import React, { FunctionComponent, useState } from "react";

// modules
import PrivacyPolicyModal from "./privacy-policy-modal";
import { StyledFooter, StyledLink } from "./footer.styles";

// services
import { get as getPrivacyPolicy } from "services/privacy-policy";

// utils+misc
import { translate } from "utils/i18n";
import { selector, equalityFn } from "redux/hooks/language";
import { useSelector } from "react-redux";

interface FooterInterface {}

const Footer: FunctionComponent<FooterInterface> = () => {
  const [showPolicyModal, setShowPolicyModal] = useState<boolean>(false);
  const [policy, setPolicy] = useState<any | undefined>(undefined);
  useSelector(selector, equalityFn);

  const closeModal = async () => setShowPolicyModal(false);

  const openModal = async () => {
    let policy;
    try {
      policy = await getPrivacyPolicy();
    } catch (error) {
      // pass, there is nothing what we can do
    }

    // set to state
    setShowPolicyModal(true);
    setPolicy(policy);
  };

  return (
    <StyledFooter>
      {showPolicyModal && policy && <PrivacyPolicyModal policy={policy} onClose={closeModal} />}
      &nbsp;
      <StyledLink onClick={openModal}>{translate("privacyPolicy")}</StyledLink>
    </StyledFooter>
  );
};

export default Footer;
