import React, { FunctionComponent } from "react";
import { translate } from "utils/i18n";

// components
import PublicForm from "../elements/form";
import PublicPlate from "../elements/plate";
import { PlateTypeEnum } from "../elements/plate.styles";
import { StageTypeEnum } from "./view";

// redux
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { useSelector } from "react-redux";
import { requestCode } from "services/link";

interface CodeErrorInterface {
  uuid: string;
  hash: string;
  onStageChange: (stage: StageTypeEnum) => void;
}

const CodeError: FunctionComponent<CodeErrorInterface> = ({ uuid, hash, onStageChange }) => {
  useSelector(languageSelector, languageEquality);

  const onResend = async () => {
    await requestCode(uuid, "not-valid-email-as-not-needed", "email", hash);
    onStageChange(StageTypeEnum.CODE);
  };

  return (
    <PublicForm>
      <PublicPlate
        type={PlateTypeEnum.ERROR}
        title={translate("public.2fa.error.title")}
        text={translate("public.2fa.error.desc_code")}
        button={{
          text: translate("public.2fa.buttons.retry"),
          fn: onResend,
        }}
      />
    </PublicForm>
  );
};

export default CodeError;
