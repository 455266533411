import React, { Fragment, FunctionComponent, useState, useEffect } from "react";
import moment from "moment";
import { translate } from "utils/i18n";

// components
import { ButtonColorEnum } from "../../../button";
import { StyledModal, StyledLayer } from "../../../layout/modal.styles";
import MonthGrid from "./month-grid";
import ViewSelector from "./view-selector";
import TimeSelector from "./time-selector";
import {
  StyledBarExt,
  StyledIconExt,
  StyledFrameExt,
  StyledSeparatorExt,
  StyleButtonContainer,
  StyledButtonLeft,
  StyledButtonRight,
} from "./picker.styles";

interface PickerProps {
  value: moment.Moment;
  time: boolean;
  onChange: (value: moment.Moment) => void;
  onCancel: () => void;
  min: moment.Moment;
  max: moment.Moment;
}

const Picker: FunctionComponent<PickerProps> = ({ value, time, onChange, onCancel, min, max }) => {
  const [loaded, setLoaded] = useState(false);
  const [internalValue, setInternalValue] = useState<moment.Moment>(moment());

  useEffect(() => {
    // set view based on initial value
    setInternalValue(moment(value));
    setLoaded(true);
  }, [value]);

  const onChangeValueDate = (day: number, month: number, year: number) => {
    const newValue = moment(internalValue);
    newValue.date(day);
    newValue.month(month);
    newValue.year(year);
    setInternalValue(newValue);
  };

  const onChangeValueTime = (hour: number, minute: number, utcOffset: number) => {
    const newValue = moment(internalValue);
    newValue.hour(hour);
    newValue.minute(minute);
    newValue.utcOffset(utcOffset, true); // true means it maintains HH/MM
    setInternalValue(newValue);
  };

  const fnOnChange = (value: moment.Moment) => {
    const valueToPass = moment(value);

    // if no time, set to noon
    if (!time) {
      valueToPass.hour(12);
      valueToPass.minute(0);
      valueToPass.second(0);
    }

    // submit
    onChange(valueToPass);
  };

  const renderView = () => {
    return (
      <ViewSelector
        day={internalValue.date()}
        year={internalValue.year()}
        month={internalValue.month()}
        onChange={onChangeValueDate}
      />
    );
  };

  const renderMonthGrid = () => {
    return (
      <MonthGrid
        day={internalValue.date()}
        month={internalValue.month()}
        year={internalValue.year()}
        min={min}
        max={max}
        onChange={onChangeValueDate}
      />
    );
  };

  const renderTime = () => {
    return (
      <Fragment>
        <StyledSeparatorExt />
        <TimeSelector
          hour={internalValue.hour()}
          minute={internalValue.minute()}
          utcOffset={internalValue.utcOffset()}
          onChange={onChangeValueTime}
        />
      </Fragment>
    );
  };

  const renderButtons = () => {
    return (
      <StyleButtonContainer>
        <StyledButtonLeft color={ButtonColorEnum.SECONDARY} onClick={onCancel}>
          {translate("datepicker.button.cancel")}
        </StyledButtonLeft>
        <StyledButtonRight
          color={ButtonColorEnum.PRIMARY}
          id="datepicker-submit"
          onClick={() => fnOnChange(internalValue)}
        >
          {translate("datepicker.button.done")}
        </StyledButtonRight>
      </StyleButtonContainer>
    );
  };

  // sanity checks
  if (!loaded) {
    return null;
  }

  return (
    <StyledModal>
      <StyledLayer>
        <StyledBarExt>
          <StyledIconExt icon="times" onClick={onCancel} />
        </StyledBarExt>
        <StyledFrameExt>
          {renderView()}
          <StyledSeparatorExt />
          {renderMonthGrid()}
          {time && renderTime()}
          {renderButtons()}
        </StyledFrameExt>
      </StyledLayer>
    </StyledModal>
  );
};

export default Picker;
