export enum FieldTypeEnum {
  TEXT = "text",
  INPUT = "input",
  TEXTAREA = "textarea",
  USERNAME = "username",
  PASSWORD = "password",
  LANGUAGE = "language",
  FILE = "file",
  DATE = "date",
  DATETIME = "datetime",
  CHECKBOX = "checkbox",
  RADIO = "radio",
  SELECT = "select",
  TICK = "tick",
  CODE = "code",
  BOOLEAN = "boolean",
  URL = "url",
  JSON = "json",
  MARKDOWN = "markdown",
  PHONE = "phone",
}
