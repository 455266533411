export enum LanguageEnum {
  FI = "fi",
  EN = "en",
  PL = "pl",
  ET = "et",
  SV = "sv",
}

export const LanguageEnumArray = [LanguageEnum.EN, LanguageEnum.FI, LanguageEnum.ET, LanguageEnum.PL];

export enum RoleEnum {
  ORGANIZATION_ADMINISTRATOR = "organization_administrator",
  ALL_LISTS_ADMIN = "all_lists_admin",
  INSIDER_LIST_ADMIN = "insider_list_admin",
  INSIDER_LIST_USER = "insider_list_user",
}

export enum AdminRoleEnum {
  ORGANIZATION_ADMINISTRATOR = "organization_administrator",
  ALL_LISTS_ADMIN = "all_lists_admin",
  INSIDER_LIST_ADMIN = "insider_list_admin",
}

export const getAdminRoles = () => {
  return [AdminRoleEnum.ORGANIZATION_ADMINISTRATOR, AdminRoleEnum.ALL_LISTS_ADMIN, AdminRoleEnum.INSIDER_LIST_ADMIN];
};
