import styled from "styled-components/macro";

export const blockStyleFn = (contentBlock: any) => {
  const type = contentBlock.getType();
  switch (type) {
    case "header-one":
    case "header-two":
    case "header-three":
      return type;
    default:
      return "normal";
  }
};

export const StyledMarkDownStyleWrapper = styled.div`
  .DraftEditor-root {
    cursor: text;
    resize: vertical;
    height: 300px;
    overflow-y: auto;
    border-bottom: 1px solid ${props => props.theme.colors.grayBorder};
    margin-bottom: -1px;
  }

  .DraftEditor-editorContainer {
    padding: 30px;

    .normal {
      ${props => props.theme.mixins.fontFira()};
      letter-spacing: -0.1px;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
    }

    h1.header-one {
      ${props => props.theme.mixins.fontFira()};
      -webkit-font-smoothing: subpixel-antialiased;
      font-size: 28px;
      font-weight: 300;
      margin-bottom: 15px;
      color: #313333;
      letter-spacing: -0.2px;
      margin-top: 0;
    }

    h2.header-two {
      ${props => props.theme.mixins.fontFira()};
      -webkit-font-smoothing: subpixel-antialiased;
      font-size: 24px;
      font-weight: 300;
      color: #313333;
      margin-top: 25px;
      margin-bottom: 15px;
      letter-spacing: -0.2px;
    }

    h3.header-three {
      ${props => props.theme.mixins.fontFira()};
      -webkit-font-smoothing: subpixel-antialiased;
      font-size: 20px;
      font-weight: 300;
      color: #313333;
      margin-top: 25px;
      margin-bottom: 15px;
      letter-spacing: -0.2px;
    }
  }
`;
