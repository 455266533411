import React, { FunctionComponent } from "react";

import { StyledLayer } from "./layer.styles";
import { ColorEnum } from "theme/colors";

interface LayerProps {
  className?: string;
  transparent?: boolean;
  color?: ColorEnum;
  onClick?: () => void;
}

const Layer: FunctionComponent<LayerProps> = ({
  className = "",
  transparent = false,
  color = ColorEnum.white,
  children = null,
  onClick = () => {},
}) => (
  <StyledLayer className={`${className} layer`} color={color} transparent={transparent} role="button" onClick={onClick}>
    {children}
  </StyledLayer>
);

export default Layer;
