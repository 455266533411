import React, { FunctionComponent } from "react";

// components
import { StyledModal, StyledLayer, StyledBar, StyledIcon, StyledFrame } from "./modal.styles";
import Portal from "components/portal";
import { ColorEnum } from "theme/colors";

export enum ModalSizeEnum {
  SMALL = "small",
  NORMAL = "normal",
  LARGE = "large",
}

export interface ModalProps {
  modalSize?: ModalSizeEnum;
  className?: string;
  onClose: () => void;
}

const Modal: FunctionComponent<ModalProps> = ({
  children,
  className = "",
  onClose,
  modalSize = ModalSizeEnum.NORMAL,
}) => (
  <Portal>
    <StyledModal className={`xmodal ${className}`}>
      <StyledLayer>
        <StyledBar modalSize={modalSize}>
          <StyledIcon
            id="modal-close-button"
            icon="times"
            onClick={onClose}
            size={18}
            modalSize={modalSize}
            color={ColorEnum.green}
            hoverColor={ColorEnum.green}
          />
        </StyledBar>
        <StyledFrame modalSize={modalSize}>{children}</StyledFrame>
      </StyledLayer>
    </StyledModal>
  </Portal>
);

export default Modal;
