import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components/macro";

// components
import Notification from "components/notification";
import NavigationBar from "../navigation/bar";
import Confirm from "components/confirm";
import TickerLoader from "components/loaders/ticker";
import OfflineError from "../error/offline";
import { privateRoutes } from "./routes";
import Footer from "./footer";
import { StyledContainer } from "components/layout/container";

// utils
import { validate } from "utils/session";
import { changeLanguage } from "utils/i18n";

// redux
import { isAuthenticated } from "redux/actions/user";
import { get } from "utils/local-storage";
import { LanguageEnum } from "consts";

export const StyledView = styled.div`
  padding-bottom: 30px;

  & > ${StyledContainer} {
    ${props => props.theme.media.desktop`max-width: calc(100% - 60px);`};
    ${props => props.theme.media.phone`max-width: 100%;`};
  }
`;

// private route which redirects unauthenticated request to the login page
const PrivateRoute: FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // set language from cookie
    const language = (get("language") as LanguageEnum) || LanguageEnum.EN;
    changeLanguage(language);

    // this one will validate if the session if fine
    validate();
    setLoaded(true);
  }, []);

  // if not laoded, show progress bar etc
  if (!loaded) {
    return <TickerLoader />;
  }

  // check authentication
  if (!isAuthenticated()) {
    return <Redirect to={{ pathname: "/login" }} />;
  }

  // render the private context
  return (
    <Fragment>
      <NavigationBar />
      <StyledView>
        <Switch>
          {privateRoutes.map((route, index) => (
            <Route
              exact
              path={route.path}
              key={`public-route-${index}`}
              render={props => <route.component {...props} />}
            />
          ))}
        </Switch>
        <Notification />
        <Confirm />
        <OfflineError />
      </StyledView>
      <Footer />
    </Fragment>
  );
};

export default PrivateRoute;
