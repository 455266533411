import { LanguageEnum } from "consts";

export enum LanguagesActionEnum {
  LANGUAGES_UPDATE = "LANGUAGES_UPDATE",
}

// Initial state
const initialState: LanguageEnum[] = [LanguageEnum.EN];

// Reducer
const LanguagesStateReducer = (state = initialState, action: any = {}) => {
  const { languages } = action;

  switch (action.type) {
    case LanguagesActionEnum.LANGUAGES_UPDATE:
      return [...languages] as LanguageEnum[];

    default:
      return state;
  }
};

export default LanguagesStateReducer;
