import React, { FunctionComponent, Fragment } from "react";
import moment from "moment";

// components
import Day from "./day";
import Week from "./week";
import { StyledMonthGrid } from "./month-grid.styles";

const isDayInRange = (
  day: number,
  value: moment.Moment,
  min: moment.Moment | undefined,
  max: moment.Moment | undefined
) => {
  const tempMin = moment(value)
    .date(day)
    .hour(23)
    .minute(59)
    .second(59);
  const tempMax = moment(value)
    .date(day)
    .hour(0)
    .minute(0)
    .second(0);
  return tempMin.isAfter(min) && tempMax.isBefore(max);
};

interface DayModel {
  day?: number; // actual day number
  disabled?: boolean;
  selectable?: boolean;
}

interface WeekModel {
  days: DayModel[];
}

const calculateWeeks = (
  value: moment.Moment,
  min: moment.Moment | undefined,
  max: moment.Moment | undefined
): WeekModel[] => {
  const weeks: WeekModel[] = [];

  // create a temp moment
  const temp = moment(value);
  const firstDay = temp.date(1).weekday();
  const days = temp.daysInMonth();

  let week: WeekModel = { days: [] };
  for (let day = 1; day <= days; day += 1) {
    if (day === 1 && firstDay !== 0) {
      // add padding if needed
      const paddingNeeded = firstDay; // 0 is Mon, so it the week starts with Tue we week 1 padding
      for (let q = 0; q < paddingNeeded; q += 1) {
        week.days.push({ day: undefined, disabled: true });
      }
    }

    // add day
    const dayObject: DayModel = { day, selectable: true };
    if (!isDayInRange(day, value, min, max)) {
      dayObject.selectable = false;
    }
    week.days.push(dayObject);

    if (day === days) {
      // add padding if needed
      for (let q = 0; q < 7 - week.days.length; q += 1) {
        week.days.push({ day: undefined, disabled: true });
      }
    }

    // week is full
    if (week.days.length === 7 || day === days) {
      weeks.push(week);
      week = { days: [] }; // create a new week
    }
  }

  return weeks;
};

// this always starts with Sunday
const getWeekdaysMin = () => {
  const localeData = moment.localeData();
  const weekdaysMin = localeData.weekdaysMin().slice(0); // makes a copy

  // if week starts with monday, shift the array a bit
  if (localeData.firstDayOfWeek() === 1) {
    weekdaysMin.push(weekdaysMin[0]);
    weekdaysMin.shift();
  }

  return weekdaysMin;
};

interface MonthGridProps {
  className?: string;
  day: number;
  month: number;
  year: number;
  min?: moment.Moment;
  max?: moment.Moment;
  onChange: (day: number, month: number, year: number) => void;
}

const MonthGrid: FunctionComponent<MonthGridProps> = ({ className, day, month, year, onChange, min, max }) => {
  // make a dummy moment to help parsing
  const value = moment()
    .date(day)
    .month(month)
    .year(year);

  // get locale data from moment
  const weekdaysMin = getWeekdaysMin();

  // render the actual head row
  const renderHeader = () => (
    <Week>
      <Day key="head-0" />
      {weekdaysMin.map((selectedDay, index) => (
        <Day key={`head-${index + 1}`} bold>
          {selectedDay}
        </Day>
      ))}
    </Week>
  );

  // change date by clicking a day
  const fnOnSelectDay = (selectedDay: number) => {
    onChange(selectedDay, month, year); // takes month and year from the "view"
  };

  const renderDay = (dayToRender: DayModel, weekDayIndex: number) => {
    // check if the date is selected
    const selected = dayToRender.day === day;
    return (
      <Day
        key={`day-${weekDayIndex}`}
        day={dayToRender.day}
        selected={selected}
        selectable={dayToRender.selectable}
        onSelect={fnOnSelectDay}
      >
        {dayToRender.day}
      </Day>
    );
  };

  const renderWeekNumber = (week: WeekModel) => {
    // create a temp moment
    const temp = moment(value);
    const firstItem = week.days[0];
    const date = firstItem && firstItem.day ? firstItem.day : 1; // here might be padding
    temp.date(date);

    // get the weeknumber
    return <Day bold>{temp.week()}</Day>;
  };

  const renderWeek = (week: WeekModel, weekIndex: number) => {
    return (
      <Week key={`week-${weekIndex}`}>
        {renderWeekNumber(week)}
        {week.days.map((weekDay, weekDayIndex) => renderDay(weekDay, weekDayIndex))}
      </Week>
    );
  };

  const renderWeeks = () => {
    const weeks = calculateWeeks(value, min, max);

    return <Fragment>{weeks.map((week, weekIndex) => renderWeek(week, weekIndex))}</Fragment>;
  };

  return (
    <StyledMonthGrid className={className}>
      {renderHeader()}
      {renderWeeks()}
    </StyledMonthGrid>
  );
};

export default MonthGrid;
