import styled, { css } from "styled-components/macro";
import Icon from "components/icon";
import { ZEnum } from "theme/layers";

interface MyProps {
  focused: boolean;
  error: any;
}

export const StyledSelect = styled.div<MyProps>`
  position: relative;
  display: flex;

  width: 100%;
  min-height: 50px;
  background-color: ${props => props.theme.colors.grayLight};
  border-radius: 0px;

  ${props => props.theme.mixins.transition()};

  border: 1px solid ${props => props.theme.colors.grayBorder};

  ${props =>
    props.focused &&
    css`
      border-color: ${props.theme.colors.green};
    `};

  ${props =>
    props.error &&
    css`
      border-color: ${props.theme.colors.redNegative} !important;
    `};
`;

interface StyledInputProps {
  hide: boolean;
}

export const StyledInput = styled.input<StyledInputProps>`
  background: transparent;
  border: none;
  ${props => props.theme.mixins.fontFira()};
  font-weight: 400;
  flex: 1;
  padding-left: 16px;

  ${props =>
    props.hide &&
    css`
      position: absolute;
      left: -10000px;
    `}
`;

export const StyledSingleValueContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 16px;
  cursor: pointer;
  ${props => props.theme.mixins.fontFira()};
  font-weight: 400;
`;

export const StyledPlaceholder = styled(StyledSingleValueContainer)`
  text-transform: italic;
`;

export const StyledCaret = styled.div`
  display: flex;
  cursor: pointer;
`;

export const StyledCaretLine = styled.div`
  margin-top: 7px;
  margin-bottom: 7px;
  border-left: 1px solid ${props => props.theme.colors.grayBorder};
`;

export const StyledCaretIcon = styled(Icon)`
  margin: 14px;
`;

export const StyledOptionsContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.grayLight};
  ${props => props.theme.mixins.boxShadow()};
  position: absolute;
  border: 1px solid ${props => props.theme.colors.gray};
  z-index: ${ZEnum.COMPONENT};

  left: 0px;
  width: 100%;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

interface StyledOptionProps {
  selected?: boolean;
}

export const StyledOption = styled.div<StyledOptionProps>`
  background-color: ${props => props.theme.colors.white};
  padding: 10px 20px !important;
  border-left: 1px solid ${props => props.theme.colors.white};
  border-right: 1px solid ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.gray};
  cursor: pointer;
  color: ${props => props.theme.colors.fontColor} !important;
  font-weight: 400;
  font-size: 12px;

  &:first-child {
    border-top: 1px solid ${props => props.theme.colors.white};
  }

  &:last-child {
    border-bottom: 1px solid ${props => props.theme.colors.white};
  }

  &:hover {
    background-color: ${props => props.theme.colors.grayLight};
    text-decoration: none;
    color: ${props => props.theme.colors.green} !important;
  }

  ${props =>
    props.selected &&
    css`
      background-color: ${props => props.theme.colors.grayLight};
      text-decoration: none;
      color: ${props => props.theme.colors.green} !important;
    `};
`;

export const StyledNoOptions = styled(StyledOption)`
  pointer-events: none;
`;

export const StyledListContainer = styled.div`
  flex: 1;
  padding-top: 9px;
  padding-left: 9px;
`;
