import { doGet, doPost } from "utils/ajax";

export const get = async (uuid: string, hash: string) => {
  const result = await doGet(`/verification-links/${uuid}?hash=${hash}`);
  return result.verificationLink;
};

export const requestCode = async (uuid: string, destination: string, notificationType: string, hash: string) => {
  const result = await doPost(`/verification-links/${uuid}/code?hash=${hash}`, {
    destination,
    notificationType,
  });
  return result;
};

export const verifyCode = async (uuid: string, verificationCode: string | undefined, hash: string) => {
  const result = await doGet(`/verification-links/${uuid}/code/${verificationCode}?hash=${hash}`);
  return result;
};

export const authenticate = async (uuid: string, hash?: string) => {
  const result = await doPost(`/verification-links/${uuid}/authenticate?hash=${hash || ""}`);
  return result;
};
