import React, { Fragment, FunctionComponent, useState } from "react";
import { translate } from "utils/i18n";

// child components
import PublicForm from "../elements/form";
import PublicNav from "../elements/nav";
import Form, { FormButtonProps } from "components/formik/form";
import { ButtonColorEnum } from "components/button";
import { StageTypeEnum } from "./view";
import PublicGuide from "../elements/guide";
import { NavContainer } from "../elements/nav.styles";

// utils
import { doPost } from "utils/ajax";
import { login2FA } from "utils/auth";
import { TwoFactorDeliveryTypeEnum } from "redux/models/user";

// redux
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { useSelector } from "react-redux";
import Indicator from "components/indicator";
import { ColorEnum } from "theme/colors";
import { get } from "utils/session-storage";
import { FieldTypeEnum } from "redux/models/field-type";
import FormikLayoutColumns from "components/formik/layout/columns";
import { FieldModel } from "components/formik/utils";

// some envs
const { REACT_APP_CSRF_TOKEN_HEADER = "X-CSRF-Token", REACT_APP_CSRF_TOKEN_COOKIE = "X-CSRF-Token" } = process.env;

interface Login2FAFormInterface {
  method: TwoFactorDeliveryTypeEnum;
  onStageChange: (stage: StageTypeEnum) => void;
}

interface FormValues {
  code: string;
}

const Login2FAForm: FunctionComponent<Login2FAFormInterface> = ({ method, onStageChange }) => {
  useSelector(languageSelector, languageEquality);
  const [codeSentAgain, setCodeSentAgain] = useState(false);

  const getSchemaForCode = () => {
    const schema: FieldModel[] = [
      {
        name: "code",
        type: FieldTypeEnum.CODE,
        codeOptions: {
          maxLength: 6,
        },
      },
    ];
    return schema;
  };

  const getInitialValuesForCode = () => {
    const initialValues: FormValues = { code: "" };
    return initialValues;
  };

  const getButtonsForCode = () => {
    const buttons: FormButtonProps[] = [
      {
        children: translate("public.2fa.buttons.login"),
        color: ButtonColorEnum.PUBLIC,
      },
    ];
    return buttons;
  };

  const getHeaders = () => {
    const token = get(REACT_APP_CSRF_TOKEN_COOKIE);
    return { [REACT_APP_CSRF_TOKEN_HEADER]: token };
  };

  const onSubmit2FACode = async (values: FormValues) => {
    const headers = getHeaders();
    const { code } = values;
    try {
      await doPost("/organization/2fa/code", { code }, { headers });
      await login2FA();
    } catch (err) {
      onStageChange(StageTypeEnum.LOGIN_2FA_ERROR);
    }
  };

  const onResend2FA = async () => {
    const headers = getHeaders();
    try {
      await doPost("/organization/2fa/resend", {}, { headers });
      setCodeSentAgain(true);
      setTimeout(() => setCodeSentAgain(false), 2500);
    } catch (err) {}
  };

  const renderLogin2FAForm = () => {
    return (
      <Fragment>
        <FormikLayoutColumns formSchema={getSchemaForCode()} />
        <NavContainer>
          {!codeSentAgain && (
            <Fragment>
              {translate("public.2fa.labels.resend")}{" "}
              <PublicNav onClick={onResend2FA}>{translate("public.2fa.buttons.resend")}</PublicNav>
            </Fragment>
          )}
          {codeSentAgain && <Indicator color={ColorEnum.green}>Code has been resent</Indicator>}
        </NavContainer>
      </Fragment>
    );
  };

  return (
    <PublicForm>
      <PublicGuide>
        {method === TwoFactorDeliveryTypeEnum.EMAIL && translate("public.2fa.guides.email")}
        {method === TwoFactorDeliveryTypeEnum.SMS && translate("public.2fa.guides.sms")}
      </PublicGuide>

      <Form
        initialValues={getInitialValuesForCode()}
        buttons={getButtonsForCode()}
        onSubmit={onSubmit2FACode}
        onRender={renderLogin2FAForm}
      />
    </PublicForm>
  );
};

export default Login2FAForm;
