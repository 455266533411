import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components/macro";

interface StyledLinkInterace {
  block: boolean;
}

export const StyledLink = styled.a<StyledLinkInterace>`
  ${props =>
    props.block &&
    css`
      display: block;
    `}
`;

interface LinkProps {
  href: string;
  blank?: boolean;
  block?: boolean;
}

const Link: FunctionComponent<LinkProps> = ({ href, blank = true, block = false, children }) => {
  return (
    <StyledLink href={href} block={block} target={blank ? "_blank" : "_self"}>
      {children}
    </StyledLink>
  );
};

export default Link;
