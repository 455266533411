import { UserActionEnum } from "redux/reducers/user";
import { AuthTypeEnum, UserModel } from "redux/models/user";
import { store } from "redux/store";
import { UserTypeEnum } from "redux/models/user";
import { OrganizationModel } from "redux/models/organization";

export const update = (payload: UserModel) => store.dispatch({ type: UserActionEnum.USER_UPDATE, payload });

export const login = (redirectPath?: string, authType?: AuthTypeEnum) =>
  store.dispatch({ type: UserActionEnum.USER_LOGIN, redirectPath, authType });

export const isAuthenticated = () => {
  const userState = store.getState().user;
  const is = userState.authenticated === true;
  return is;
};

export const isTokenUser = () => {
  const userState = store.getState().user;
  const { authType } = userState;
  return authType === AuthTypeEnum.TOKEN;
};

export const getAuthType = () => {
  const userState = store.getState().user;
  return userState.authType as AuthTypeEnum;
};

export const isSystemAdmin = () => {
  const userState: any = store.getState().user;
  const { userType } = userState;
  return userType === UserTypeEnum.SYSTEM_ADMIN;
};

export const hasRole = (role: string | string[]) => {
  // if no role required, then allow it
  if (!role) {
    return true;
  }

  // make array, some of the roles must apply
  const arr = role instanceof Array ? role : [role];

  // another security check, if empty arry
  if (role.length === 0) {
    return true;
  }

  // make sure the roles is an array
  const organization: OrganizationModel = store.getState().organization;

  // if no org, no access
  if (!organization) {
    return false;
  }

  // take roles from current or default to empty array
  let { roles = [] } = organization;
  return arr.filter(role => roles.includes(role)).length > 0;
};
