// utils
import { store } from "redux/store";
import { login } from "redux/actions/user";
import { isAuthenticated } from "redux/actions/user";
import { poll } from "services/session";
import { set, remove } from "./session-storage";

let SESSION_TIMER: number | undefined;
let schedule: () => void | undefined;

const INTERVAL = parseInt(process.env.REACT_APP_SESSION_POLL_INTERVAL || "60", 10);

// check session validity (polled on intervals)
const validate = async () => {
  const state = store.getState();

  if (isAuthenticated()) {
    // check from the server
    try {
      const result = await poll();
      const { authType, idpName } = result;

      // set idp name
      if (idpName) {
        set("idpName", idpName);
      } else {
        remove("idpName");
      }

      // push to redux, if changed
      const userState: any = state.user;
      if (authType !== userState.authType) {
        login(undefined, authType);
      }
    } catch (error) {
      // the session is down (or the server is down)
      // note: axios client will already handle the exception and the logout
    }
  }

  // add a timer, should be run even in case of resolve or reject
  schedule();
};

// an utility method to reschedule the next check
schedule = () => {
  const state = store.getState();

  // clear the old timer
  clearTimeout(SESSION_TIMER);

  // set new one
  let delay = INTERVAL * 1000;
  if (state.app.offline) {
    delay = 2500; // a bit shorted check period when the app is offline
  }

  // use window.setTimeout instead of NodeJS.timer :P
  SESSION_TIMER = window.setTimeout(validate, delay);
};

export default { validate };
export { validate };
