import styled from "styled-components/macro";

export const StyledNavigationSubroute = styled.a`
  white-space: nowrap;
  background-color: ${props => props.theme.colors.white};
  padding: 10px 20px !important;
  border-left: 1px solid ${props => props.theme.colors.white};
  border-right: 1px solid ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.gray};
  cursor: pointer;
  color: ${props => props.theme.colors.fontColor} !important;
  font-weight: 400;
  font-size: 12px;

  &:first-child {
    border-top: 1px solid ${props => props.theme.colors.white};
  }

  &:last-child {
    border-bottom: 1px solid ${props => props.theme.colors.white};
  }

  &:hover {
    background-color: ${props => props.theme.colors.grayLight};
    text-decoration: none;
    color: ${props => props.theme.colors.green} !important;
  }
`;
