import styled, { css } from "styled-components/macro";
import { fontFiraMono } from "theme/mixins";

interface StyledCodeContainerProps {
  length: number;
}

interface StyledCodeProps {
  ref?: any;
  length: number;
}

const blockSize: number = 42; // width of a single white block, not including borders
const blockSizeWithBorders = blockSize + 1;

export const StyledCodeContainer = styled.div<StyledCodeContainerProps>`
  width: ${props =>
    css`
      ${props.length * blockSizeWithBorders}px;
    `};
  position: relative;
`;

export const StyledWhitey = styled.div`
  position: absolute;
  right: ${blockSize * -1 - 1}px;
  top: 0px;
  width: ${blockSize}px;
  height: 60px;
  background: white;
`;

export const StyledCode = styled.input<StyledCodeProps>`
  width: ${props =>
    css`
      ${(props.length + 1) * (blockSize + 1)}px;
    `}
  height: 60px;
  ${fontFiraMono()};
  font-size: 20px;
  border: 1px solid ${props => props.theme.colors.grayBorder};
  background-image: repeating-linear-gradient(90deg, transparent, transparent ${blockSize}px, ${props =>
  props.theme.colors.grayBorder} ${blockSize}px, ${props => props.theme.colors.grayBorder} ${blockSizeWithBorders}px);
  letter-spacing: 30.8px;
  padding-left: 16px;
  color: ${props => props.theme.colors.fontColor};
  text-transform: uppercase;
`;
