import { EmailTemplatesActionEnum } from "redux/reducers/email-templates";
import { store } from "../store";
import { list as getEmailTemplatesFromServer } from "services/email-templates";
import { EmailIncludeEnum } from "redux/models/email-templates";

export const update = async () => {
  const emailTemplates = await getEmailTemplatesFromServer();
  store.dispatch({ type: EmailTemplatesActionEnum.EMAIL_TEMPLATES_UPDATE, emailTemplates });
};

export const list = () => {
  const state = store.getState();
  if (state.emailTemplates) {
    return [...state.emailTemplates];
  }
  return null;
};

export const get = (emaiLTemplateUuid: string) => {
  const emailTemplates = list();
  if (emailTemplates) {
    return emailTemplates.find(emailTemplate => emailTemplate.uuid === emaiLTemplateUuid);
  }
  return null;
};

const hasLink = (template: any, inc: EmailIncludeEnum) => {
  const { includes = [] } = template;
  for (const include of includes) {
    if (include.include === inc) {
      return true;
    }
  }
  return false;
};
export const hasLoginLink = (template: any) => hasLink(template, EmailIncludeEnum.LOGIN_LINK);

export const hasPasswordLink = (template: any) => hasLink(template, EmailIncludeEnum.FORGOTTEN_PASSWORD);
