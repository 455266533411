import React, { FunctionComponent, Fragment } from "react";
import Row from "components/layout/row";
import Col from "components/layout/col";
import { renderField } from "utils/form";
import { StyledColumns } from "./columns.styles";
import { FieldModel } from "../utils";

interface FormikLayoutColumnsProps {
  className?: string;
  name?: string;
  formSchema: FieldModel[];
  columns?: number;
}

// splits form schema in to rows
const splitToRows = (formSchema: FieldModel[], columns: number) => {
  const rowSchema: FieldModel[][] = [];
  let rowIndex = 0;
  for (const formField of formSchema) {
    // make sure the chunk exists
    rowSchema[rowIndex] = rowSchema[rowIndex] || [];

    // precheck, if the row has stuff, but the field is full, move to next one
    if (rowSchema[rowIndex].length > 0 && formField.wide) {
      rowIndex++;
      rowSchema[rowIndex] = [];
    }

    // push field to chunk
    rowSchema[rowIndex].push(formField);

    // if the row is full, move to next one
    if (rowSchema[rowIndex].length === columns) {
      // increase the index, if the row is full
      rowIndex++;
    } else if (formField.wide) {
      rowIndex++;
    }
  }

  return rowSchema;
};

const FormikLayoutColumns: FunctionComponent<FormikLayoutColumnsProps> = ({ formSchema, name, columns = 1 }) => {
  const rows = splitToRows(formSchema, columns);

  const renderRow = (rowSchema: FieldModel[], rowIndex: number) => {
    const addEmptyColumns = () => {
      if (rowSchema.length === 1 && !rowSchema[0].wide) {
        const cols = [];
        for (let i = 0; i < columns - rowSchema.length; i += 1) {
          const key = `col-empty-${i}`;
          cols.push(<Col className="empty" key={key} />);
        }
        return <Fragment>{cols}</Fragment>;
      }
    };

    return (
      <Row key={`row-${rowIndex}`}>
        {rowSchema.map((fieldSchema, fieldIndex) => {
          const modifiedSchema = { ...fieldSchema, name: name ? `${name}.${fieldSchema.name}` : fieldSchema.name };
          const key = `col-${fieldIndex}`;
          return <Col key={key}>{renderField(modifiedSchema)}</Col>;
        })}
        {addEmptyColumns()}
      </Row>
    );
  };

  return <StyledColumns>{rows.map((row, rowIndex) => renderRow(row, rowIndex))}</StyledColumns>;
};

export default FormikLayoutColumns;
