import React, { Component } from "react";
import { report } from "services/error";
import UnexpectedException from "./unexpected-error";

interface ErrorBoundaryInterface {}

// This will catch only render time errors from react components.
// Without ErrorBoundary in the DOM the app in production might show
// broken components.

class ErrorBoundary extends Component<ErrorBoundaryInterface> {
  state = {
    err: null,
  };

  componentDidCatch(err: any) {
    // Toggle err flag to show an error notification instead of broken UI.
    this.setState({ err });
    report(err);
  }

  render() {
    const { err } = this.state;
    if (err) {
      return <UnexpectedException />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
