import momentjs from "moment";
import { translate, translateTo } from "utils/i18n";
import store from "redux/store";
import moment from "moment";

// converts given date to a moment object, optionally with utc mode
export const toMoment = (date: any, UTC: boolean = false) => {
  const fn = UTC ? momentjs.utc : momentjs;

  if (typeof date === "string") {
    // get current language and create fixed translator
    const language = store.getState().language;

    // try to parse string with multitude of formats
    const localeFormats = [translateTo(language, "date.full"), translateTo(language, "date.short")];
    return fn(date, [momentjs.ISO_8601, ...localeFormats]);
  }

  return fn(date);
};

export const toFormat = (date: any, desiredFormat?: string, UTC?: boolean) => {
  const o = toMoment(date, UTC);

  if (!date || !o.isValid()) {
    return date as string;
  }

  return o.format(desiredFormat || translate("date.full")) as string;
};

// shortcut formatters
export const formatShort = (date: any, UTC: boolean = false) => toFormat(date, translate("date.short"), UTC) as string;
export const formatFull = (date: any, UTC: boolean = false) => toFormat(date, translate("date.full"), UTC) as string;

// mar specific formatters, supports both UTC and local
export const formatMar = (date: any, UTC = true) => formatMarFull(date, UTC);
export const formatMarShort = (date: any, UTC = true) => {
  if (date) {
    const str = toFormat(date, "YYYY-MM-DD", UTC) as string;
    return UTC ? `${str} UTC` : str;
  }
  return "";
};
export const formatMarFull = (date: any, UTC = true) => {
  if (date) {
    const str = toFormat(date, "YYYY-MM-DD, HH:mm", UTC) as string;
    return UTC ? `${str} UTC` : str;
  }
  return "";
};

export const getFormats = () => [
  "YYYY-M-D, H.m",
  "D.M.YYYY-H.m",
  "D.M.YYYY - H.m",
  "D.M.YYYY H.m",
  "YYYY-M-DD",
  "D.M.YYYY",
  moment.ISO_8601,
  moment.RFC_2822,
];
