import { SettingsActionEnum } from "redux/reducers/settings";
import { store } from "../store";
import { list } from "services/settings";

export const update = async () => {
  const settings = await list();
  store.dispatch({ type: SettingsActionEnum.UPDATE_SETTINGS, settings });
};

export const isInsider2FABySMSEnabled = () => {
  const { settings } = store.getState();
  const { verificationLinkSettings } = settings;
  const { useTwoFactor, insiderSmsCode } = verificationLinkSettings;
  return useTwoFactor && insiderSmsCode;
};
