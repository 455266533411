import React, { FunctionComponent } from "react";

import { StyledErrorContainer } from "./error.styles";
import { flatten } from "flat";

interface FormikErrorProps {
  className?: string;
  error?: any;
}

export const parseError = (form: any, field: any) => {
  const { touched = {}, errors = {} } = form;

  // flat errors and touched (array/grids require this operation)
  const errorMap: any = flatten(errors);
  const touchMap: any = flatten(touched);

  // check for errors
  const doesFormHasErrors = Object.keys(errorMap).length > 0;
  const doesFieldHasErrors = !!errorMap[field.name];
  const isFieldTouched = !!touchMap[field.name];
  const error = doesFormHasErrors && doesFieldHasErrors && isFieldTouched;

  // return error string, if exists
  if (error) {
    return errorMap[field.name] as string;
  }

  // no error at all
  return undefined;
};

const FormikError: FunctionComponent<FormikErrorProps> = ({ className, error }) => {
  // if no error, exit
  if (!error) {
    return null;
  }

  return <StyledErrorContainer className={`${className || ""} error`}>{error}</StyledErrorContainer>;
};

export default FormikError;
