import axios from "axios";
import delay from "delay";

// utils
import { logout, LogoutTypeEnum } from "./auth";

// redux
import store from "redux/store";
import { online, offline, redirect } from "redux/actions/app";
import { isAuthenticated } from "redux/actions/user";
import { get } from "./session-storage";
import { OrganizationModel } from "redux/models/organization";

export enum MethodEnum {
  PUT = "PUT",
  POST = "POST",
  DELETE = "DELETE",
  GET = "GET",
  PATCH = "PATCH",
}

interface RequestOptionsInterface {
  method?: MethodEnum;
  url?: string;
  baseURL?: string;
  headers?: any;
  withCredentials?: boolean;
  file?: any;
  data?: any;
  params?: any;
}

const {
  REACT_APP_API_BASE_URL = "http://localhost:3000/api/v1",
  REACT_APP_CORS = "false",
  REACT_APP_CSRF_TOKEN_HEADER = "X-CSRF-Token",
  REACT_APP_CSRF_TOKEN_COOKIE = "X-CSRF-Token",
  REACT_APP_DELAY,
} = process.env;

const DELAY = parseInt(REACT_APP_DELAY || "", 10) || null;

const getOrganizationUuid = () => {
  const state = store.getState();
  const organization: OrganizationModel = state.organization;
  return organization ? organization.uuid : undefined;
};

const requiresCsrf = (method: MethodEnum) => {
  return (
    method === MethodEnum.PUT ||
    method === MethodEnum.POST ||
    method === MethodEnum.PATCH ||
    method === MethodEnum.DELETE
  );
};

const isNetworkError = (err: Error) => err && err.message === "Network Error";

const isAuthError = (err: any) => err && isAuthenticated() && err.request.response.indexOf("auth_failure") !== -1;

// an utility method to create a request
const Request = async (options: RequestOptionsInterface) => {
  // options for the axious
  options.method = options.method ? (options.method.toUpperCase() as MethodEnum) : MethodEnum.GET;
  options.baseURL = REACT_APP_API_BASE_URL;
  options.headers = { ...(options.headers || {}), "Access-Control-Allow-Origin": "*" };

  // cors
  if (REACT_APP_CORS === "true") {
    options.withCredentials = true;
  }

  // add organization header
  const organizationUuid = getOrganizationUuid();
  if (organizationUuid) {
    options.headers["x-active-org"] = organizationUuid;
  }

  // x-csrf-token (only for authenticated users)
  if (isAuthenticated() && requiresCsrf(options.method)) {
    const csrfToken = get(REACT_APP_CSRF_TOKEN_COOKIE);
    options.headers[REACT_APP_CSRF_TOKEN_HEADER] = csrfToken;
  }

  // content type
  let contentType = "application/json";
  if (options.method === MethodEnum.PATCH) {
    contentType = "application/json-patch+json";
  } else if (options.file) {
    contentType = "multipart/form-data";
  }
  options.headers.ContentType = contentType;

  // the actual request
  let response;
  try {
    response = await axios(options);
    if (DELAY) {
      console.warn(`Warn: using delay of ${DELAY}`);
      await delay(DELAY);
    }

    // remove potential msg for unreachable server
    online();
  } catch (err) {
    // if the failing path if /logout, just ignore
    if (options.url === "/logout") {
      return;
    }

    // when the server is down
    if (isNetworkError(err)) {
      offline();
      return;
    }

    // handle expired session
    if (isAuthError(err)) {
      // try to detect if the org uuid has changed in the middle, then we can ignore the error
      if (organizationUuid !== getOrganizationUuid()) {
        return null;
      }

      // store the current path
      const { pathname } = (store as any).getState().router.location;
      redirect(pathname);

      // force logout
      logout(LogoutTypeEnum.SESSION_TIMEOUT);
      return;
    }

    // delegate higher
    if (err.response) {
      // throw new error with correct error message from the server
      throw new Error(err.response.data.error.message);
    } else {
      // just rethrow the error
      throw err;
    }
  }

  // return json data
  return response.data;
};

export default Request;

export const doGet = async (url: string, options?: RequestOptionsInterface) =>
  Request({ ...options, url, method: MethodEnum.GET });

export const doPost = async (url: string, data?: any, options?: RequestOptionsInterface) =>
  Request({ ...options, url, data, method: MethodEnum.POST });

export const doPut = async (url: string, data?: any, options?: RequestOptionsInterface) =>
  Request({ ...options, url, data, method: MethodEnum.PUT });

export const doPatch = async (url: string, data?: any, options?: RequestOptionsInterface) =>
  Request({ ...options, url, data, method: MethodEnum.PATCH });

export const doDelete = async (url: string, data?: any, options?: RequestOptionsInterface) =>
  Request({ ...options, url, data, method: MethodEnum.DELETE });
