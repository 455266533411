import React, { FunctionComponent, Fragment, useState } from "react";

// components
import NavigationToggler from "./elements/toggler";
import NavigationLanguage from "./elements/language";
import { getRoutes as getAccountRoutes } from "./elements/account";
import { RouteInterface } from "./elements/route";

// utils
import { goto } from "../app/navigation";
import { translate } from "utils/i18n";
import { formatListType } from "utils/string";
import { plainSort } from "utils/table";

// redux
import { AuthTypeEnum } from "redux/models/user";
import { selector as listsSelector, equalityFn as listsEquality } from "redux/hooks/lists";
import { selector as listTypesSelector, equalityFn as listTypesEquality } from "redux/hooks/list-types";
import { hasListsOfType } from "redux/actions/lists";
import { isTokenUser } from "redux/actions/user";
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { useSelector } from "react-redux";
import { selector as userSelector, equalityFn as userEquality } from "redux/hooks/user";

// styles
import { StyledRoute, StyledNavigationMobile, StyledRouteContainer } from "./mobile.styles";

interface NavigationMobileInterface {
  className?: string;
}

const NavigationMobile: FunctionComponent<NavigationMobileInterface> = ({ className }) => {
  const userState = useSelector(userSelector, userEquality);
  const listsState = useSelector(listsSelector, listsEquality);
  const listTypesState = useSelector(listTypesSelector, listTypesEquality);
  useSelector(languageSelector, languageEquality);
  const [open, setOpen] = useState<boolean>(false);

  if (!listTypesState) {
    return null;
  }

  // convert list types into an array of routes
  const routes: RouteInterface[] = [];
  listTypesState
    // sort first
    .sort((a, b) => plainSort(a.sorting, b.sorting, false))
    // then create a route for each
    .forEach(listType => {
      const { uuid, settings } = listType;
      const route = { name: uuid || "", path: `/lists/${uuid}`, text: formatListType(listType) };

      // skip disabled list types
      if (!settings.enabled) {
        return;
      }

      // if unique, but the user does not have any lsits, skip
      if (settings.unique && listsState) {
        // ignore, if the user has not access to an unique list
        if (!hasListsOfType(listsState, listType.uuid || "")) {
          return null;
        }

        // change route to match the first actual list
        const list = listsState.find(list => list.type.uuid === listType.uuid);
        if (list) {
          route.path = `/list/${list.uuid}`;
        }
      }

      // push to be rendered
      routes.push(route);
    });

  // add "All lists" route
  const homeRoute = { name: "uuid", text: translate("home"), path: "/lists" };
  routes.splice(0, 0, homeRoute);

  // add account things
  getAccountRoutes().forEach(accountRoute => routes.push(accountRoute));

  const onToggle = () => setOpen(!open);

  // list types etc
  const renderRoutes = () => {
    return (
      <Fragment>
        {routes.map(route => {
          const { path, text } = route;
          const onClick = (event: any) => {
            event.preventDefault();
            onToggle();
            if (path) {
              goto(path);
            }
          };

          return (
            <StyledRoute key={path} onClick={onClick}>
              {text}
            </StyledRoute>
          );
        })}
      </Fragment>
    );
  };

  return (
    <StyledNavigationMobile className={className}>
      {userState.authType === AuthTypeEnum.CREDENTIALS && <NavigationToggler onClick={onToggle} />}
      <NavigationLanguage />
      {open && <StyledRouteContainer>{!isTokenUser() && renderRoutes()}</StyledRouteContainer>}
    </StyledNavigationMobile>
  );
};

export default NavigationMobile;
