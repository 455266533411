import React, { FunctionComponent } from "react";
import { translate } from "utils/i18n";

// components
import NavigationRoute, { RouteInterface } from "./route";
import { goto } from "modules/app/navigation";

// redux
import { AuthTypeEnum } from "redux/models/user";
import { isSystemAdmin, hasRole } from "redux/actions/user";
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { useSelector } from "react-redux";
import { selector as userSelector, equalityFn as userEquality } from "redux/hooks/user";

// utils
import { RoleEnum } from "consts";

// system -admin route
export const getRoutes = () => {
  const routes: RouteInterface[] = [];
  if (isSystemAdmin()) {
    routes.push({
      name: "systemadmin",
      path: "/systemadmin",
      text: translate("navigation.avatar.systemAdmin"),
    });
  }
  if (hasRole(RoleEnum.ORGANIZATION_ADMINISTRATOR)) {
    routes.push({
      name: "defaults",
      path: "/defaults",
      text: translate("navigation.avatar.defaults"),
    });
    routes.push({
      name: "organization",
      path: "/organization",
      text: translate("navigation.avatar.organization"),
    });
  }
  routes.push({ name: "profile", path: "/profile", text: translate("navigation.avatar.profile") });
  routes.push({ name: "support", url: translate("supportSite"), text: translate("navigation.avatar.support") });
  routes.push({ name: "logout", path: "/logout", text: translate("navigation.avatar.logout") });

  return routes;
};

interface NavigationAccountInterface {}

const NavigationAccount: FunctionComponent<NavigationAccountInterface> = () => {
  const userState = useSelector(userSelector, userEquality);
  useSelector(languageSelector, languageEquality);

  const fnOnClick = (route: RouteInterface) => {
    const { path, url } = route;
    if (path) {
      goto(path);
    } else {
      window.open(url, "_blank");
    }
  };

  // immediately exit if auth not by credentials
  if (userState.authType === AuthTypeEnum.TOKEN) {
    return null;
  }

  // render the dropdown
  return <NavigationRoute onClick={fnOnClick} route={{ name: "account", subroutes: getRoutes(), icon: "sliders-h" }} />;
};

export default NavigationAccount;
