import React, { FunctionComponent, useEffect } from "react";
import queryString from "query-string";

import TickerLoader from "components/loaders/ticker";
import { goto } from "../../modules/app/navigation";
import { update as updateAuth } from "utils/auth";

// redux
import { AuthTypeEnum } from "redux/models/user";
import { login } from "redux/actions/user";
import { selector as routerSelector, equalityFn as routerEquality } from "redux/hooks/router";
import { useSelector } from "react-redux";
import { set } from "utils/session-storage";

interface SSOLoginInterface {}

const SSOLogin: FunctionComponent<SSOLoginInterface> = () => {
  const routerState = useSelector(routerSelector, routerEquality);
  const { location } = routerState;
  const { search } = location;
  const values = queryString.parse(search);
  const { csrfToken } = values;

  useEffect(() => {
    const parseToken = async () => {
      if (csrfToken && typeof csrfToken === "string") {
        // Store csrfToken
        set(process.env.REACT_APP_CSRF_TOKEN_COOKIE || "", csrfToken);

        // mark as authenticated
        login(undefined, AuthTypeEnum.SAML);

        // update user information
        updateAuth();

        // goto to login
        goto("/");
      }
    };

    parseToken();
  }, [csrfToken]);

  return <TickerLoader />;
};

export default SSOLogin;
