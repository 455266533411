import React, { FunctionComponent } from "react";

// components
import PublicNav from "./nav";
import { StyledPlate, StyledTitle, StyledText, StyleImage, StyledIcon, PlateTypeEnum } from "./plate.styles";

// images
import logo from "images/ticker_logo_circle.svg";

// utils
import { nl2br } from "utils/string";
import { ColorEnum } from "theme/colors";

interface PublicPlateButtonInterface {
  fn: () => void;
  text: string;
}
interface PublicPlateInterface {
  type: PlateTypeEnum;
  title: string;
  text: string;
  button?: PublicPlateButtonInterface;
}

const PublicPlate: FunctionComponent<PublicPlateInterface> = ({
  type = PlateTypeEnum.NORMAL,
  title = "",
  text = "",
  button,
}) => (
  <StyledPlate type={type}>
    {type === PlateTypeEnum.NORMAL && <StyleImage className="logo" src={logo} alt="Ticker" width={42} />}
    {type === PlateTypeEnum.ERROR && <StyledIcon icon="exclamation-circle" size={42} color={ColorEnum.redNegative} />}
    <StyledTitle>{title}</StyledTitle>
    <StyledText>{nl2br(text)}</StyledText>
    {button && <PublicNav onClick={button.fn}>{button.text}</PublicNav>}
  </StyledPlate>
);

export default PublicPlate;
