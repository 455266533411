import { doGet, doPatch } from "utils/ajax";
import { PrivacyPolicyModel, PrivacyPolicyContentModel } from "redux/models/privacy-policy";

export const get = async () => {
  const result = await doGet(`/privacy-policies`);
  return result.organizationPrivacyPolicy as PrivacyPolicyModel;
};

export const update = async (
  privacyPolicyUuid: string,
  privacyPolicyContentUuid: string,
  contentData: PrivacyPolicyContentModel
) => {
  const result = await doPatch(
    `privacy-policies/${privacyPolicyUuid}/privacy-policy-contents/${privacyPolicyContentUuid}`,
    contentData
  );
  return result;
};

export default { get, update };
