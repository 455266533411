import styled from "styled-components/macro";

import Container from "components/layout/container";

export const StyledNavigationBar = styled.div`
  ${props => props.theme.mixins.fontFira()};
  background-color: ${props => props.theme.colors.greenDark};
  border-top: 3px solid ${props => props.theme.colors.greenAlternative};
  height: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
`;

export const StyledNavigationBarContent = styled(Container)`
  flex: 1;
  padding: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${props => props.theme.media.desktop`max-width: calc(100% - 60px);`};
  ${props => props.theme.media.phone`max-width: calc(100% - 30px);`};
`;
