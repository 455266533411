import React, { FunctionComponent } from "react";

import { StyledToggler } from "./toggler.styles";
import { ColorEnum } from "theme/colors";

interface NavigationTogglerInterface {
  className?: string;
  onClick: () => void;
}

const NavigationToggler: FunctionComponent<NavigationTogglerInterface> = ({ className, onClick }) => (
  <StyledToggler className={className} icon="bars" onClick={onClick} color={ColorEnum.white} />
);

export default NavigationToggler;
