import styled, { css } from "styled-components/macro";

// child components
import PublicNav from "./nav";

export const StyledPublicLanguageContainer = styled.div`
  margin-top: 60px;

  .language {
  }
`;

interface StyledLanguageItemInterface {
  active: boolean;
}

export const StyledLanguageItem = styled(PublicNav)<StyledLanguageItemInterface>`
  margin-top: 0;
  ${props => props.theme.mixins.noSelect()};
  ${props =>
    props.active &&
    css`
      border-bottom: 1px solid white !important;
    `};

  &:last-of-type {
    margin-right: 0px;
  }
`;
