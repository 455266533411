import React, { FunctionComponent } from "react";

import { StyledReactMarkdown } from "./markdown.styles";

interface MarkdownVariableProps {
  [key: string]: string;
}

interface MarkdownProps {
  className?: string;
  text: string;
  variables?: MarkdownVariableProps;
}

// allowed protocols
const WHITELIST = ["http:", "https:", "ftp:", "mailto:", "tel:"];

const securityCheck: any = (uri: string) => {
  // one of the allowed protocols
  if (uri) {
    for (const protocol of WHITELIST) {
      if (uri.toLowerCase().indexOf(protocol) === 0) {
        return uri;
      }
    }
  }

  // no secure protocol used
  return undefined;
};

const Markdown: FunctionComponent<MarkdownProps> = ({ text, className = "", variables = {} }) => {
  let parsedText = text;
  for (const key in variables) {
    // first espace underscores to match the markdown syntax
    const pattern = `{{${key}}}`.replace(/_/gi, "\\_");
    parsedText = parsedText.replace(pattern, variables[key]);
  }

  // replace "++" with <span style="underline"> because native markdown does not support it (but draftjs does)
  const matches = parsedText.match(/\+\+/g);
  if (matches) {
    for (let i = 0; i < matches.length; i += 1) {
      parsedText = parsedText.replace(/\+\+/, i % 2 === 0 ? '<span style="text-decoration: underline;">' : "</span>");
    }
  }

  return (
    <StyledReactMarkdown
      escapeHtml={false}
      className={className}
      source={parsedText}
      transformLinkUri={securityCheck}
    />
  );
};

export default Markdown;
