import React, { FunctionComponent } from "react";
import { translate, translateTo } from "utils/i18n";

// components
import SelectField from "./select";
import { LanguageEnum } from "consts";
import { sortBy } from "utils/array";

import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { selector as languagesSelector, equalityFn as languagesEquality } from "redux/hooks/languages";
import { useSelector } from "react-redux";
import { FieldTypeEnum } from "redux/models/field-type";
import { FieldModel } from "../utils";

export interface LanguageOptions {
  // language component might have extras
  include?: LanguageEnum;
}

// build collection of language options
export const getLanguageOptions = (
  currentLanguage: LanguageEnum,
  languages: LanguageEnum[],
  include?: LanguageEnum
) => {
  // add all organization languages
  const options = languages.map((language: LanguageEnum) => {
    const name = translateTo(language, "language");
    const nameInCurrent = translateTo(language, `languages.${language}`);

    // compose the label
    let label = `${name}`;
    if (currentLanguage !== language) {
      label += ` (${nameInCurrent})`;
    }

    // option json
    const option = {
      value: language,
      label,
    };
    return option;
  });

  // add current, if needed
  if (include && !options.find(option => option.value === currentLanguage)) {
    options.push({ value: include, label: translate(`languages.${include}`) });
  }

  // sort by label
  sortBy(options, "label");
  return options;
};

const FormikLanguage: FunctionComponent<FieldModel> = ({
  name,
  label,
  guide,
  required,
  languageOptions = {},
  onChange = () => {},
}) => {
  const languageState = useSelector(languageSelector, languageEquality);
  const languagesState = useSelector(languagesSelector, languagesEquality);

  const { include } = languageOptions;
  return (
    <SelectField
      type={FieldTypeEnum.SELECT}
      name={name}
      label={label}
      guide={guide}
      required={required}
      selectOptions={{ options: getLanguageOptions(languageState, languagesState, include) }}
      onChange={onChange}
    />
  );
};

export default FormikLanguage;
