import React, { FunctionComponent } from "react";
import { translate } from "utils/i18n";

// child components
import PublicForm from "../elements/form";
import PublicNav from "../elements/nav";
import Form, { FormButtonProps } from "components/formik/form";
import DocTitle from "components/doctitle";
import { ButtonColorEnum } from "components/button";
import { goto } from "modules/app/navigation";
import { StageTypeEnum } from "./view";

// utils
import { login } from "utils/auth";
import { AuthTypeEnum, TwoFactorDeliveryTypeEnum } from "redux/models/user";

// redux
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { useSelector } from "react-redux";
import { FieldTypeEnum } from "redux/models/field-type";
import FormikLayoutColumns from "components/formik/layout/columns";
import { FieldModel } from "components/formik/utils";

interface LoginFormInterface {
  username: string;
  onStageChange: (stage: StageTypeEnum, username: string, method?: TwoFactorDeliveryTypeEnum) => void;
}

const LoginForm: FunctionComponent<LoginFormInterface> = ({ username = "", onStageChange }) => {
  useSelector(languageSelector, languageEquality);

  const getSchemaForLogin = () => {
    const schema: FieldModel[] = [
      {
        name: "username",
        type: FieldTypeEnum.USERNAME,
        placeholder: translate("public.login.placeholders.email"),
        label: translate("public.login.labels.email"),
      },
      {
        name: "password",
        type: FieldTypeEnum.PASSWORD,
        placeholder: translate("public.login.placeholders.password"),
        label: translate("public.login.labels.password"),
        passwordOptions: {
          submitOnEnter: true,
        },
      },
    ];
    return schema;
  };

  const getInitialValuesForLogin = () => {
    // use username from view level props
    const initialValues = { username, password: "" };
    return initialValues;
  };

  const getButtonsForLogin = () => {
    const buttons: FormButtonProps[] = [
      {
        children: "Let's Ticker!",
        color: ButtonColorEnum.PUBLIC,
      },
    ];
    return buttons;
  };

  // login with given username and password
  const onSubmit = async (values: any) => {
    let { username, password } = values;

    try {
      const result = await login(username, password);

      // if we get a result, then handle it - normal login won't return anything
      if (result) {
        const { authType, method } = result;
        if (authType === AuthTypeEnum.CREDENTIALS_TWO_FACTOR_PENDING) {
          onStageChange(StageTypeEnum.LOGIN_2FA, username, method);
        }
      }

      // after this, the user is redirected so no code here
    } catch (err) {
      onStageChange(StageTypeEnum.LOGIN_ERROR, username);
    }
  };

  const onClickForgotPassword = () => {
    goto("/forgotten-passwords");
  };

  const onRender = () => <FormikLayoutColumns formSchema={getSchemaForLogin()} />;

  return (
    <PublicForm>
      <DocTitle title={translate("public.login.title")} />

      <Form
        initialValues={getInitialValuesForLogin()}
        buttons={getButtonsForLogin()}
        onSubmit={onSubmit}
        onRender={onRender}
      />

      <PublicNav onClick={onClickForgotPassword}>{translate("public.login.buttons.forgotpw")}</PublicNav>
    </PublicForm>
  );
};

export default LoginForm;
