import React, { FunctionComponent } from "react";
import { translate } from "utils/i18n";

// components
import PublicForm from "../elements/form";
import PublicPlate from "../elements/plate";
import { PlateTypeEnum } from "../elements/plate.styles";
import DocTitle from "components/doctitle";
import { goto } from "modules/app/navigation";

// redux
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { useSelector } from "react-redux";
import { get } from "utils/session-storage";

interface SessionExpiredViewInterface {}

const SessionExpiredView: FunctionComponent<SessionExpiredViewInterface> = () => {
  useSelector(languageSelector, languageEquality);

  const idpName = get("idpName");

  const fn = () => {
    if (idpName) {
      window.open(`/auth/saml/login/${idpName}`, "_self");
    } else {
      goto("/login");
    }
  };

  return (
    <PublicForm>
      <DocTitle title={translate("public.sessionExpired.title")} />
      <PublicPlate
        type={PlateTypeEnum.NORMAL}
        title={translate("public.sessionExpired.title")}
        text={translate("public.sessionExpired.text")}
        button={{
          fn,
          text: translate(idpName ? "public.sessionExpired.buttonSaml" : "public.sessionExpired.button"),
        }}
      />
    </PublicForm>
  );
};

export default SessionExpiredView;
