import { toast, ToastId } from "react-toastify";
import { css } from "glamor";

import { ColorEnum } from "theme/colors";

const CommonStyles = {
  fontFamily: `"Exo 2", sans-serif`,
  fontSize: "11px",
  minHeight: "50px",
  color: ColorEnum.white,
  letterSpacing: "0.02em",
  fontWeight: "400",
  paddingRight: "20px",
};

const CommonBodyStyles = { paddingLeft: "10px", paddingTop: "2px", paddingBottom: "5px" };

const SuccessStyles = css({ ...CommonStyles, backgroundColor: ColorEnum.greenPositive });

const SuccessBodyStyles = css({ ...CommonBodyStyles });

const FailureStyles = css({ ...CommonStyles, backgroundColor: ColorEnum.redNegative });

const FailureBodyStyles = css({ ...CommonBodyStyles });

export const failure = (message: string): ToastId =>
  toast.error(message, {
    className: FailureStyles,
    bodyClassName: FailureBodyStyles,
  });

export const success = (message: string): ToastId =>
  toast.success(message, {
    className: SuccessStyles,
    bodyClassName: SuccessBodyStyles,
  });

export default { success, failure };
