import React, { FunctionComponent, useState, useEffect } from "react";
import * as queryString from "query-string";

// components
import TickerLoader from "components/loaders/ticker";
import PublicForm from "../elements/form";
import CodeForm from "./code-form";
import CodeError from "./code-error";
import LinkError from "./link-error";
import EmailForm from "./email-form";
import EmailError from "./email-error";

// services
import { get as getLink, verifyCode as verifyLink } from "services/link";

// utils
import { loginWithLink } from "utils/auth";
import { changeLanguage } from "utils/i18n";
import { TwoFactorDeliveryTypeEnum } from "redux/models/user";
import { LinkModel } from "redux/models/link";

// redux
import { selector as routerSelector, equalityFn as routerEquality } from "redux/hooks/router";
import { useSelector } from "react-redux";
import PreCodeForm from "./precode-form";

export enum StageTypeEnum {
  LOADING = "loading",
  LINK_ERROR = "link_error",
  EMAIL = "email",
  EMAIL_ERROR = "email_error",
  PRECODE = "precode",
  CODE = "code",
  CODE_ERROR = "code_error",
}

interface LinkViewInterface {
  match: any;
}

export interface LinkViewStateInterface {
  loaded: boolean;
  stage: StageTypeEnum;
  uuid: string;
  hash: string;
  link?: LinkModel;
  method?: TwoFactorDeliveryTypeEnum;
}

const LinkView: FunctionComponent<LinkViewInterface> = ({ match }) => {
  const routerState = useSelector(routerSelector, routerEquality);

  // this tells if the whole initialization is done and the link verified
  const [loaded, setLoaded] = useState(false);

  // this tells if the initial setup is done
  const [initialized, setInitialized] = useState(false);

  // ui stage
  const [stage, setStage] = useState(StageTypeEnum.EMAIL);

  // link attributes
  const [uuid, setUuid] = useState<string | undefined>(undefined);
  const [hash, setHash] = useState<string | undefined>(undefined);
  const [link, setLink] = useState<LinkModel | undefined>(undefined);

  // 2fa method
  const [method, setMethod] = useState<TwoFactorDeliveryTypeEnum | undefined>(undefined);

  // if we dont have uuid in local state, parse the url and set the correct variables
  if (!uuid) {
    // extract the link uuid
    const { params } = match;
    setUuid(params.uuid);

    // extract the link hash
    const { search } = routerState.location;
    const searchObject = queryString.parse(search);
    setHash(searchObject["hash"] as string);

    // confirm the initialization is done and we might proceed
    setInitialized(true);
  }

  useEffect(() => {
    // fetch and verify the link ONLY if uuid exists and we have done the initial setup
    if (uuid && hash && initialized) {
      const fetchLink = async () => {
        try {
          const link: LinkModel = await getLink(uuid, hash);
          const { hasTwoFactor, askOpenLinkEmail, language } = link;
          changeLanguage(language);
          setLoaded(true);
          setLink(link);

          // parse path
          if (hasTwoFactor && askOpenLinkEmail) {
            // 2fa with email verification
            setStage(StageTypeEnum.EMAIL);
          } else if (hasTwoFactor && !askOpenLinkEmail) {
            // 2fa without email verification
            setStage(StageTypeEnum.PRECODE);
          } else {
            // no 2fa at all
            await verifyLink(uuid, undefined, hash);
            setStage(StageTypeEnum.LOADING);
            loginWithLink(link, uuid);
          }
        } catch (error) {
          setStage(StageTypeEnum.LINK_ERROR);
          setLoaded(true);
        }
      };
      fetchLink();
    }
  }, [uuid, hash, initialized]);

  const renderLoader = () => (
    <PublicForm>
      <TickerLoader />
    </PublicForm>
  );

  // allows subforms to change state
  const onStageChange = (stage: StageTypeEnum, method?: TwoFactorDeliveryTypeEnum) => {
    setStage(stage);
    if (method) {
      setMethod(method);
    }
  };

  // if no link, render nothing
  if (!loaded || !uuid || !hash || !link) {
    return renderLoader();
  }

  switch (stage) {
    case StageTypeEnum.PRECODE:
      return <PreCodeForm uuid={uuid} hash={hash} onStageChange={onStageChange} />;

    case StageTypeEnum.CODE:
      return <CodeForm uuid={uuid} hash={hash} method={method} link={link} onStageChange={onStageChange} />;

    case StageTypeEnum.CODE_ERROR:
      return <CodeError uuid={uuid} hash={hash} onStageChange={onStageChange} />;

    case StageTypeEnum.EMAIL:
      return <EmailForm uuid={uuid} link={link} onStageChange={onStageChange} />;

    case StageTypeEnum.EMAIL_ERROR:
      return <EmailError onStageChange={onStageChange} />;

    case StageTypeEnum.LINK_ERROR:
      return <LinkError />;

    case StageTypeEnum.LOADING:
      return renderLoader();

    default:
      return null;
  }
};

export default LinkView;
