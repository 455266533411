import React, { FunctionComponent, CSSProperties } from "react";
import styled from "styled-components/macro";

export const StyledCol = styled.div`
  max-width: 100%;
  position: relative;
  min-height: 1px;

  /* all cols have right padding, excel the last one */
  padding-right: 30px;
  &:last-child {
    padding-right: 0px;
  }

  /* pretty much equal size */
  flex-basis: 0;
  flex-grow: 1;
`;

interface ColProps {
  className?: string;
  width?: any;
}

const Col: FunctionComponent<ColProps> = ({ className = "", children, width }) => {
  const style = { flexGrow: width ? 0 : null, flexBasis: width || null };

  return (
    <StyledCol className={className} style={style as CSSProperties}>
      {children}
    </StyledCol>
  );
};

export default Col;
