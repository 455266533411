import React, { FunctionComponent, Fragment } from "react";

import FormikField, { setValue } from "../field";
import { StyledInput, StyledLabelRadio, StyledWrapper, StyledContainer } from "./radio.styles";
import FormikError, { parseError } from "../error";
import { FieldTypeEnum } from "redux/models/field-type";
import { OptionsOpts, FieldModel } from "../utils";

// this component is rendered inside of the Formiks Field Component. See `./field.js`
// components gets fields & form props from the formik renderer.

export interface RadioOptions extends OptionsOpts {}

const FormikRadio: FunctionComponent<FieldModel> = ({
  name,
  label,
  guide,
  required,
  onChange = () => {},
  radioOptions = {},
}) => {
  const { options = [] } = radioOptions;

  return (
    <FormikField name={name} type={FieldTypeEnum.RADIO} label={label} guide={guide} required={required}>
      {({ field, form }) => {
        // stops propagation when needed
        const fnOnPreventPropagation = (e: any) => {
          e.stopPropagation();
        };

        // handle the value changes, radio only has 1 value so pretty simple
        const fnOnChange = (event: any) => {
          const value = event.target.value;
          setValue(form, field.name, value);
          onChange(value);
        };

        // if no options, exit
        if (!options) {
          return null;
        }

        // parse options and error
        const error = parseError(form, field);

        // utility to check if the certain option is checked
        const isChecked = (value: string) => {
          return field.value === value;
        };

        return (
          <Fragment>
            <StyledWrapper>
              {options.map((option: any) => {
                // get label and value from this option
                const { label, value } = option;

                const fieldId = `${field.name}-${value}`;
                const checked = isChecked(value);

                return (
                  <StyledContainer key={fieldId} onClick={fnOnPreventPropagation}>
                    <StyledInput type="radio" value={value} id={fieldId} checked={checked} onChange={fnOnChange} />
                    <StyledLabelRadio htmlFor={fieldId} checked={checked}>
                      {label}
                    </StyledLabelRadio>
                  </StyledContainer>
                );
              })}
            </StyledWrapper>

            <FormikError error={error} />
          </Fragment>
        );
      }}
    </FormikField>
  );
};

export default FormikRadio;
