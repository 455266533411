import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCaretDown,
  faCaretUp,
  faTimes,
  faLock,
  faTimesCircle,
  faInfoCircle,
  faBars,
  faCheck,
  faExclamationCircle,
  faClock,
  faSlidersH,
  faCalendarAlt,
  faUser,
  faSignal,
  faCopyright,
  faExclamationTriangle,
  faFrown,
  faArrowAltCircleUp,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faCaretDown,
  faCaretUp,
  faTimes,
  faLock,
  faTimesCircle,
  faInfoCircle,
  faBars,
  faCheck,
  faExclamationCircle,
  faClock,
  faSlidersH,
  faCalendarAlt,
  faUser,
  faSignal,
  faCopyright,
  faExclamationTriangle,
  faFrown,
  faArrowAltCircleUp
);
