import styled from "styled-components/macro";

import { StyledCodeContainer } from "components/formik/fields/code.styles";

export const StyledForm = styled.div`
  width: 100%;
  ${props => props.theme.mixins.fontUpperExo()};

  & ${StyledCodeContainer} {
    margin: 0 auto;
  }
`;
