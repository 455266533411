import styled from "styled-components/macro";
import Icon from "components/icon";
import FormikClear from "components/formik/clear";
import { StyledInput } from "./text.styles";

export const StyledDateInput = styled(StyledInput)``;

export const StyledErrorContainer = styled.div`
  ${props => props.theme.mixins.fontExo()};
  display: inline-block;
  color: ${props => props.theme.colors.redNegative};
  margin-top: 7px;
`;

export const FormikClearExt = styled(FormikClear)`
  right: 44px;
`;

export const StyledCalendar = styled(Icon)`
  position: absolute;
  right: 16px;
  top: 15px;
`;

export default {};
