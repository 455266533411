import React, { FunctionComponent } from "react";

import { StyledNav } from "./nav.styles";

interface PublicNavInterface {
  className?: string;

  // href for normal links and onClick for programmed links
  href?: string;
  onClick?: () => void;
}
const PublicNav: FunctionComponent<PublicNavInterface> = ({ className, href, children, onClick = () => {} }) => (
  <StyledNav onClick={onClick} className={className} href={href}>
    {children}
  </StyledNav>
);

export default PublicNav;
