import React from "react";
import * as yup from "yup";

import { translateFrom } from "utils/i18n";

import TextField from "components/formik/fields/text";
import TextareaField from "components/formik/fields/textarea";
import FormikLanguage from "components/formik/fields/language";
import UsernameField from "components/formik/fields/username";
import PasswordField from "components/formik/fields/password";
import CheckboxField from "components/formik/fields/checkbox";
import SelectField from "components/formik/fields/select";
import FormikRadio from "components/formik/fields/radio";
import FormikCode from "components/formik/fields/code";
import DateTimeField from "components/formik/fields/datetime";
import FormikTick from "components/formik/fields/tick";
import FormikFile from "components/formik/fields/file";
import BooleanField from "components/formik/fields/boolean";
import UrlField from "components/formik/fields/url";
import JsonField from "components/formik/fields/json";
import MarkdownField from "components/formik/fields/markdown";
import PhoneField from "components/formik/fields/phone";

import { LanguageEnum } from "consts";
import { sortBy } from "utils/array";
import { FieldTypeEnum } from "redux/models/field-type";
import { FieldSetSchemaModel } from "redux/models/field-set-schema";
import { FieldSetModel } from "redux/models/field-set";
import { FieldModel } from "components/formik/utils";

const buildOptions = (fieldSetSchema: FieldSetSchemaModel) => {
  const { opts = [] } = fieldSetSchema;

  return opts.map(opt => {
    return {
      value: opt.key,
      label: translateFrom(opt.label),
    };
  });
};

export const buildFormSchemaFromFieldSet = (fieldSet: FieldSetModel, isAdmin: boolean = false) => {
  const { schema } = fieldSet;
  const sortedFieldSetSchemas = sortBy(schema, "sorting");
  const formSchema: FieldModel[] = [];
  for (const sortedFieldSetSchemaField of sortedFieldSetSchemas) {
    const { uuid, name, type, adminOnly } = sortedFieldSetSchemaField;

    // parse required
    let { required } = sortedFieldSetSchemaField;
    if (required && adminOnly && !isAdmin) {
      // required, adminOnly field for insider should not be required
      required = false;
    }

    // parse disabled
    const disabled = adminOnly && !isAdmin;

    // push field schema
    const field: FieldModel = {
      name: uuid || name,
      type,
      label: translateFrom(sortedFieldSetSchemaField.label),
      guide: translateFrom(sortedFieldSetSchemaField.guide),
      placeholder: translateFrom(sortedFieldSetSchemaField.placeholder),
      required,
      disabled,
      wide: sortedFieldSetSchemaField.wide,
    };

    // add options for SELECT and RADIO
    switch (field.type) {
      case FieldTypeEnum.CHECKBOX:
        field.checkboxOptions = { options: buildOptions(sortedFieldSetSchemaField) };
        break;
      case FieldTypeEnum.RADIO:
        field.radioOptions = { options: buildOptions(sortedFieldSetSchemaField) };
        break;
      case FieldTypeEnum.SELECT:
        field.selectOptions = { options: buildOptions(sortedFieldSetSchemaField) };
        break;
    }

    // push
    formSchema.push(field);
  }
  return formSchema;
};

// builds a single field
export const renderField = (fieldSchema: FieldModel) => {
  const { type, name } = fieldSchema;
  switch (type) {
    case FieldTypeEnum.TEXT:
    case FieldTypeEnum.INPUT:
      return <TextField key={name} {...fieldSchema} />;
    case FieldTypeEnum.TEXTAREA:
      return <TextareaField key={name} {...fieldSchema} />;
    case FieldTypeEnum.LANGUAGE:
      return <FormikLanguage key={name} {...fieldSchema} />;
    case FieldTypeEnum.USERNAME:
      return <UsernameField key={name} {...fieldSchema} />;
    case FieldTypeEnum.PASSWORD:
      return <PasswordField key={name} {...fieldSchema} />;
    case FieldTypeEnum.SELECT:
      return <SelectField key={name} {...fieldSchema} />;
    case FieldTypeEnum.BOOLEAN:
      return <BooleanField key={name} {...fieldSchema} />;
    case FieldTypeEnum.CHECKBOX:
      return <CheckboxField key={name} {...fieldSchema} />;
    case FieldTypeEnum.RADIO:
      return <FormikRadio key={name} {...fieldSchema} />;
    case FieldTypeEnum.CODE:
      return <FormikCode key={name} {...fieldSchema} />;
    case FieldTypeEnum.TICK:
      return <FormikTick key={name} {...fieldSchema} />;
    case FieldTypeEnum.DATETIME:
      return <DateTimeField key={name} {...fieldSchema} />;
    case FieldTypeEnum.DATE:
      const props: FieldModel = {
        ...fieldSchema,
        datetimeOptions: { ...fieldSchema.datetimeOptions, time: false } || { time: false },
      };
      return <DateTimeField key={name} {...props} />;
    case FieldTypeEnum.FILE:
      return <FormikFile key={name} {...fieldSchema} />;
    case FieldTypeEnum.URL:
      return <UrlField key={name} {...fieldSchema} />;
    case FieldTypeEnum.JSON:
      return <JsonField key={name} {...fieldSchema} />;
    case FieldTypeEnum.MARKDOWN:
      return <MarkdownField key={name} {...fieldSchema} />;
    case FieldTypeEnum.PHONE:
      return <PhoneField key={name} {...fieldSchema} />;
    default:
      throw new Error(`Unknown type ${type} (name=${name})`);
  }
};

// builds an object validation schema
export const buildFormValidationObjectSchema = (formSchema: FieldModel[]) => {
  const shape: object = {};
  formSchema.forEach(formField => {
    const { name, type, validate, required } = formField;

    // build validation for that field
    let fieldValidationSchema = validate || yup.string();
    // if field is required
    if (required) {
      fieldValidationSchema = fieldValidationSchema.required();
    }

    if (type === FieldTypeEnum.DATE || type === FieldTypeEnum.DATETIME) {
      fieldValidationSchema = fieldValidationSchema.nullable();
    }

    // add to shape (ts safe)
    Object.assign(shape, { [name]: fieldValidationSchema });
  });

  // build the actual yup validation schema
  const validationSchema = yup.object().shape(shape);
  return validationSchema;
};

export const buildFormValidationArraySchema = (formSchema: FieldModel[]) => {
  const objectValidationSchema = buildFormValidationObjectSchema(formSchema);
  const validationSchema = yup.array().of(objectValidationSchema);
  return validationSchema;
};

export const wrapArraySchema = (key: string, validationArraySchema: yup.ArraySchema<any>) => {
  const shape: object = { [key]: validationArraySchema };
  return yup.object().shape({ ...shape });
};

export const wrapObjectSchema = (key: string, validationObjectSchema: yup.ObjectSchema<any>) => {
  const shape: object = { [key]: validationObjectSchema };
  return yup.object().shape({ ...shape });
};

export const buildFormValidationObjectSchemaForI18N = (formSchema: FieldModel[], languages: LanguageEnum[]) => {
  const shape: object = {};

  // iterate schema
  formSchema.forEach(formField => {
    // build a new schema for this field
    const newSchema: FieldModel[] = [];
    for (const language of languages) {
      const name = `${language}`;
      const newField: FieldModel = { ...formField, name };
      newSchema.push(newField);
    }

    // build validation schema per language
    const fieldValidationSchema = buildFormValidationObjectSchema(newSchema);
    Object.assign(shape, { [formField.name]: fieldValidationSchema });
  });

  // combine all
  const validationSchema = yup.object().shape({ ...shape });
  return validationSchema;
};

export const mergeFormValidationObjectSchemas = (validationSchemas: yup.ObjectSchema<any>[]) => {
  let mergedValidationSchema = yup.object();
  for (const validationSchema of validationSchemas) {
    mergedValidationSchema = mergedValidationSchema.concat(validationSchema);
  }
  return mergedValidationSchema;
};

export const removeRequired = (formSchema: FieldModel[]) =>
  formSchema.map(formField => {
    return { ...formField, required: false } as FieldModel;
  });
