import styled from "styled-components/macro";

import Icon from "components/icon";
import { StyledHourSelector } from "./hour-selector.styles";
import { StyledMinuteSelector } from "./minute-selector.styles";

export const StyledTimeSelector = styled.div`
  position: relative;
  height: 60px;
  margin-top: 12px;
`;

export const StyledClockIcon = styled(Icon)`
  color: ${props => props.theme.colors.green};
`;

export const StyledSemicolon = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const StyleHourMinuteWrapper = styled.div`
  position: absolute;
  left: px;

  ${StyledClockIcon} {
    position: absolute;
    left: 5px;
    top: 15px;
  }

  ${StyledHourSelector} {
    position: absolute;
    left: 20px;
    top: 12px;
  }

  ${StyledSemicolon} {
    position: absolute;
    left: 53px;
    top: 10px;
  }

  ${StyledMinuteSelector} {
    position: absolute;
    left: 58px;
    top: 12px;
  }
`;
