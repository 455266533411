import React, { FunctionComponent } from "react";
import styled from "styled-components/macro";
import { translate } from "utils/i18n";
import { useSelector } from "react-redux";

// components
import Button, { ButtonColorEnum } from "components/button";
import Modal, { ModalSizeEnum } from "components/layout/modal";
import Title from "components/title";
import Row from "components/layout/row";
import Col from "components/layout/col";

// utils
import { nl2br } from "utils/string";

// redux
import { hide as hideConfirm } from "redux/actions/confirm";
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { selector as confirmSelector, equalityFn as confirmEquality } from "redux/hooks/confirm";

interface ConfirmProps {
  className?: string;
}

const StyledCol = styled(Col)`
  padding-bottom: 20px;
  min-height: 100px;
`;

const Confirm: FunctionComponent<ConfirmProps> = ({ className }) => {
  const confirmState = useSelector(confirmSelector, confirmEquality);
  useSelector(languageSelector, languageEquality);

  if (!confirmState) {
    return null;
  }

  const { title, text, labelConfirm, labelCancel, onCancel, onConfirm } = confirmState;

  const fnOnCancel = async () => {
    hideConfirm();

    if (onCancel) {
      onCancel();
    }
  };

  const fnOnConfirm = async () => {
    hideConfirm();

    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <Modal className={className} onClose={fnOnCancel} modalSize={ModalSizeEnum.SMALL}>
      <Row>
        <Col>
          <Title>{title}</Title>
        </Col>
      </Row>
      <Row>
        <StyledCol>{nl2br(text)}</StyledCol>
      </Row>
      <Row>
        <Col>
          <Button color={ButtonColorEnum.SECONDARY} onClick={fnOnCancel}>
            {labelCancel || translate("confirm.labelCancel")}
          </Button>
        </Col>
        <Col>
          <Button id="confirm-button" color={ButtonColorEnum.PRIMARY} onClick={fnOnConfirm}>
            {labelConfirm || translate("confirm.labelConfirm")}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default Confirm;
