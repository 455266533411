import { AttachmentModel } from "redux/models/attachment";

// megabytes * kilobytes * bytes / 1.37
// base64 is roughly 37% bigger than binary version, that's why
// and 10Mb since Postmark allows that
export const MaxFileSize = 7 * 1024 * 1024;

export const getTotalSize = (attachments: AttachmentModel[] = []) => {
  let sizeInBytes = 0;
  for (const attachment of attachments) {
    sizeInBytes += attachment.size;
  }
  return sizeInBytes || 0;
};

// validate total size of attachments
export const validateSize = (attachments: AttachmentModel[] = [], sizeOfNewFile = 0) =>
  getTotalSize(attachments) + sizeOfNewFile <= MaxFileSize;

// add
export const addAttachment = (attachments: AttachmentModel[] = [], attachment: AttachmentModel) => {
  attachments.push(attachment);
};

// change the original array
export const removeAttachment = (attachments: AttachmentModel[] = [], index: number) => {
  attachments.splice(index, 1);
};
