import React, { ReactChild, FunctionComponent, Fragment } from "react";
import { ThemeProvider } from "styled-components/macro";

// theme items
import { ColorEnum } from "./colors";
import media from "./media";
import mixins from "./mixins";
import GlobalStyle from "./global";

interface ThemeProps {
  children: ReactChild;
}

const TickerThemeProvider: FunctionComponent<ThemeProps> = ({ children }) => {
  const theme = { colors: ColorEnum, media, mixins };

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        {children}
        <GlobalStyle />
      </Fragment>
    </ThemeProvider>
  );
};

export default TickerThemeProvider;
