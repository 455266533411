import React, { FunctionComponent, Fragment, useState, useRef } from "react";

import FormikError, { parseError } from "../error";
import FormikField, { setValue } from "../field";
import {
  StyledSelect,
  StyledInput,
  StyledSingleValueContainer,
  StyledCaret,
  StyledCaretIcon,
  StyledCaretLine,
  StyledOptionsContainer,
  StyledOption,
  StyledPlaceholder,
  StyledNoOptions,
  StyledListContainer,
} from "./select.styles";
import List, { ListItemProps } from "components/list";
import { translate } from "utils/i18n";
import Overlay from "components/overlay";
import { getTop, getLeft, getWidth, getHeight } from "utils/dimensions";
import { useWindowScroll } from "react-use";
import { FieldTypeEnum } from "redux/models/field-type";
import { OptionsOpts, FieldModel } from "../utils";

export interface SelectOptions extends OptionsOpts {
  multi?: boolean;
}

const SelectField: FunctionComponent<FieldModel> = ({
  name,
  label,
  guide,
  required,
  placeholder,
  onChange = () => {},
  selectOptions = {},
}) => {
  const { options = [], multi = false } = selectOptions;

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [focused, setFocused] = useState(false);
  const [open, setOpen] = useState(false);
  const [keyword, setKeyword] = useState("");

  // hook on scroll
  useWindowScroll();

  const onBlur = () => {
    setTimeout(onClose, 250);
  };

  const onFocus = () => {
    onOpen();
  };

  const onOpen = () => {
    if (open) {
      return;
    }

    // set open and focuses
    setOpen(true);
    setFocused(true);

    // focus the text
    const input = inputRef.current;
    if (input) {
      input.focus();

      // clear search now, because we can't do it on close (ref is null)
      input.value = "";
      setKeyword("");
    }
  };

  const onClose = () => {
    if (!open) {
      return;
    }
    setOpen(false);
    setFocused(false);
  };

  const onKeyUp = (event: any) => {
    const { target, key } = event;

    if (key === "Escape") {
      setKeyword("");
      return;
    }

    // normal handling
    const { value } = target;
    setKeyword(value);
  };

  return (
    <FormikField name={name} type={FieldTypeEnum.SELECT} label={label} guide={guide} required={required}>
      {({ field, form }) => {
        const error = parseError(form, field);

        const onSelect = (value: any) => {
          setOpen(false);
          setFocused(false);
          setKeyword("");

          if (multi) {
            const values = [...(field.value as any[])];
            values.push(value);
            setValue(form, field.name, values);
            onChange(values);
          } else {
            setOpen(false);
            setFocused(false);
            setValue(form, field.name, value);
            onChange(value);
          }
        };

        const onDelete = (value: any) => {
          const values = (field.value as any[]).filter(val => val !== value);
          setValue(form, field.name, values);
          onChange(values);
        };

        // find selected value
        const listItems: ListItemProps[] = (multi
          ? options.filter(option => field.value.includes(option.value))
          : options.filter(option => option.value === field.value)
        ).map(option => {
          const item: ListItemProps = {
            value: option.value as string,
            label: option.label,
            locked: option.locked || false,
          };
          return item;
        });
        const selectedValues: string[] = listItems.map(selection => {
          return selection.value;
        });

        // filter options by keyword
        const filteredOptions = options.filter(option => {
          // must match search
          if (keyword && !option.label.toLowerCase().includes(keyword.toLowerCase())) {
            return false;
          }

          // if multi, strip away already selected
          if (multi && (field.value as any[]).includes(option.value)) {
            return false;
          }

          // if multi, strip away fixed ones
          if (multi && option.locked) {
            return false;
          }

          // all good
          return true;
        });

        // create value items array
        const hideInput = multi || (!multi && !focused);
        return (
          <Fragment>
            <StyledSelect focused={focused} error={error} onClick={onOpen} ref={ref}>
              {selectedValues.length === 0 && !open && <StyledPlaceholder>{placeholder}</StyledPlaceholder>}
              <StyledInput onFocus={onFocus} onBlur={onBlur} ref={inputRef} onKeyUp={onKeyUp} hide={hideInput} />
              {!focused && !multi && listItems[0] && (
                <StyledSingleValueContainer>{listItems[0].label}</StyledSingleValueContainer>
              )}
              {multi && (
                <StyledListContainer>
                  <List
                    items={listItems}
                    onDelete={(item, index) => {
                      onDelete(item.value);
                    }}
                  />
                </StyledListContainer>
              )}
              <StyledCaret>
                <StyledCaretLine />
                <StyledCaretIcon icon="caret-down" />
              </StyledCaret>
            </StyledSelect>
            {open && ref.current && (
              <Overlay
                top={getTop(ref.current) + getHeight(ref.current)}
                left={getLeft(ref.current)}
                width={getWidth(ref.current)}
              >
                <StyledOptionsContainer>
                  {filteredOptions.map((option, index) => {
                    const { label, value } = option;
                    return (
                      <StyledOption
                        key={index}
                        selected={selectedValues.includes(value)}
                        onClick={() => onSelect(value)}
                      >
                        {label}
                      </StyledOption>
                    );
                  })}
                  {filteredOptions.length === 0 && <StyledNoOptions>{translate("select.noOptions")}</StyledNoOptions>}
                </StyledOptionsContainer>
              </Overlay>
            )}

            <FormikError error={error} />
          </Fragment>
        );
      }}
    </FormikField>
  );
};

export default SelectField;
