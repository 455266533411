import Icon from "components/icon";
import styled from "styled-components/macro";

export const StyledStrenght = styled.div`
  margin-top: 10px;
  ${props => props.theme.mixins.fontExo()};
  color: ${props => props.theme.colors.fontColor};
  text-transform: initial;
  display: block;
`;

export const StyledCaps = styled(Icon)`
  position: absolute;
  right: 16px;
  top: 14px;
`;
