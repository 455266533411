import styled from "styled-components/macro";
import { ZEnum } from "theme/layers";

export interface StyledContainerProps {
  top: number;
  left: number;
  width?: number;
  height?: number;
}

export const StyledOverlay = styled.div<StyledContainerProps>`
  position: fixed;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  z-index: ${ZEnum.MODAL + 1};
`;
