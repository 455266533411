import styled from "styled-components/macro";

export const StyledPublicLogo = styled.div`
  margin-top: 42px;
  margin-bottom: 50px;
  width: 180px;

  img {
    display: block;
    width: 100%;
    height: 91.3px;
    cursor: pointer;
  }
`;

export const StyledImg = styled.img``;
