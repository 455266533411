import React, { FunctionComponent, CSSProperties, Fragment } from "react";

// import and export some
import StyledButton, { ButtonColorEnum } from "./button.styles";
import PulseLoader from "./loaders/pulse";

export enum ButtonTypeEnum {
  BUTTON = "button",
  SUBMIT = "submit",
  RESET = "reset",
}

export interface ButtonProps {
  className?: string;
  id?: string;
  type?: string;
  isLoading?: boolean;
  inline?: boolean;
  isDisabled?: boolean;
  style?: CSSStyleDeclaration;
  color?: ButtonColorEnum;
  onClick?: (arg0?: any) => void;
  children?: any;
}

const Button: FunctionComponent<ButtonProps> = ({
  children = null,
  inline = false,
  isDisabled = false,
  style = {},
  color = ButtonColorEnum.PRIMARY,
  id,
  className = "",
  type = ButtonTypeEnum.BUTTON,
  isLoading = false,
  onClick = () => {},
}) => {
  const fnOnClick = () => {
    // ignore if disabled
    if (isDisabled || isLoading) {
      return;
    }

    // delegate
    onClick();
  };

  return (
    <Fragment>
      <StyledButton
        id={id}
        type={type as ButtonTypeEnum}
        color={color}
        disabled={isDisabled}
        onClick={fnOnClick}
        style={style as CSSProperties}
        className={className}
        isLoading={isLoading}
        inline={inline}
      >
        {isLoading ? <PulseLoader /> : children}
      </StyledButton>
    </Fragment>
  );
};

export default Button;
export { ButtonColorEnum, StyledButton };
