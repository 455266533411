import { ConfirmModel } from "redux/models/confirm";

export enum ConfirmActionEnum {
  SHOW_CONFIRM = "SHOW_CONFIRM",
  HIDE_CONFIRM = "HIDE_CONFIRM",
}

// Initial state
const initialState: ConfirmModel | null = null;

// Reducer
const ConfirmStateReducer = (state: ConfirmModel | null = initialState, action: any) => {
  const { type, confirm } = action;

  switch (type) {
    case ConfirmActionEnum.SHOW_CONFIRM:
      return { ...confirm } as ConfirmModel;

    case ConfirmActionEnum.HIDE_CONFIRM:
      return null;

    default:
      return state;
  }
};

export default ConfirmStateReducer;
