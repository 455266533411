import React, { FunctionComponent } from "react";
import { translate } from "utils/i18n";

// components
import PublicForm from "../elements/form";
import PublicPlate from "../elements/plate";
import { PlateTypeEnum } from "../elements/plate.styles";

// redux
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { useSelector } from "react-redux";

interface EmailDoneInterface {
  username: string;
}

const EmailDone: FunctionComponent<EmailDoneInterface> = ({ username }) => {
  useSelector(languageSelector, languageEquality);
  return (
    <PublicForm>
      <PublicPlate
        type={PlateTypeEnum.NORMAL}
        title={translate("public.forgottenPassword.success.title_email")}
        text={translate("public.forgottenPassword.success.text_email", { username })}
      />
    </PublicForm>
  );
};

export default EmailDone;
