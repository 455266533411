import { doGet, doPatch } from "utils/ajax";

export const list = async () => {
  const result = await doGet(`/organization/settings`);
  return result.organizationSettings;
};

interface SettingsServicePatchInterface {}

export const update = async (settingsUuid: string, settings: SettingsServicePatchInterface) => {
  const result = await doPatch(`/organization/settings/${settingsUuid}`, settings);

  return result;
};

export default {
  list,
  update,
};
