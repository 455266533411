import React, { FunctionComponent, Fragment, useState } from "react";
import owasp from "owasp-password-strength-test";
import { translate } from "utils/i18n";

// components
import { StyledInput } from "./text.styles";
import FormikError, { parseError } from "../error";
import FormikField, { setValue } from "../field";
import { StyledStrenght, StyledCaps } from "./password.styles";
import Indicator from "components/indicator";
import { ColorEnum } from "theme/colors";
import { FieldTypeEnum } from "redux/models/field-type";
import { SubmitOnEnterOpts, FieldModel } from "../utils";

enum OwaspErrorCodeEnum {
  MIN_LENGTH = 0,

  // these are optional tests
  LOWERCASE = 1,
  UPPERCASE = 2,
  NUMBER = 3,
  SPECIAL = 4,
}

// does not change the validation chain, the value is strengh checked onChange event
export const formikPasswordValidate = (password: string) => {
  const result = owasp.test(password);
  if (didTestFail(result, OwaspErrorCodeEnum.MIN_LENGTH)) {
    return translate("formik.password.minLength");
  }
  return undefined;
};

const didTestFail = (result: owasp.TestResult, test: OwaspErrorCodeEnum) => {
  const { failedTests } = result;
  const fail = failedTests.includes(test);
  return fail;
};

// remove maxLength and repeating requirements
owasp.tests.required = [owasp.tests.required[0]];
owasp.config({ allowPassphrases: true, minLength: 8, maxLength: 255, minPhraseLength: 20, minOptionalTestsToPass: 2 });

export interface PasswordOptions extends SubmitOnEnterOpts {
  strength?: boolean;
}

const PasswordField: FunctionComponent<FieldModel> = ({
  name,
  label,
  guide,
  required,
  placeholder,
  disabled = false,
  passwordOptions = {},
  onChange = () => {},
  onKeyDown = () => {},
}) => {
  const { submitOnEnter = false, strength = false } = passwordOptions;
  const [capsLock, setCapsLock] = useState(false);

  return (
    <FormikField
      name={name}
      type={FieldTypeEnum.PASSWORD}
      label={label}
      guide={guide}
      required={required}
      validate={strength ? formikPasswordValidate : undefined}
    >
      {({ field, form }) => {
        const error = parseError(form, field);

        // wrap changes
        const fnOnChange = (event: any) => {
          const { target } = event;
          const { value } = target;

          // pass on
          setValue(form, field.name, value);
          onChange(value);
        };

        // wrap onkeydown
        const fnOnKeyDown = (event: any) => {
          // handle submit on enter
          if (submitOnEnter && event.key === "Enter") {
            form.submitForm();
          }

          // required in both key down and up
          toggleCaps(event);

          // delegate
          onKeyDown(event, form);
        };

        const fnOnKeyUp = (event: any) => {
          // required in both key down and up
          toggleCaps(event);
        };

        const toggleCaps = (event: any) => {
          const caps = event.getModifierState("CapsLock");
          if (caps !== capsLock) {
            setCapsLock(caps);
          }
        };

        // check if is strong for the validation
        const { strong } = owasp.test(field.value);
        return (
          <Fragment>
            <StyledInput
              autoComplete="off"
              error={error}
              maxLength={255}
              type="password"
              name={field.name}
              value={field.value}
              disabled={disabled}
              id={field.name}
              placeholder={placeholder}
              onChange={fnOnChange}
              onKeyUp={fnOnKeyUp}
              onKeyDown={fnOnKeyDown}
            />
            {capsLock && (
              <StyledCaps
                size={22}
                icon="arrow-alt-circle-up"
                title={translate("formik.password.capsLock")}
                color={ColorEnum.grayDisabled}
              />
            )}
            {strength && field.value && (
              <StyledStrenght>
                {translate("formik.password.strength.indicator")}:{" "}
                {strong ? (
                  <Indicator color={ColorEnum.greenPositive}>{translate("formik.password.strength.strong")}</Indicator>
                ) : (
                  <Indicator color={ColorEnum.redNegative}>{translate("formik.password.strength.weak")}</Indicator>
                )}
              </StyledStrenght>
            )}
            <FormikError error={error} />
          </Fragment>
        );
      }}
    </FormikField>
  );
};

export default PasswordField;
