import React, { FunctionComponent } from "react";

import { StyledWeek } from "./week.styles";

interface WeekProps {
  children: any;
}
const Week: FunctionComponent<WeekProps> = ({ children }) => <StyledWeek>{children}</StyledWeek>;

export default Week;
