import React, { Fragment, StrictMode } from "react";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { CookiesProvider } from "react-cookie";

import { store } from "redux/store";
import { history } from "redux/middlewares";

import { I18nextProvider } from "react-i18next";
import { i18next } from "utils/i18n";

import ThemeProvider from "theme/provider";

import PrivateRoute from "./private";
import PublicRoute from "./public";
import { publicRoutes, privateRoutes, redirectRoutes } from "./routes";
import RedirectRoute from "./redirect";
import NotFoundError from "../error/not-found";
import ErrorBoundary from "../error/boundary";

import { RouteModel } from "redux/models/route";

const truncate = (routes: RouteModel[]) => {
  const paths = routes.map(route => route.path);
  return paths;
};

// to use or not to use strict mode
let StrictModeComponent = StrictMode;
if (process.env.REACT_APP_SKIP_STRICT) {
  StrictModeComponent = Fragment;
}

const AppRoot = () => (
  <I18nextProvider i18n={i18next}>
    <CookiesProvider>
      <Provider store={store}>
        <ThemeProvider>
          <ErrorBoundary>
            <ConnectedRouter history={history}>
              <StrictModeComponent>
                <Switch>
                  {/* routes must have "exact" otherwise the 404 route does not work */}
                  <Route exact path={truncate(redirectRoutes)} component={RedirectRoute} />
                  <Route exact path={truncate(publicRoutes)} component={PublicRoute} />
                  <Route exact path={truncate(privateRoutes)} component={PrivateRoute} />
                  <Route component={NotFoundError} />
                </Switch>
              </StrictModeComponent>
            </ConnectedRouter>
          </ErrorBoundary>
        </ThemeProvider>
      </Provider>
    </CookiesProvider>
  </I18nextProvider>
);

export default AppRoot;
