import React, { FunctionComponent } from "react";

import { StyledInput, StyledLabel, StyledContainer } from "./checkbox.styles";
import FormikError, { parseError } from "../error";
import FormikField, { setValue } from "../field";
import { FieldTypeEnum } from "redux/models/field-type";
import { FieldModel } from "../utils";

// this component is rendered inside of the Formiks Field Component. See `./field.js`
// components gets fields & form props from the formik renderer.
const BooleanField: FunctionComponent<FieldModel> = ({ name, label, guide, required, onChange = () => {} }) => {
  return (
    <FormikField name={name} type={FieldTypeEnum.BOOLEAN} label={undefined} guide={guide} required={required}>
      {({ field, form }) => {
        // stops propagation when needed
        const fnOnPreventPropagation = (e: any) => {
          e.stopPropagation();
        };

        // handle the value changes, boolean has only one value, which is boolean
        const fnOnChange = async (value: any) => {
          setValue(form, field.name, value);
          onChange(value);
        };

        const booleanId = `boolean-${field.name}`;
        const error = parseError(form, field);

        return (
          <StyledContainer onClick={fnOnPreventPropagation}>
            <StyledInput
              checked={field.value}
              id={booleanId}
              type="checkbox"
              onChange={() => {}} // must have for formik
              onClick={() => fnOnChange(!field.value)}
            />
            <StyledLabel id={field.name} htmlFor={booleanId} checked={field.value}>
              {label}
            </StyledLabel>

            <FormikError error={error} />
          </StyledContainer>
        );
      }}
    </FormikField>
  );
};

export default BooleanField;
