import React, { FunctionComponent, useState, useRef } from "react";

// components
import NavigationSubroute from "./subroute";
import {
  StyledRoute,
  StyledText,
  StyledIconContainer,
  StyledIcon,
  StyledCaret,
  StyledSubrouteContainer,
  AlignEnum,
} from "./route.styles";

// redux
import Overlay from "components/overlay";
import { selector as appSelector, equalityFn as appEquality } from "redux/hooks/app";
import { useSelector } from "react-redux";
import { ColorEnum } from "theme/colors";
import { getTop, getLeft, getWidth, getHeight } from "utils/dimensions";
import { useWindowScroll } from "react-use";

export interface RouteInterface {
  name: string; // identifier of each route, used for highlighting etc
  path?: string; // url path for the orute
  url?: string; // external url
  text?: string; // label
  icon?: string; // icon, if used
  subroutes?: RouteInterface[];
  align?: AlignEnum; // text align
}

interface NavigationRouteInterface {
  className?: string;
  route: RouteInterface;
  onClick: (Route: RouteInterface) => void;
}

export const isListRoute = (path: string) => path.includes("/list/");
export const isListTypeRoute = (path: string) => path.includes("/lists/");

export const getListTypeUuidFromPath = (path: string) => path.replace("/lists/", "");

const NavigationRoute: FunctionComponent<NavigationRouteInterface> = ({ className, onClick, route }) => {
  const { name, subroutes, path, text, icon, align = AlignEnum.RIGHT } = route;

  const appState = useSelector(appSelector, appEquality);
  const [open, setOpen] = useState<boolean>(false);

  // reference for the
  const ref = useRef<HTMLDivElement>(null);

  // hook on scroll
  useWindowScroll();

  // when a route is clicked
  const fnOnClick = (route: RouteInterface) => {
    if (onClick) {
      onClick(route);
    }
    setOpen(false);
  };

  // compile a dummy route object and delegate
  const fnOnClickHeader = () => {
    if (path) {
      fnOnClick(route);
    }
  };

  const onMouseEnterRoute = () => {
    setOpen(true);
  };
  const onMouseLeaveRoute = () => setOpen(false);
  const hasSubroutes = subroutes ? subroutes.length > 0 : false;

  const isSelected = () => {
    const { navigationReference } = appState;
    return navigationReference !== undefined && name === navigationReference;
  };

  const renderSubroute = (subroute: RouteInterface, index: number) => {
    return <NavigationSubroute key={index} onClick={fnOnClick} route={{ ...subroute }} />;
  };

  return (
    <StyledRoute
      ref={ref}
      className={className}
      onClick={fnOnClickHeader}
      selected={isSelected()}
      hasIcon={!!icon}
      onMouseEnter={onMouseEnterRoute}
      onMouseLeave={onMouseLeaveRoute}
    >
      {text && <StyledText>{text}</StyledText>}
      {icon && (
        <StyledIconContainer>
          <StyledIcon icon={icon} size={14} color={ColorEnum.white} />
        </StyledIconContainer>
      )}
      {hasSubroutes && !icon && <StyledCaret icon="caret-down" color={ColorEnum.white} />}
      {hasSubroutes && ref.current && open && (
        <Overlay
          top={getTop(ref.current) + getHeight(ref.current)}
          left={getLeft(ref.current)}
          width={getWidth(ref.current)}
        >
          <StyledSubrouteContainer className="drop" align={align}>
            {(subroutes || []).map((subroute, number) => renderSubroute(subroute, number))}
          </StyledSubrouteContainer>
        </Overlay>
      )}
    </StyledRoute>
  );
};

export default NavigationRoute;
