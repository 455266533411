import React, { FunctionComponent } from "react";
import styled from "styled-components/macro";
import { ColorEnum } from "theme/colors";

interface IndicatorProps {
  className?: string;
  color: ColorEnum;
}

const StyledIndicator = styled.span`
  ${props => props.theme.mixins.fontExo()};
  font-weight: 600;
  color: ${props => props.color};
`;

const Indicator: FunctionComponent<IndicatorProps> = ({ className = "", color = ColorEnum.fontColor, children }) => (
  <StyledIndicator className={className} color={color}>
    {children}
  </StyledIndicator>
);

export default Indicator;
