import styled from "styled-components/macro";

import { ZEnum } from "theme/layers";

export const StyledNavigationMobile = styled.div`
  z-index: ${ZEnum.COMPONENT};
  display: none;

  ${props => props.theme.media.desktop`display: none;`};
  ${props => props.theme.media.tablet`display: none;`};
  ${props => props.theme.media.phone`display: block;`};
`;

export const StyledRoute = styled.div`
  color: ${props => props.theme.colors.white};
  display: block;
  margin: 11px 0;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    font-weight: 400;
  }
`;

export const StyledRouteContainer = styled.div`
  display: block;
  position: absolute;
  background-color: ${props => props.theme.colors.green};
  z-index: ${ZEnum.COMPONENT};
  padding: 0px 0px 20px 30px;
  left: -30px;
  right: -30px;
  top: 47px;
`;
