import React, { FunctionComponent } from "react";

import { IconName } from "@fortawesome/fontawesome-svg-core";
import { StyledIconWrapper, StyledIcon } from "./icon.styles";
import { ColorEnum } from "theme/colors";

interface IconProps {
  id?: string;
  className?: string;
  icon: IconName | string;
  size?: number;
  title?: string;
  onClick?: (event?: any) => void;
  color?: ColorEnum;
  hoverColor?: ColorEnum;
  padding?: number;
}

const Icon: FunctionComponent<IconProps> = ({
  className,
  icon = "search",
  size = 18,
  title,
  padding = 0,
  onClick,
  id,
  color = ColorEnum.fontColor,
  hoverColor = ColorEnum.green,
}) => {
  return (
    <StyledIconWrapper
      padding={padding}
      onClick={onClick}
      className={className}
      color={color}
      hoverColor={hoverColor}
      title={title}
    >
      <StyledIcon id={id} icon={icon as IconName} fontSize={size} />
    </StyledIconWrapper>
  );
};

export default Icon;
