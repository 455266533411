import styled from "styled-components/macro";

import NavigationRoute from "./route";
import { StyledText } from "./route.styles";

export const StyledNavigationRoute = styled(NavigationRoute)`
  margin-left: -20px;
`;

export const StyledOrganization = styled.div`
  display: flex;
  align-items: center;
  cursor: default;
`;

export const StyledOrganizationText = styled(StyledText)``;
