import React, { FunctionComponent } from "react";

// components
import HourSelector from "./hour-selector";
import MinuteSelector from "./minute-selector";
import { StyledTimeSelector, StyleHourMinuteWrapper, StyledClockIcon, StyledSemicolon } from "./time-selector.styles";
import { ColorEnum } from "theme/colors";

interface TimeSelectorProps {
  className?: string;
  hour: number;
  minute: number;
  utcOffset: number;
  onChange: (hour: number, minute: number, utcOffset: number) => void;
}

const TimeSelector: FunctionComponent<TimeSelectorProps> = ({ className, hour, minute, utcOffset, onChange }) => {
  const fnOnChangeHour = (newHour: number) => {
    onChange(newHour, minute, utcOffset);
  };

  const fnOnChangeMinute = (newMinute: number) => {
    onChange(hour, newMinute, utcOffset);
  };

  return (
    <StyledTimeSelector className={className}>
      <StyleHourMinuteWrapper>
        <StyledClockIcon icon="clock" size={16} color={ColorEnum.green} />
        <HourSelector hour={hour} onChange={fnOnChangeHour} />
        <StyledSemicolon>:</StyledSemicolon>
        <MinuteSelector minute={minute} onChange={fnOnChangeMinute} />
      </StyleHourMinuteWrapper>
    </StyledTimeSelector>
  );
};

export default TimeSelector;
