import styled, { css } from "styled-components/macro";

import { ColorEnum } from "theme/colors";
import { fontExo } from "theme/mixins";
import Icon from "components/icon";

export const StyledList = styled.div`
  position: relative;
  flex: 1;
`;

interface StyledListItemProps {
  hasClick: boolean;
  hasDelete: boolean;
}

export const StyledListItem = styled.div<StyledListItemProps>`
  display: inline-flex;
  align-items: center;
  position: relative;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;

  ${props =>
    props.hasClick &&
    css`
      cursor: pointer;
    `};

  ${fontExo()};
  font-size: 10px;

  border: 1px solid ${ColorEnum.grayBorder};
  margin-right: 9px;
  margin-bottom: 9px;
  background-color: ${ColorEnum.grayLight};
`;

export const StyledText = styled.div`
  flex: 1;
`;

export const StyledDelete = styled(Icon)`
  width: 30px;
  height: 30px;
  margin-top: 1px;
  margin-right: -10px;
`;
