import styled from "styled-components/macro";

export const StyledInputWrapper = styled.div`
  position: absolute;
  height: 20px;
  width: 28px;
`;

export const StyledInput = styled.input`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border: 0px;
  background-color: transparent;
  text-align: center;

  color: ${props => props.theme.colors.fontColor};
  ${props => props.theme.mixins.fontExo()};
  font-size: 16px;
  font-weight: 600;
`;
