import React, { FunctionComponent } from "react";

// utils
import { changeLanguage } from "utils/i18n";
import { AuthTypeEnum } from "redux/models/user";
import { LanguageEnum } from "consts";
import { LanguageSelectorContainer, StyledLabel, StyledRadio, StyledText } from "./language.styles";
import { selector as languageSelector, equalityFn as languageEquality } from "redux/hooks/language";
import { selector as languagesSelector, equalityFn as languagesEquality } from "redux/hooks/languages";
import { useSelector } from "react-redux";
import { selector as userSelector, equalityFn as userEquality } from "redux/hooks/user";

interface NavigationLanguageSelectorInterface {}

interface LanguageOptionInterface {
  value: LanguageEnum;
  id: string;
  label: string;
}

const NavigationLanguageSelector: FunctionComponent<NavigationLanguageSelectorInterface> = () => {
  const userState = useSelector(userSelector, userEquality);
  const languageState = useSelector(languageSelector, languageEquality);
  const languagesState = useSelector(languagesSelector, languagesEquality);

  const getLanguageOptions = () =>
    languagesState.map(lang => ({
      id: `lan-${lang}`,
      value: lang,
      name: "language",
      label: lang,
    }));

  const renderLanguage = (option: LanguageOptionInterface) => {
    const selected = languageState === option.value;

    return (
      <StyledLabel
        htmlFor={option.id}
        selected={selected}
        key={option.id}
        onClick={() => {
          changeLanguage(option.value);
        }}
      >
        <StyledRadio type="radio" name="language" value={option.value} id={option.id} />
        <StyledText>{option.label}</StyledText>
      </StyledLabel>
    );
  };

  const renderLanguages = () => getLanguageOptions().map(option => renderLanguage(option));

  // do not show if admin
  const { authType } = userState;
  if (authType === AuthTypeEnum.CREDENTIALS || authType === AuthTypeEnum.SAML) {
    return null;
  }

  // if 1 language (or less) do not render
  const languageOptions = renderLanguages();
  if (languageOptions.length < 2) {
    return null;
  }

  return <LanguageSelectorContainer id="language-selector">{languageOptions}</LanguageSelectorContainer>;
};

export default NavigationLanguageSelector;
