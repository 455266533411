import styled from "styled-components/macro";
import { ZEnum } from "theme/layers";
import { ColorEnum } from "theme/colors";

interface StyledLayerProps {
  color: ColorEnum;
  transparent: boolean;
}

export const StyledLayer = styled.div<StyledLayerProps>`
  position: fixed;
  background-color: ${props => props.color};
  opacity: ${props => (props.transparent ? 0 : 0.5)};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${ZEnum.MODAL};
`;
